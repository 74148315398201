


import React, {useState, useEffect } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { CreateDioceseOfficial, CreateProfile, CreateEducation, CreatePerferment, EditPerferment } from '../redux/action';
import { IMAGEPATH } from '../redux/action/constant';


const CreateNewPerferment = (props) => {

    const [ show, setshow ] = useState(false);
    const [ edit, setedit ] = useState(false);
    const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
    const [IsSubmitting, setIsSubmitting ] = useState(false);
    const { id } = useParams();
    const [ file, setfile ] = useState(null);
    const dioceanofficials = useSelector(state=>state.root.dioceanofficials);
    const month = useSelector(state => state.root.month);
    const year = useSelector(state => state.root.year);
    const thispriest = useSelector(state=>state.root.onboard_information);

    const perSubmit = async (data,e) => {
        //alert('gate');
        setIsSubmitting(true);
        //alert(JSON.stringify(props.id));
        //let dioceseId = window.atob(id);
        //alert(JSON.stringify(data));
        if(props.type == 'create')
            await dispatch(CreatePerferment(data,thispriest.id,e));
        if(props.type == 'edit')
            await dispatch(EditPerferment(data,props.perferment.id,e));
        else    
            await dispatch(CreatePerferment(data,props.id,e));
        setIsSubmitting(false);
    }


    useEffect(() => {
       // alert(month[0]);
        // let start = 1950;
        // let allstart = [];
        // for(let r = 0; r < 100; r++)
        // {
        //     allstart.push(start+r);
        // }
        // setyear(allstart);
    },[])

    return (
        <div className="">
        <div style={{marginTop:30}}>
        <form onSubmit={handleSubmit(perSubmit)}>
                    <div className="row"  style={{marginBottom:20}}>
                        <div className="col-lg-6 col-sm-6">
                        <select 
                             className="form-control" 
                             placeholder ="Status" 
                             style={{marginBottom:20}}
                             name ="status"
                             type ="text" 
                             defaultValue = { props.type == 'edit' ? props.perferment.status :'' }
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Status</option>
                         <option value="Most Revd">Most Revd</option>
                         <option value="Right Revd">Right Revd</option>
                         <option value="Very Revd">Very Revd</option>
                         <option value="Venerable">Venerable</option>
                         <option value="Revd Canon">Revd Canon</option>
                         <option value="Revd">Revd</option>
                        </select>  
                            <small className="text-danger">{errors.status?.type == "required" && "Status is required"}</small>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                        <select 
                             className="form-control" 
                             placeholder ="Status" 
                             style={{marginBottom:20}}
                             name ="IsNewPerferment"
                             type ="text" 
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Is New Perferment</option>
                         <option value="1">Yes</option>
                         <option value="0">No</option>
                        </select>  
                            <small className="text-danger">{errors.IsNewPerferment?.type == "required" && "IsNewPerferment is required"}</small>
                        </div>
                       
                    </div>
                    <div className="row"  style={{marginBottom:20}}>
                        <div className="col-lg-6 col-sm-6">
                        <select 
                          name = "startMonth"
                          defaultValue = { props.type == 'edit' ? props.perferment.startMonth :'' }
                          ref={register({
                            required: "Required"
                            })}
                          className="form-control">
                        <option value="">Select Start Month</option>
                        {
                            month != null && month.map((mon,x) =>
                            <option value={mon}>{mon}</option>
                            )
                        }
                        </select>
                            <small className="text-danger">{errors.startmonth?.type == "required" && "Required"}</small>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                        <select 
                          name = "startYear"
                          defaultValue = { props.type == 'edit' ? props.perferment.startYear :'' }
                          ref={register({
                            required: "Required"
                            })}
                          className="form-control">
                        <option value="">Select Start Year</option>
                        {
                            year != null && year.map((y) =>
                            <option value={y.id}>{y.id}</option>
                            )
                        }
                        </select>
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>
                   
                    
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                  </form>        
        </div>
     </div>
    );
}

export default CreateNewPerferment;

