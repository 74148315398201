import axios from 'axios';

import 
{
    USER_LOGIN,
    USER_REGISTER,
    PATH,
    DIOCESES,
    ARCHDEACONRIES,
    PARISHES,
    PRIESTS,
    NEWPROVINCE,
    PROVINCES,
    NEWPROVINCEOFFICIAL,
    PROVINCEOFFICIALS,
    PROV,
    REFRESH,
    NEWDIOCEANOFFICIAL,
    DIOCEANOFFICIALS,
    USER_ONBOARD_REGISTER,
    NEWDIOCESE,
    OFFICIALS,
    NEWARCHDEACONRY,
    NEWPARISH,
    NEWPRIEST,
    THISPRIEST,
    UPDATETHISPRIEST,
    YEAR,
    PARISHINARCHDEACONARY,
    PRIESTSINPARISH,
    NEWADMIN,
    ADMINS,
    NEWPORTAL,
    PORTALS,
    ONBOARDINGUSER,
    DASHBOARD
} from './constant';
import { Success, Error } from '../../Message/message';

import { store } from '../store/index';

const INSTANCE = axios.create({
    baseURL: `${PATH}`,
    timeout: 200000,
    headers: {
              'Content-Type': 'application/json', 
               'Accept': 'application/json'
            }
});

const AUTH = axios.create({
    baseURL: `${PATH}`,
    timeout: 20000,
    headers: {
              'Content-Type': 'application/json', 
               'Accept': 'application/json',
               'Authorization': `Bearer ${store.getState().root.token}`
            }
});


export function SearchProvince(data)
{
    return { type: PROVINCES, payload: data };
}

export function SearchDiocese(data)
{
    return { type: DIOCESES, payload: data };
}

export function SearchPOfficial(data)
{
    ////alert(JSON.stringify(data));
    return { type: OFFICIALS, payload: data };
}

export function SearchDOfficial(data)
{
    return { type: DIOCEANOFFICIALS, payload: data };
}

export function SearchArchdeaconry(data)
{
    return { type: ARCHDEACONRIES, payload: data };
}

export function SearchParish(data)
{
    return { type: PARISHES, payload: data };
}

export function SearchPriest(data)
{
    return { type: PRIESTS, payload: data };
}

export function SearchUser(data)
{
    return { type: ADMINS, payload: data };
}

export function Refresh()
{
    return { type: REFRESH };
}

export function Dashboard()
{
    return function(dispatch,getState)
    {
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      
                       'Accept': 'application/json',
                       'Content-type': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get('dashboard')
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(response.data));
                await dispatch({ type: DASHBOARD, payload: response.data });
            }else{  
                Error(response.data.error);
            }
        }).catch(err => {
            if(err.response) Error('Failed Attempt','Error whiling processing data...try again later');
            else if(err.request) Error('Failed Attempt', 'Error whiling processing data...try again later'); 
        }); 
    }
}
export function UserLogin(data, props)
{
    return function(dispatch,getState)
    {
        return INSTANCE.post('login',{
                email:data.email,
                password:data.password
        })
        .then(async (response) => {
            if(response.data.status === 'success'){
                let start = 1950;
                let allstart = [];
                for(let r = 0; r < 100; r++)
                {
                    allstart.push({ id: start+r });
                }
                await dispatch({ type: USER_LOGIN, payload: response.data });
                await dispatch({ type: YEAR, payload: allstart });
                Success('Login Successful');
                sessionStorage.setItem("token",response.data.access_token);
                props.history.push('/provinces');
            }else{  
                Error(response.data.error);
            }
        }).catch(err => {
            if(err.response) Error('Failed Attempt','Error whiling processing data...try again later');
            else if(err.request) Error('Failed Attempt', 'Error whiling processing data...try again later'); 
        }); 
    }
}

export function UserRegister(data,props) {
    return function(dispatch){
        return INSTANCE.post('register',data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                await dispatch({ type: USER_REGISTER, payload: response.data });
                Success('Registration Successful');
                sessionStorage.setItem("token",response.data.access_token);
                props.history.push('/provinces');
            }else {
                Error(response.data.error);
                //console.log(response.data);
            }
        }).catch(err => {
            //console.log(err);
            ////alert(JSON.stringify(err));
            if(err.response) Error('Failed Attempt','Error whiling processing data...try again later');
            else if(err.request) Error('Failed Attempt', 'Error whiling processing data...try again later');

        }); 
    }
}

export function UserOnboardRegister(data,props,e) {
    return function(dispatch){
        return INSTANCE.post('onboardregister',data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                let start = 1950;
                let allstart = [];
                for(let r = 0; r < 100; r++)
                {
                    allstart.push({ id: start+r });
                }
                await dispatch({ type: USER_ONBOARD_REGISTER, payload: response.data });
                await dispatch({ type: YEAR, payload: allstart });
                Success('Registration Successful');
                e.target.reset();
                sessionStorage.setItem("token",response.data.access_token);
                props.history.push('/onboard');
            }else {
                Error(response.data.error);
                //console.log(response.data);
            }
        }).catch(err => {
            //console.log(err);
            ////alert(JSON.stringify(err));
            if(err.response) Error('Failed Attempt','Error whiling processing data...try again later');
            else if(err.request) Error('Failed Attempt', 'Error whiling processing data...try again later');

        }); 
    }
}

export function UserOnboardLogin(data,props) {
    return function(dispatch){
        return INSTANCE.post('onboardlogin',data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                let start = 1950;
                let allstart = [];
                for(let r = 0; r < 100; r++)
                {
                    allstart.push({ id: start+r });
                }
                await dispatch({ type: USER_ONBOARD_REGISTER, payload: response.data });
                await dispatch({ type: YEAR, payload: allstart });
                Success('Login Successful');
                //sessionStorage.setItem("token",response.data.access_token);
                props.history.push('/onboard');
            }else {
                ////alert(JSON.stringify(response.data));
                Error(response.data.error);
                //console.log(response.data);
            }
        }).catch(err => {
            //console.log(err);
            ////alert(JSON.stringify(err));
            if(err.response) Error('Failed Attempt','Error whiling processing data...try again later');
            else if(err.request) Error('Failed Attempt', 'Error whiling processing data...try again later');

        }); 
    }
}

export function CreateAdmin(data,e) {
    ////alert(store.getState().root.token);
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      
                       'Accept': 'application/json',
                       'Content-type': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post('createadmin',data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(data));
                Success('User Created Successfully');
                await dispatch({ type: NEWADMIN, payload: response.data });
                e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            ////alert(JSON.stringify(err));
        }); 
    }
}
export function EditAdmin(data,id) {
    ////alert(store.getState().root.token);
    let admins = store.getState().root.admins;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      
                       'Accept': 'application/json',
                       'Content-type': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`editadmin/${id}`,data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(data));
                Success('User Updated Successfully');
                let index = admins.findIndex(x => x.id == id);
                admins[index] = response.data.admin;
                await dispatch({ type: ADMINS, payload: { admins : admins } });
               // e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
           // //alert(JSON.stringify(err));
        }); 
    }
}

export function GetAdmins(data,e) {
    ////alert(store.getState().root.token);
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      
                       'Accept': 'application/json',
                       'Content-type': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get('getadmins',data)
        .then(async (response) => {
            if(response.data.status === 'success'){
               // Success('User Created Successfully');
               ////alert(JSON.stringify(response.data));
                await dispatch({ type: ADMINS, payload: response.data });
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
           // //alert(JSON.stringify(err));
        }); 
    }
}

export function CreatePortal(data,e) {
    ////alert(store.getState().root.token);
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      
                       'Accept': 'application/json',
                       'Content-type': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post('createportal',data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(data));
                Success('Registration Portal Created Successfully');
                await dispatch({ type: NEWPORTAL, payload: response.data });
                e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
           // //alert(JSON.stringify(err));
        }); 
    }
}
export function EditPortal(data,id) {
    ////alert(store.getState().root.token);
    let portals = store.getState().root.portals;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      
                       'Accept': 'application/json',
                       'Content-type': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`editportal/${id}`,data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(data));
                Success('Registration Portal Updated Successfully');
                let index = portals.findIndex(x => x.id == id);
                portals[index] = response.data.portal;
                await dispatch({ type: PORTALS, payload: { portals : portals } });
               // e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            //alert(JSON.stringify(err));
        }); 
    }
}

export function GetPortals() {
    ////alert(store.getState().root.token);
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      
                       'Accept': 'application/json',
                       'Content-type': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get('getportals')
        .then(async (response) => {
            if(response.data.status === 'success'){
               // Success('User Created Successfully');
               ////alert(JSON.stringify(response.data));
                await dispatch({ type: PORTALS, payload: response.data });
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            ////alert(JSON.stringify(err));
        }); 
    }
}


export function CreateProvince(data,e) {
    ////alert(store.getState().root.token);
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post('provincecreate',data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(data));
                Success('Province Created Successfully');
                await dispatch({ type: NEWPROVINCE, payload: response.data });
                e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            //alert(JSON.stringify(err));
        }); 
    }
}
export function GetProvinces() {
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get('getprovinces')
        .then(async (response) => {
            if(response.data.status === 'success'){
                await dispatch({ type: PROVINCES, payload: response.data });
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function EditProvince(data,id) {
    let provinces = store.getState().root.provinces;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`provinceedit/${id}`,data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                Success('Data Saved Successfully');
                let index = provinces.findIndex(x => x.id == id);
                provinces[index] = response.data.province;
                await dispatch({ type: PROVINCES, payload: { provinces: provinces } });
            }else {
                Error(response.data.error);
                //console.log(response.data);
            }
        }).catch(err => {
        }); 
    }
}
export function CreateOfficial(data, provinceId, e) {
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`official/${provinceId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                Success("Official Created Successfully");
                await dispatch({ type: NEWPROVINCEOFFICIAL, payload: response.data });
                e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function EditOfficial(data, officialId) {
    let officials = store.getState().root.officials;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`editofficial/${officialId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(response.data));
                let index = officials.findIndex(x => x.id == officialId);
                officials[index] = response.data.official;
                await dispatch({ type: PROVINCEOFFICIALS, payload: { officials: officials } });
                Success("Data Updated Successfully");
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function GetOfficials(provinceId) {
   
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get(`getofficials/${provinceId}`)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(response.data));
                await dispatch({ type: OFFICIALS, payload: response.data });
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            //alert(JSON.stringify(err));
        }); 
    }
}

export function CreateDiocese(data, provinceId, e) {
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`diocesecreate/${provinceId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                Success("Diocese Created Successfully");
                await dispatch({ type: NEWDIOCESE, payload: response.data });
                e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function EditDiocese(data, dioceseId) {
    let dioceses = store.getState().root.dioceses;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`dioceseedit/${dioceseId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                let index = dioceses.findIndex(x => x.id == dioceseId);
                dioceses[index] = response.data.diocese;
                await dispatch({ type: DIOCESES, payload: { dioceses : dioceses } });
                Success("Diocese Updated Successfully");
                //e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            //alert(JSON.stringify(err));
        }); 
    }
}

export function CreateDioceseOfficial(data, dioceseId, e) {
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`createdioceseofficial/${dioceseId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                await dispatch({ type: NEWDIOCEANOFFICIAL, payload: response.data });
                e.target.reset();
                Success("Official Created Successfully");
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function EditDioceseOfficial(data, officialId) {
    let dioceanofficials = store.getState().root.dioceanofficials;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`editdioceseofficial/${officialId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                let index = dioceanofficials.findIndex(x => x.id == officialId);
                dioceanofficials[index] = response.data.official;
                await dispatch({ type: DIOCEANOFFICIALS, payload: { officials : dioceanofficials } });
                Success("Official Information Updated Successfully");
                //e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function GetDioceseOfficial(dioceseId) {
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get(`getdioceseofficials/${dioceseId}`)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(response.data));
                await dispatch({ type: DIOCEANOFFICIALS, payload: response.data });
                //e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            //alert(JSON.stringify(err));
        }); 
    }
}

export function GetDioceses(provinceId) {
    //let dioceses = store.getState().root.dioceses;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get(`getdioceses/${provinceId}`)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(response.data));
                await dispatch({ type: DIOCESES, payload: response.data });
                //e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            ////alert(JSON.stringify(err));
        }); 
    }
}


export function CreateArchdeaconry(data, dioceseId, e) {
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`archcreate/${dioceseId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                await dispatch({ type: NEWARCHDEACONRY, payload: response.data });
                Success("Archdeaconary Created Successfully");
                e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function EditArchdeaconry(data,archId) {
    let archdeaconries = store.getState().root.archdeaconries;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`archedit/${archId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(response.data));
                let index = archdeaconries.findIndex(x => x.id == archId);
                archdeaconries[index] = response.data.archdeaconary;
                await dispatch({ type: ARCHDEACONRIES, payload: { archdeaconaries : archdeaconries } });
                //e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function GetArchdeaconries(dioceseId) {
    //let dioceses = store.getState().root.dioceses;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get(`getarchdeaconaries/${dioceseId}`)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(response.data));
                await dispatch({ type: ARCHDEACONRIES, payload: response.data });
                //e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function CreateParish(data, archdeaconryId, e) {
   // //alert(JSON.stringify(data));
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        
        return AUTH.post(`parishcreate/${archdeaconryId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                await dispatch({ type: NEWPARISH, payload: response.data });
                Success("Parish Created Successfully");
                e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function EditSelectedParish(data,parishId) {
    let parishes = store.getState().root.parishes;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        
        return AUTH.post(`parishedit/${parishId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                // let index = parishes.findIndex(x => x.id == parishId);
                // parishes[index] = response.data.parish;
                // await dispatch({ type: PARISHES, payload: { parishes : parishes } });
                await dispatch({ type: PARISHES, payload: response.data });
                Success("Parish Updated Successfully");
                //e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function GetParishes(dioceseId) {
    //let dioceses = store.getState().root.dioceses;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get(`getparishesIndiocese/${dioceseId}`)
        .then(async (response) => {
            if(response.data.status === 'success'){
                await dispatch({ type: PARISHES, payload: response.data });
                //e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function CreateProfile(data, e,id) {
    ////alert(id);
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token  == null ? store.getState().root.onboard_token :store.getState().root.token }`
                    }
        });
        return AUTH.post(`profilecreate/${id}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(response.data));
                
                e.target.reset();
                if(store.getState().root.user_onboard_login != null)
                {
                    let data = {
                        onboard_information: response.data.priest,
                        onboard_education:store.getState().root.onboard_education,
                        onboard_parish: store.getState().root.onboard_parish,
                        onboard_perferment:store.getState().root.onboard_perferment,
                        parishes:store.getState().root.allparishes
                    }
                    await dispatch({ type: ONBOARDINGUSER, payload: data });
                }else {
                    await dispatch({ type: NEWPRIEST, payload: response.data });
                }
                Success("Priest Created Successfully");
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            alert(JSON.stringify(err.response));
            console.log(err);
        }); 
    }
}

export function EditProfile(data,profileId) {
    let priests = store.getState().root.priests;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token  == null ? store.getState().root.onboard_token :store.getState().root.token }`
                    }
        });
        return AUTH.post(`profileedit/${profileId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                //alert(JSON.stringify(response.data.date))
                Success("Profile Editted Successfully");
                if(store.getState().root.user_onboard_login != null)
                {
                    let data = {
                        onboard_information: response.data.priest,
                        onboard_education:store.getState().root.onboard_education,
                        onboard_parish: store.getState().root.onboard_parish,
                        onboard_perferment:store.getState().root.onboard_perferment,
                        parishes:store.getState().root.allparishes
                    }
                    await dispatch({ type: ONBOARDINGUSER, payload: data });
                }else {
                    let index = priests.findIndex(x => x.id == profileId);
                    priests[index] = response.data.priest;
                    await dispatch({ type: UPDATETHISPRIEST, payload: { priest : response.data.priest } });
                    await dispatch({ type: PRIESTS, payload: { priests : priests } });
                }
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            //console.log(err);
            //alert(JSON.stringify(err.response));
        }); 
    }
}

export function GetProfiles(dioceseId) {
    ////alert(dioceseId);
    //let dioceses = store.getState().root.dioceses;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get(`getpriests/${dioceseId}`)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(response.data));
                await dispatch({ type: PRIESTS, payload: response.data });
                //e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function CreateEducation(data,profileId,e) {
    let priests = store.getState().root.priests;
    let token = store.getState().root.token != null ? store.getState().root.token : store.getState().root.onboard_token;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${token}`
                    }
        });
        return AUTH.post(`educationcreate/${profileId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){

                if(store.getState().root.user_onboard_login != null)
                {
                    let data = {
                        onboard_information: store.getState().root.onboard_information,
                        onboard_education: response.data.priest.education,
                        onboard_parish: store.getState().root.onboard_parish,
                        onboard_perferment:store.getState().root.onboard_perferment,
                        parishes:store.getState().root.allparishes
                    }
                    await dispatch({ type: ONBOARDINGUSER, payload: data });
                }else {
                    let index = priests.findIndex(x => x.id == profileId);
                    priests[index] = response.data.priest;
                    await dispatch({ type: UPDATETHISPRIEST, payload: { priest : response.data.priest } });
                    await dispatch({ type: PRIESTS, payload: { priests : priests } });
                   // e.target.reset();
                    
                }
               
                Success('Education Added Successfully');
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            alert(JSON.stringify(err));
        }); 
    }
}

export function EditEducation(data,id) {
    ////alert(id);
    let token = store.getState().root.token != null ? store.getState().root.token : store.getState().root.onboard_token;
    let priests = store.getState().root.priests;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${token}`
                    }
        });
        return AUTH.post(`educationedit/${id}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                if(store.getState().root.user_onboard_login != null)
                {
                    ////alert(JSON.stringify(response.data.priest.education));
                    let data = {
                        onboard_information: store.getState().root.onboard_information,
                        onboard_education: response.data.priest.education,
                        onboard_parish: store.getState().root.onboard_parish,
                        onboard_perferment:store.getState().root.onboard_perferment,
                        parishes:store.getState().root.allparishes
                    }
                    await dispatch({ type: ONBOARDINGUSER, payload: data });
                }else {
                    let index = priests.findIndex(x => x.id == id);
                    priests[index] = response.data.priest;
                    await dispatch({ type: UPDATETHISPRIEST, payload: { priest : response.data.priest } });
                    await dispatch({ type: PRIESTS, payload: { priests : priests } });
                    
                }

                Success("Priest Education Updated Successfully");
                
               // e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            //alert(JSON.stringify(err));
        }); 
    }
}

export function CreatePerferment(data,profileId,e) {
    let priests = store.getState().root.priests;
    let token = store.getState().root.token != null ? store.getState().root.token : store.getState().root.onboard_token;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${token}`
                    }
        });
        return AUTH.post(`perfermentcreate/${profileId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){

                if(store.getState().root.user_onboard_login != null)
                {
                   // //alert(JSON.stringify(response.data.priest.preferment));
                    let data = {
                        onboard_information: store.getState().root.onboard_information,
                        onboard_education: store.getState().root.onboard_education,
                        onboard_parish: store.getState().root.onboard_parish,
                        onboard_perferment:response.data.priest.preferment,
                        parishes:store.getState().root.allparishes
                    }
                    await dispatch({ type: ONBOARDINGUSER, payload: data });
                }else {
                    let index = priests.findIndex(x => x.id == profileId);
                    priests[index] = response.data.priest;
                    await dispatch({ type: UPDATETHISPRIEST, payload: { priest : response.data.priest } });
                    await dispatch({ type: PRIESTS, payload: { priests : priests } });
                    
                }
               
                e.target.reset();
                Success("Perferment Updated Successfully");
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function EditPerferment(data,id) {
    let priests = store.getState().root.priests;
    let token = store.getState().root.token != null ? store.getState().root.token : store.getState().root.onboard_token;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${token}`
                    }
        });
        return AUTH.post(`perfermentedit/${id}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){

                if(store.getState().root.user_onboard_login != null)
                {
                    ////alert(JSON.stringify(response.data.priest.education));
                    let data = {
                        onboard_information: store.getState().root.onboard_information,
                        onboard_education: store.getState().root.onboard_education,
                        onboard_parish: store.getState().root.onboard_parish,
                        onboard_perferment:response.data.priest.preferment,
                        parishes:store.getState().root.allparishes
                    }
                    await dispatch({ type: ONBOARDINGUSER, payload: data });
                }else {
                    let index = priests.findIndex(x => x.id == id);
                    priests[index] = response.data.priest;
                    ////alert(JSON.stringify(response.data.priest));
                    await dispatch({ type: UPDATETHISPRIEST, payload: { priest : response.data.priest } });
                    await dispatch({ type: PRIESTS, payload: { priests : priests } });
                    
                }
               
                Success("Perferment Updated Successfully");
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function CreatePriestParish(data,profileId, e) {
    let priests = store.getState().root.priests;
    let token = store.getState().root.token != null ? store.getState().root.token : store.getState().root.onboard_token;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${token}`
                    }
        });
        return AUTH.post(`priestparishcreate/${profileId}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                if(store.getState().root.user_onboard_login != null)
                {
                    ////alert(JSON.stringify(response.data.priest.education));
                    let data = {
                        onboard_information: store.getState().root.onboard_information,
                        onboard_education: store.getState().root.onboard_education,
                        onboard_parish: response.data.priest.parishtilldate,
                        onboard_perferment:store.getState().root.onboard_perferment,
                        parishes:store.getState().root.allparishes
                    }
                    await dispatch({ type: ONBOARDINGUSER, payload: data });
                }else {
                    let index = priests.findIndex(x => x.id == profileId);
                    priests[index] = response.data.priest;
                    await dispatch({ type: UPDATETHISPRIEST, payload: { priest : response.data.priest } });
                    await dispatch({ type: PRIESTS, payload: { priests : priests } });
                    
                   // e.target.reset();
                    
                }
                Success("Priest Parish History Updated Successfully");
               
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function EditPriestParish(data,id) {
    let priests = store.getState().root.priests;
    let token = store.getState().root.token != null ? store.getState().root.token : store.getState().root.onboard_token;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${token}`
                    }
        });
        return AUTH.post(`priestparishedit/${id}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){

                if(store.getState().root.user_onboard_login != null)
                {
                    ////alert(JSON.stringify(response.data.priest.education));
                    let data = {
                        onboard_information: store.getState().root.onboard_information,
                        onboard_education: store.getState().root.onboard_education,
                        onboard_parish: response.data.priest.parishtilldate,
                        onboard_perferment:store.getState().root.onboard_perferment,
                        parishes:store.getState().root.allparishes
                    }
                    await dispatch({ type: ONBOARDINGUSER, payload: data });
                }else {
                    let index = priests.findIndex(x => x.id == id);
                    priests[index] = response.data.priest;
                    await dispatch({ type: UPDATETHISPRIEST, payload: { priest : response.data.priest } });
                    await dispatch({ type: PRIESTS, payload: { priests : priests } });
                    
                }
                ////alert(JSON.stringify(response.data.priest.parishtilldate));
                
                Success("Parish Updated Successfully");
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            //alert(JSON.stringify(err));
        }); 
    }
}

export function Move_diocese_to_another_province(data) {
    let priests = store.getState().root.priests;
  //  //alert(JSON.stringify(data));
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`move_diocese_to_another_province`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                await dispatch({ type: REFRESH });
                Success("Data Moved Successfully");
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function Move_archdeaconary_to_another_diocese(data) {
    let priests = store.getState().root.priests;
   // //alert(JSON.stringify(data));
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`move_archdeaconary_to_another_diocese`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
              //  //alert(JSON.stringify(response.data));
                await dispatch({ type: REFRESH });
                Success("Data Moved Successfully");
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}


export function Move_parish_to_archdeaconry(data) {
    let priests = store.getState().root.priests;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`move_parish_to_archdeaconry`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                await dispatch({ type: REFRESH });
                Success("Data Moved Successfully");
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function Move_priest_another_parish(data) {
    let priests = store.getState().root.priests;
    return function(dispatch){
        return INSTANCE.post(`move_priest_another_parish`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
               
                await dispatch({ type: REFRESH });
                Success("Data Moved Successfully");
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function GetthisPriestInformation(id)
{
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get(`getpriestInformation/${id}`)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(response.data));
                await dispatch({ type: THISPRIEST, payload: response.data });
                //Success("Perferment Updated Successfully");
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function GetParishInArchdeaconary(id)
{
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get(`getparishes/${id}`)
        .then(async (response) => {
            if(response.data.status === 'success'){
                await dispatch({ type: PARISHINARCHDEACONARY, payload: response.data });
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
        }); 
    }
}

export function GetPriestsInParish(id) {
    let priests = store.getState().root.priests;
    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.get(`priestinparish/${id}`)
        .then(async (response) => {
            if(response.data.status === 'success'){
                await dispatch({ type: PRIESTSINPARISH, payload: response.data });
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            //alert(JSON.stringify(err));
        }); 
    }
}

export function GetOnboardingUserInformation() {

    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.onboard_token}`
                    }
        });
        return AUTH.get(`onboardinguserinformation`)
        .then(async (response) => {
            if(response.data.status === 'success'){
                ////alert(JSON.stringify(response.data));
                await dispatch({ type: ONBOARDINGUSER, payload: response.data });
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            //alert(JSON.stringify(err));
        }); 
    }
}

export function SetPassword(data,id,e) {

    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.post(`setpassword/${id}`, data)
        .then(async (response) => {
            if(response.data.status === 'success'){
                Success("Password Reset Successfully");
                e.target.reset();
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            //alert(JSON.stringify(err));
        }); 
    }
}


export function DeleteProfile(id) {

    return function(dispatch){
        const AUTH = axios.create({
            baseURL: `${PATH}`,
            timeout: 20000,
            headers: {
                      'Content-Type': 'application/json', 
                       'Accept': 'application/json',
                       'Authorization': `Bearer ${store.getState().root.token}`
                    }
        });
        return AUTH.delete(`deleteprofile/${id}`)
        .then(async (response) => {
            if(response.data.status === 'success'){
                let priests = store.getState().root.priests
                let index = priests.findIndex(x =>x.id == id)
                priests.splice(index,1)
                dispatch({ type : PRIESTS, payload : { priests : priests}})
                Success("Data Deleted Successfully");
            }else {
                Error(response.data.error);
            }
        }).catch(err => {
            alert(JSON.stringify(err.response));
        }); 
    }
}

