import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Aside2 from './aside_boarding';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import CreateNewParish from './createpriestparish';
import OnboardLink from './onboardlinks';


const OnboardParish = (props) => {
	const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
  const [IsSubmitting, setIsSubmitting ] = useState(false);
  const [ show, setshow ] = useState(false);
  const [ parish, setparish ] = useState(false);
  const thispriest = useSelector(state=>state.root.onboard_information);
  const onboard_education = useSelector(state=>state.root.onboard_education);
  const onboard_parish = useSelector(state=>state.root.onboard_parish);
  const onboard_perferment = useSelector(state=>state.root.onboard_perferment);
  const allparishes = useSelector( state => state.root.allparishes);
  const [ type, settype ] = useState(false);

  const onSubmit = async (data,e) => {
    setIsSubmitting(true);
    //await dispatch(CreateDocumentType(data,e));
    setIsSubmitting(false);
  }

  const EditEducationModal = () => {

  }

  const dioceseSubmit = async (data,e) => {
    setIsSubmitting(true);
    //await dispatch(CreateDocumentType(data,e));
    setIsSubmitting(false);
  }

  const ParishName = (parishId) => {
    let index = allparishes.findIndex(x => x.id == parishId);
    return allparishes[index].name;
  }
	
    return (
        <div class="body-wrapper">
         
         <Modal show = {parish} size="lg" Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setparish(false)}>
                <Modal.Title style={{color:'#fff'}}>Add Parish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container" style={{marginTop:40}}>
                   <CreateNewParish type = {type} parish = {parish} />
                 </div>
                </Modal.Body>
          </Modal>  
        <Aside2 />
        <div class="main-wrapper mdc-drawer-app-content">
          <Header />
          <div className = "mobile">
             <OnboardLink />
          </div>
          <div class="page-wrapper mdc-toolbar-fixed-adjust">
            <main class="content-wrapper" style={{marginTop:-20}}>
              <div class="">
                <div class="">
                <div className ="header1">
                  <Link class="mdc-button" style ={{textDecorationLine:'none',fontSize:20}}>
                  Complete Your Profile 
                </Link>
                </div>  
                 <div className="">
                 <div class="header_btn" style={{width:200,marginTop:20}}>
                    <button onClick = { () => { setparish(true); settype('create'); } }  type ="submit" class="mdc-button mdc-button--raised w-100">
                    <i class="mdc-drawer-arrow material-icons">add</i> Add Parish
                    </button>
                  </div>    
                  {
                     onboard_parish != null && onboard_parish.map((parish) =>
                       <div class="card2 mt-3 tab-card">
                        <div class="desktop" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-3">
                                    <p className = "cardtitle">Parish Name</p>
                                    <p className = "body">{parish.parishId != null ? ParishName(parish.parishId) : parish.name} </p>
                                    </div>
                            </div>    
                            <div className="row" style ={{marginTop:20}}>    
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                    <p className = "cardtitle">Start Date</p>
                                    <p className = "body">{parish.startmonth} {parish.startyear}</p>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                    <p className="cardtitle">End Date</p>
                                    <p className = "body">{parish.endmonth} {parish.endyear}</p>
                                    </div>
                                    
    
                                
                            </div>
                            <div style={{position:'relative',bottom:30,right:0}}>
                                <button onClick = { () => { setparish(parish); setshow(true); settype('edit') } } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                </button>
                                
                            </div> 
                           
                            </div>     
                        </div>
                        </div>
                        <div class="mobile" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-3">
                                    <p className = "cardtitle">Parish Name</p>
                                    <p className = "bodymobile">{parish.parishId != null ? ParishName(parish.parishId) : parish.name} </p>
                                    </div>
                            </div>    
                            <div className="row" style ={{marginTop:20}}>    
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                    <p className = "cardtitle">Start Date</p>
                                    <p className = "bodymobile">{parish.startmonth} {parish.startyear}</p>
                                    </div>
                                  
                            </div>
                            <div className="row" style ={{marginTop:20}}>    
                               <div className="col-xl-6 col-lg-6 col-md-3">
                                    <p className="cardtitle">End Date</p>
                                    <p className = "bodymobile">{parish.endmonth} {parish.endyear}</p>
                                    </div>      
                              </div>
                            <div style={{position:'relative',bottom:30,right:0}}>
                                <button onClick = { () => { setparish(parish); setshow(true); settype('edit') } } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                </button>
                                
                            </div> 
                           
                            </div>     
                        </div>
                        </div>
                       </div>   
                    
                    )
                  }  
                   {
                        (onboard_parish == null || onboard_parish.length == 0 ) &&
                        <div class="card mt-3">
                        
                            <div className = "" style={{textAlign:'center'}}>
                            
                            <div class="" style={{width:'100%',marginLeft:'auto',marginRight:'auto'}}>
                                No Parish Added Yet
                            </div>

                            </div>
                        </div>
                    } 
                   
                 </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    );
}


export default withRouter(OnboardParish);