import React, {useEffect, useState} from 'react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';



const Aside = () => {
   const [show,setshow] = useState(true);
   const user = useSelector(state => state.root.user_login);
    return (
        <aside className="mdc-drawer mdc-drawer--dismissible mdc-drawer--open">
        <div className="mdc-drawer__header">
          <Link className="brand-logo" to ="/home">
            {/* <img src={require('../asset/img/logo11.png')} alt="logo"/>  */}
          </Link>
        </div>
        <div className="mdc-drawer__content">
        <div className="user-info">
        </div>
        <div className="mdc-list-group">
          <nav className="mdc-list mdc-drawer-menu">
            {
              user.userType == 'superadmin' &&
              <div className="mdc-list-item mdc-drawer-item">
                <Link className="mdc-drawer-link" to="/home">
                  <i className="material-icons mdc-list-item__start-detail mdc-drawer-item-icon" aria-hidden="true">dashboard</i>
                  Dashboard
                </Link>
              </div>
            }
           
            <div className="mdc-list-item mdc-drawer-item">
              <Link className="mdc-expansion-panel-link" to = "/provinces" data-toggle="expansionPanel" data-target="product">
                <i className="material-icons mdc-list-item__start-detail mdc-drawer-item-icon" aria-hidden="true">home</i>
                Provinces
               
              </Link>
            </div>
            {
                user.userType == 'superadmin' &&
                <div className="mdc-list-item mdc-drawer-item">
                  <Link className="mdc-expansion-panel-link" to = "/move" data-toggle="expansionPanel" data-target="product">
                    <i className="material-icons mdc-list-item__start-detail mdc-drawer-item-icon" aria-hidden="true">share</i>
                    Move Data
                  
                  </Link>
                </div>
            }
           
            {
              user.userType == 'superadmin' &&
                <div className="mdc-list-item mdc-drawer-item">
                <Link className="mdc-expansion-panel-link" to = "/report" data-toggle="expansionPanel" data-target="product">
                  <i className="material-icons mdc-list-item__start-detail mdc-drawer-item-icon" aria-hidden="true">settings</i>
                  Report
                
                </Link>
              </div> 
            }
            
            {
              user.userType == 'superadmin' &&
              <div className="mdc-list-item mdc-drawer-item">
                <Link className="mdc-expansion-panel-link" to = "/users" data-toggle="expansionPanel" data-target="product">
                  <i className="material-icons mdc-list-item__start-detail mdc-drawer-item-icon" aria-hidden="true">person</i>
                  Users
                
                </Link>
              </div>
            }
            {
               user.userType == 'superadmin' &&
               <div className="mdc-list-item mdc-drawer-item">
                <Link className="mdc-expansion-panel-link" to = "/onboard_settings" data-toggle="expansionPanel" data-target="product">
                  <i className="material-icons mdc-list-item__start-detail mdc-drawer-item-icon" aria-hidden="true">group</i>
                  Onboarding
                
                </Link>
              </div>
            }
            
           
          </nav>
        </div>
       
      </div>
      </aside>
        
  
    );

}

export default Aside;