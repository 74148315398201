import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, useHistory, withRouter, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { IMAGEPATH, LOADERCOLOR } from '../redux/action/constant';
import { EditPerferment, GetthisPriestInformation } from '../redux/action/index';
import Aside from './aside';
import { Tabs,Tab } from 'react-bootstrap';
import PHeader from './province_header';
import { Modal } from 'react-bootstrap';
import EditPriestInformation from './editpriest';
import CreateNewEducation from './createpriesteducation';
import CreateNewParish from './createpriestparish';
import CreateNewPerferment from './createnewperferment';
import EditSelectedPerferment from './editperferment';
import EditSelectedEducation from './editeducation';
import EditSelectedParish from './editparish';



const Priest = (props) => {
	const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
    const [IsSubmitting, setIsSubmitting ] = useState(false);
    const [ show, setshow ] = useState(false);
    const [ information, setinformation ] = useState(null);
    const [ education, seteducation ] = useState(null);
    const [ parish, setparish ] = useState(null);
    const [ perferment, setperferment ] = useState(null);
    const history = useHistory();
    const [ IsFetching, setIsFetching ] = useState(false);
    const thispriest = useSelector(state => state.root.thispriest);
    const allparishes = useSelector( state => state.root.allparishes);
    const { id } = useParams();
    const [which, setwhich ] = useState('Information');
    const user = useSelector(state => state.root.user_login);


    useEffect(() => {
       Process();
       console.log(thispriest);
    },[]);

    const Process = async () => {
        let priestid = window.atob(id);
        //alert(priestid);
        setIsFetching(true);
        await dispatch(GetthisPriestInformation(priestid));
        //alert(JSON.stringify(thispriest));
        setIsFetching(false);
    }

    const ParishName = (parishId) => {
        let index = allparishes.findIndex(x => x.id == parishId);
        return allparishes[index].name;
    }
    
    const onSubmit = () => {

    }

    const EditEducationModal = () => {

    }

    
    const ToShow = (type) => {

        //alert(type);
        if(type == 'Education')
        {
            setwhich(type);
            setshow(true);
        }
        if(type == 'Parishes')
        {
            setwhich(type);
            setshow(true);
        }
        if(type == 'Perferment')
        {
            setwhich(type);
            setshow(true);
        }
        if(type == 'Information')
        {
            setwhich(type);
            setshow(true);
        }
    }
    return (
        <div class="body-wrapper">
        <Modal show = {information == null ? false : true} size="lg" Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setinformation(null)}>
                <Modal.Title style={{color:'#fff'}}>Create Priest Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container" style={{marginTop:40}}>
                  <div style={{marginTop:30}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                style={{marginBottom:20}}
                                placeholder ="First Name" 
                                name ="firstname"
                                type ="text" 
                                ref={register({
                                    required: "Required"
                                    })}
                            />
                                <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                style={{marginBottom:20}}
                                placeholder ="Last Name" 
                                name ="lastname"
                                type ="text" 
                                ref={register({
                                    required: "Required"
                                    })}
                            />
                                <small className="text-danger">{errors.lastname?.type == "required" && "Last Name is required"}</small>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                placeholder ="Middle Name" 
                                style={{marginBottom:20}}
                                name ="middlename"
                                type ="text" 
                                ref={register({
                                    required: "Required"
                                    })}
                            />
                                <small className="text-danger">{errors.middlename?.type == "required" && "Middle Name is required"}</small>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                placeholder ="Phone Number 1" 
                                style={{marginBottom:20}}
                                name ="phonenumber1"
                                type ="text" 
                                ref={register({
                                    required: "Required"
                                    })}
                            />
                                <small className="text-danger">{errors.phonenumber1?.type == "required" && "Phone Number 1 is required"}</small>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                placeholder ="Phone Number 2" 
                                style={{marginBottom:20}}
                                name ="phonenumber2"
                                type ="text" 
                                ref={register({
                                    required: "Required"
                                    })}
                            />
                                <small className="text-danger">{errors.phonenumber2?.type == "required" && "Phone NUmber 2 is required"}</small>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                placeholder ="Email 1" 
                                style={{marginBottom:20}}
                                name ="email1"
                                type ="email" 
                                ref={register({
                                    required: "Required"
                                    })}
                            />
                                <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                placeholder ="Email 2" 
                                style={{marginBottom:20}}
                                name ="email2"
                                type ="email" 
                                ref={register({
                                
                                    })}
                            />
                                <small className="text-danger">{errors.email2?.type == "required" && "Email 2 is required"}</small>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                placeholder ="Status" 
                                style={{marginBottom:20}}
                                name ="status"
                                type ="text" 
                                ref={register({
                                    required: "Required"
                                    })}
                            />
                                <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                placeholder ="Status" 
                                style={{marginBottom:20}}
                                name ="file"
                                type ="file" 
                                ref={register({
                                
                                    })}
                            />
                                <small className="text-danger">{errors.file?.type == "required" && "Image is required"}</small>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                placeholder ="Date of Birth" 
                                style={{marginBottom:20}}
                                name ="date_of_birth"
                                type ="date" 
                                ref={register({
                                
                                    })}
                            />
                                <small className="text-danger">{errors.file?.type == "required" && "Image is required"}</small>
                            </div>
                        
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                placeholder ="Status" 
                                style={{marginBottom:20}}
                                name ="date_priested"
                                type ="file" 
                                ref={register({
                                
                                    })}
                            />
                                <small className="text-danger">{errors.file?.type == "required" && "Image is required"}</small>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <select 
                            className="form-control" 
                            style={{marginBottom:20}}
                            name ="marital_status"
                            type ="date" 
                            ref={register({
                                
                                })}
                            >
                                <option value="">Married Status</option>
                            </select>    
                            <small className="text-danger">{errors.file?.type == "required" && "Image is required"}</small>
                            </div>
                        
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                placeholder ="Position" 
                                style={{marginBottom:20}}
                                name ="position"
                                type ="file" 
                                ref={register({
                                
                                    })}
                            />
                                <small className="text-danger">{errors.file?.type == "required" && "Image is required"}</small>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <input 
                                className="form-control" 
                                placeholder ="Image" 
                                style={{marginBottom:20}}
                                name ="image"
                                type ="file" 
                                ref={register({
                                
                                    })}
                            />   
                            <small className="text-danger">{errors.file?.type == "required" && "Image is required"}</small>
                            </div>
                        
                        </div>
                        <div className="row" style={{marginTop:10}}>
                            <div className="col-lg-4 col-sm-4">
                            <div style={{marginTop:0}} class="">
                            {
                            IsSubmitting == false &&
                            <button type ="submit" class="mdc-button mdc-button--raised">
                                Submit
                            </button>
                            }
                            {
                            IsSubmitting == true &&
                            <div style={{textAlign:'center'}}>
                            <Loader
                                visible={true}
                                type="Puff"
                                color="#7a00ff"
                                height={30}
                                width={30}
                                timeout= {0} //3 secs
                            />
                            </div>
                            }
                        </div>
                            </div>
                            
                        </div>
                    </form>
                    </div>
                 </div>
                </Modal.Body>
          </Modal> 
          <Modal show = {education == null ? false : true} size="lg" Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => seteducation(null)}>
                <Modal.Title style={{color:'#fff'}}>Add Education</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container" style={{marginTop:40}}>
                  <div style={{marginTop:30}}>
                      <EditSelectedEducation education = { education != null && education } />
                    </div>
                 </div>
                </Modal.Body>
          </Modal>
          <Modal show = {parish == null ? false : true} size="lg" Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setparish(null)}>
                <Modal.Title style={{color:'#fff'}}>Add Parish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container" style={{marginTop:40}}>
                  <div style={{marginTop:30}}>
                    <EditSelectedParish parish = {parish} />
                 </div>
                 </div>
                </Modal.Body>
          </Modal>
         
          <Modal show = {perferment == null ? false : true} size="lg" Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setperferment(null)}>
                <Modal.Title style={{color:'#fff'}}>Add Perferment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditSelectedPerferment perferment = {perferment} />
                </Modal.Body>
          </Modal>
          <Modal show = {show} size="lg" Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshow(false)}>
                <Modal.Title style={{color:'#fff'}}>Create Priest Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container" style={{marginTop:40}}>
                 <Tabs defaultActiveKey={which} id="uncontrolled-tab-example">
                  <Tab eventKey="Information" title="Priest Information" style={{fontSize:16,fontSize:30,backgroundColor:'#fff'}}>
                    <EditPriestInformation priest = { thispriest != null && thispriest } />
                  </Tab>
                  <Tab eventKey="Education" title="Education">
                  <div>
                      
                    <CreateNewEducation id = { thispriest != null ? thispriest.id : ''} />
                  </div>
                  </Tab>
                  <Tab eventKey="Parishes" title="Parishes">
                    <CreateNewParish id = { thispriest != null ? thispriest.id : ''}  />
                  </Tab>
                  <Tab eventKey="Perferment" title="Perferment">
                      <CreateNewPerferment id = { thispriest != null ? thispriest.id : ''} />
                  
                  </Tab>
                 
                </Tabs>
                 </div>
                </Modal.Body>
          </Modal>
        <Aside />
        <div class="main-wrapper mdc-drawer-app-content">
          <Header />
          <div className = "mobile">
          <div className = "bottomwrapper">
                <div style = {{display:'flex', flexDirection:'row',justifyContent:'space-around'}}>
                    <div onClick = { () => { setwhich('Information'); window.scrollTo(0,0) } } className = "bottomicon">
                        <div style={{textAlign:'center'}}><i style = {{color: which == 'Information' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">person</i></div>
                        <div style = {{color: which == 'Information' ? '#7a00ff' : ''}} className = "bottomtext">Information</div>
                    </div>
                    <div onClick = { () => { setwhich('Education'); window.scrollTo(0,0) } } className = "bottomicon">
                        <div ><i style = {{color: which == 'Education' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">book</i></div>
                        <div style = {{color: which == 'Education' ? '#7a00ff' : ''}} className = "bottomtext">Education</div>
                    </div>
                    <div onClick = { () => { setwhich('Parishes'); window.scrollTo(0,0) } } className = "bottomicon">
                        <div ><i style = {{color: which == 'Parishes' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">home</i></div>
                        <div style = {{color: which == 'Parishes' ? '#7a00ff' : ''}} className = "bottomtext">Parish</div>
                    </div>
                    <div onClick = { () => { setwhich('Perferment'); window.scrollTo(0,0) } } className = "bottomicon">
                        <div ><i style = {{color: which == 'Perferment' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">home</i></div>
                        <div style = {{color: which == 'Perferment' ? '#7a00ff' : ''}} className = "bottomtext">Perferment</div>
                    </div>
                   
                </div>            
          </div>
          </div>
          <div class="page-wrapper mdc-toolbar-fixed-adjust">
            <main class="content-wrapper" style={{marginTop:-20}}>
              <div class="">
                <div class="">
                <div className = "marginTop">
                  <Link onClick = { () => history.goBack() } class="mdc-button" style ={{textDecorationLine:'none',fontSize:20, padding:0, textTransform:'uppercase'}}>
                  <i style={{fontSize:40}} class="mdc-drawer-arrow material-icons">chevron_left</i>
                  <div class="displaytitle">
                    {thispriest!= null && thispriest.firstname} {thispriest!= null && thispriest.lastname} Information
                  </div>
                  </Link>
                </div>
                {/* <h4>Diocese Information</h4>   */}
                 <div className ="desktop">
                 <div className="container" style={{marginTop:20}}>
                 <Tabs defaultActiveKey="Information" id="uncontrolled-tab-example">
                  <Tab eventKey="Information" title="Personal Information" style={{fontSize:16,fontSize:30}}>
                  <div class="header_btn" style={{width:200,marginTop:20}}>
                  {
                    ( user.userType == 'superadmin' || user.userType == 'admin' ) && 
                    <button onClick = { () => ToShow('Information') }  type ="submit" class="mdc-button mdc-button--raised w-100">
                    <i class="mdc-drawer-arrow material-icons">add</i> Edit Information
                    </button>
                  }  
                  </div>    
                    {
                        IsFetching == false && thispriest != null &&
                        <div>
                        <div className ="desktop">    
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                              <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-3">
                                        <div className="" style={{width:'100%'}}>
                                        {
                                            thispriest.image == '' &&
                                            <img class="card-img-top" style={{height:"100%",width:'100%'}} src={require(`../asset/images/profile.jpg`)} alt="Card image cap"/>

                                        }
                                        {
                                            thispriest.image != '' &&
                                            <img class="card-img-top" style={{height:"100%",width:'100%'}} src={`${IMAGEPATH}files/image400/${thispriest.image}`} alt="Card image cap"/>

                                        }
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-9 col-lg-9 col-md-3" style={{width:'55%'}}>
                                    <div class="row">
                                        <div className="col-xl-6 col-lg-6 col-md-3">
                                        <p className = "cardtitle">Name</p>
                                        <p className = "body">{thispriest.firstname} {thispriest.lastname} {thispriest.middlename}</p>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-3">
                                        <p className = "cardtitle">Status</p>
                                        <p className="body">{thispriest.status}</p>
                                        </div>
                                    
                                    </div>
                                    <div class="row" style={{marginTop:20}}>
                                    
                                        <div className="col-xl-6 col-lg-6 col-md-3" style={{marginTop:10}}>
                                        <p className ="cardtitle">Position</p>
                                        <p className =" body">{thispriest.position}</p>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-3" style={{marginTop:10}}>
                                        <p className="cardtitle">Email1</p>
                                        <p className="body">{thispriest.email1}</p>
                                        </div>
                                        
                                    </div>
                                    </div>
                                    
                                </div>
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                    <div class="row">
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Email2</p>
                                        <p className = "body">{thispriest.email2}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Phone Number 1</p>
                                        <p className = "body">{thispriest.phonenumber1}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg- col-md-3">
                                        <p className = "cardtitle">Phone Number 2</p>
                                        <p className = "body">{thispriest.phonenumber2}</p>
                                        </div>
                                    
                                        
                                    </div>
                                   
                                </div>     
                            </div>
                            </div>
                        </div>
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                    <div class="row" style={{marginTop:20}}>
                                        
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Date Deaconed</p>
                                        <p className = "body">{thispriest.date_deaconed}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Date of Birth</p>
                                        <p className = "body">{thispriest.date_of_birth}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Date Priested</p>
                                        <p className = "body">{thispriest.date_priested}</p>
                                        </div>
                                        
                                    
                                        
                                    </div>
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div class="row" style={{marginTop:20}}>
                                        
                                        
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Gender</p>
                                        <p className = "body">{thispriest.gender}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Marital Status</p>
                                        <p className = "body">{thispriest.marital_status}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Name of Spouse</p>
                                        <p className = "body">{thispriest.name_of_spouse}</p>
                                        </div>
                                    
                                        
                                    </div>
                            
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div class="row" style={{marginTop:20}}>
                                        
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Date Married</p>
                                        <p className = "body">{thispriest.date_married}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Spouse Qualification</p>
                                        <p className = "body">{thispriest.qualification_of_spouse}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Name of Spouse</p>
                                        <p className = "body">{thispriest.name_of_spouse}</p>
                                        </div>
                                    
                                        
                                    </div>
                            
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div class="row" style={{marginTop:20}}>
                                        
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                        <p className = "cardtitle">Present Parish</p>
                                        <p className = "body">{thispriest.parish_id != null ? ParishName(thispriest.parish_id) : 'Not Defined'}</p>
                                        </div>
                                       
                                    </div>
                            
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>

                        
                        

                    }
                    {
                        IsFetching == true &&
                        <div class="card mt-3">
                        
                            <div className = "" style={{textAlign:'center'}}>
                            
                            <div style={{textAlign:'center'}}>
                                <Loader
                                    visible={true}
                                    type="Puff"
                                    color="#7a00ff"
                                    height={30}
                                    width={30}
                                    timeout= {0} //3 secs
                                />
                            </div>

                            </div>
                        </div>
                    } 
                  </Tab>
                  <Tab eventKey="Education" title="Education">
                  <div class="header_btn" style={{width:200,marginTop:20}}>
                  {
                    ( user.userType == 'superadmin' || user.userType == 'admin' ) &&  
                    <button onClick = { () => ToShow('Education') }  type ="submit" class="mdc-button mdc-button--raised w-100">
                    <i class="mdc-drawer-arrow material-icons">add</i> Add Education
                    </button>
                  }  
                  </div>   
                    {
                        IsFetching == false && thispriest != null &&  thispriest.education != null && thispriest.education.map((education) => 
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div className="row">
                                        <div className="col-xl-6 col-lg-4 col-md-4">
                                        <p className = "cardtitle">Name of Institution</p>
                                        <p  className = "bodymobile">{education.institution}</p>
                                        </div>
                                        <div className="col-xl-6 col-lg-4 col-md-4">
                                        <p className = "cardtitle">Course</p>
                                        <p className = "bodymobile">{education.course}</p>
                                        </div>
                                </div>
                               
                                <div className="row" style={{marginTop:40}}>
                                   <div className="col-xl-4 col-lg-4 col-md-4">
                                        <p className = "cardtitle">Start Date Attended</p>
                                        <p className = "bodymobile">{education.startmonth} {education.startyear}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-3" >
                                        <p className = "cardtitle">End Date Attended</p>
                                        <p className = "bodymobile">{education.endmonth} {education.endyear}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-3">
                                        <p className = "cardtitle">Qualification</p>
                                        <p className = "bodymobile">{education.qualification}</p>
                                    </div>
        
                                    
                                </div>
                                <div style={{position:'relative',bottom:30,right:0}}>
                                    <button onClick = { () => seteducation(education) } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                    <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                    </button>
                                    
                                </div> 
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        )
                    } 
                   
                    {
                        IsFetching == false && thispriest != null && thispriest.education.length == 0 &&
                        <div class="card mt-3">
                        
                            <div className = "" style={{textAlign:'center'}}>
                            
                            <div class="" style={{width:'30%',marginLeft:'auto',marginRight:'auto'}}>
                                No Education Added Yet
                            </div>

                            </div>
                        </div>
                    } 
                    {
                        IsFetching == true &&
                        <div class="card mt-3">
                        
                            <div className = "" style={{textAlign:'center'}}>
                            
                            <div style={{textAlign:'center'}}>
                                <Loader
                                    visible={true}
                                    type="Puff"
                                    color="#7a00ff"
                                    height={30}
                                    width={30}
                                    timeout= {0} //3 secs
                                />
                            </div>

                            </div>
                        </div>
                    } 
                    
                  </Tab>
                  <Tab eventKey="Parish" title="Parish">
                  <div class="header_btn" style={{width:200,marginTop:20}}>
                  {
                    ( user.userType == 'superadmin' || user.userType == 'admin' ) &&  
                    <button onClick = { () => ToShow('Parishes') }  type ="submit" class="mdc-button mdc-button--raised w-100">
                    <i class="mdc-drawer-arrow material-icons">add</i> Add Parish
                    </button>
                  }
                  </div> 
                  {
                      IsFetching == false && thispriest != null && thispriest.parishtilldate != null && thispriest.parishtilldate.map((parish) =>
                       <div class="card2 mt-3 tab-card">
                        <div class="" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-3">
                                    <p className = "cardtitle">Parish Name</p>
                                    <p className = "body">{parish.parishId != null ? ParishName(parish.parishId) : parish.name} </p>
                                    </div>
                            </div>    
                            <div className="row" style ={{marginTop:20}}>    
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                    <p className = "cardtitle">Start Date</p>
                                    <p className = "body">{parish.startmonth} {parish.startyear}</p>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                    <p className="cardtitle">End Date</p>
                                    <p className = "body">{parish.endmonth} {parish.endyear}</p>
                                    </div>
                                    
    
                                
                            </div>
                            <div style={{position:'relative',bottom:30,right:0}}>
                                <button onClick = { () => setparish(parish) } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                </button>
                                
                            </div> 
                           
                            </div>     
                        </div>
                        </div>
                    </div>   
                    
                    )
                  }  
                   {
                        IsFetching == false && thispriest != null && thispriest.parishtilldate.length == 0 &&
                        <div class="card mt-3">
                        
                            <div className = "" style={{textAlign:'center'}}>
                            
                            <div class="" style={{width:'30%',marginLeft:'auto',marginRight:'auto'}}>
                                No Parish Added Yet
                            </div>

                            </div>
                        </div>
                    } 
                    {
                        IsFetching == true &&
                        <div class="card mt-3">
                        
                            <div className = "" style={{textAlign:'center'}}>
                            
                            <div style={{textAlign:'center'}}>
                                <Loader
                                    visible={true}
                                    type="Puff"
                                    color="#7a00ff"
                                    height={30}
                                    width={30}
                                    timeout= {0} //3 secs
                                />
                            </div>

                            </div>
                        </div>
                    }   
                  </Tab>
                  <Tab eventKey="Perferment" title="Perferment">
                  <div class="header_btn" style={{width:200,marginTop:20}}>
                  {
                    ( user.userType == 'superadmin' || user.userType == 'admin' ) &&  
                    <button onClick = { () => ToShow('Perferment') }  type ="submit" class="mdc-button mdc-button--raised w-100">
                    <i class="mdc-drawer-arrow material-icons">add</i> Add Preferment
                    </button>
                  } 
                  </div> 
                   {
                      IsFetching == false && thispriest != null && thispriest.preferment != null && thispriest.preferment.map((per) => 
                       <div class="card2 mt-3 tab-card">
                        <div class="" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                    <p className = "cardtitle">Status</p>
                                    <p className = "body">{per.status}</p>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                    <p className = "cardtitle">Start Date</p>
                                    <p className = "body">{per.startMonth} {per.startYear}</p>
                                    </div>
                                    
    
                                
                            </div>
                            <div style={{position:'relative',bottom:30,right:0}}>
                                <button onClick = { () => setperferment(per) } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                </button>
                                
                            </div> 
                           
                            </div>     
                        </div>
                        </div>
                    </div>
                    
                    )
                   }  
                    {
                        IsFetching == false && thispriest != null && thispriest.preferment.length == 0 &&
                        <div class="card mt-3">
                        
                            <div className = "" style={{textAlign:'center'}}>
                            
                            <div class="" style={{width:'30%',marginLeft:'auto',marginRight:'auto'}}>
                                No Perferment Added Yet
                            </div>

                            </div>
                        </div>
                    } 
                    {
                        IsFetching == true &&
                        <div class="card mt-3">
                        
                            <div className = "" style={{textAlign:'center'}}>
                            
                            <div style={{textAlign:'center'}}>
                                <Loader
                                    visible={true}
                                    type="Puff"
                                    color="#7a00ff"
                                    height={30}
                                    width={30}
                                    timeout= {0} //3 secs
                                />
                            </div>

                            </div>
                        </div>
                    }
                  </Tab>                 
                </Tabs>
                 </div>
                 </div>
                 <div className = "mobile">
                    {
                        which == 'Information' &&
                        <div>
                            {
                                ( user.userType == 'superadmin' || user.userType == 'admin' ) && 
                                <div>
                                    <div class="header_btn" style={{width:200,marginTop:20}}>
                                    {
                                        ( user.userType == 'superadmin' || user.userType == 'admin' ) &&  
                                        <button onClick = { () => ToShow('Information') }  type ="submit" class="mdc-button mdc-button--raised w-100">
                                        <i class="mdc-drawer-arrow material-icons">add</i> Edit Information
                                        </button>
                                    }   
                                    </div> 
                                </div> 
                            }  
                           
                            {
                                IsFetching == false && thispriest != null &&
                                <div>
                                <div class="card2 mt-3 tab-card">
                                <div class="mobile" id="">
                                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                            <div style={{padding:30}}>
                                            <div className="row">
                                                <div className="col-xl-1 col-lg-2 col-md-2 pixside">
                                                    <div className="" style={{width:'100%'}}>
                                                    {
                                                        thispriest.image == '' &&
                                                        <img class="card-img-top" style={{height:"100%",width:'100%'}} src={require(`../asset/images/profile.jpg`)} alt="Card image cap"/>

                                                    }
                                                    {
                                                        thispriest.image != '' &&
                                                        <img class="card-img-top" style={{height:"100%",width:'100%'}} src={`${IMAGEPATH}files/image400/${thispriest.image}`} alt="Card image cap"/>

                                                    }   

                                                    </div>
                                                </div>
                                            
                                                <div className="col-xl-9 col-lg-9 col-md-3" style={{width:'55%'}}>
                                                <div>
                                                <p class="cardtitle">{thispriest.firstname} {thispriest.lastname} </p>
                                                <p class="bodymobile">{thispriest.status} </p>
                                                <p class="bodymobile">{thispriest.position} </p>
                                                </div>    
                                            
                                                </div>
                                                <hr  />
                                                
                                            
                                            </div>
                                            </div>     
                                        </div>
                                </div>
                                </div>
                                <div class="card2 mt-3 tab-card">
                                <div class="" id="">
                                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                            <div style={{padding:30}}>
                                                <div class="row">
                                                    <div className="col-xl-12 col-lg-4 col-md-4" style={{marginBottom:20}}>
                                                        <p className ="cardtitle">Email 1</p>
                                                        <p className ="bodymobile">{thispriest.email1 == null ? 'Unknown' : thispriest.email1} </p>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-4 col-md-4" style={{marginBottom:20}}>
                                                        <p className ="cardtitle">Email 2</p>
                                                        <p className ="bodymobile">{thispriest.email2 == null ? 'Unknown' : thispriest.email2} </p>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-4" style={{marginBottom:20}}>
                                                        <p className ="cardtitle">Phone Number 1</p>
                                                        <p className ="bodymobile">{thispriest.phonenumber1 == null ? 'Unknown' : thispriest.phonenumber1}</p>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-4" style={{marginBottom:20}}>
                                                        <p class="cardtitle">Phone Number 2</p>
                                                        <p className="bodymobile">{thispriest.phonenumber2 == null ? 'Unknown' : thispriest.phonenumber2}</p>
                                                    </div>
                                                </div>
                                                
                                    
                                            </div>     
                                        </div>
                                </div>
                                </div>
                                <div class="card2 mt-3 tab-card">
                                    <div class="" id="">
                                    <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                        <div style={{padding:30}}>
                                            <div class="row">
                                                
                                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                                <p className = "cardtitle">Date Deaconed</p>
                                                <p className = "bodymobile">{thispriest.date_deaconed}</p>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                                <p className = "cardtitle">Date of Birth</p>
                                                <p className = "bodymobile">{thispriest.date_of_birth}</p>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                                <p className = "cardtitle">Date of Priested</p>
                                                <p className = "bodymobile">{thispriest.date_priested}</p>
                                                </div>
                                            
                                                
                                            </div>
                                        
                                    
                                        </div>     
                                    </div>
                                    </div>
                                </div>
                        
                                <div class="card2 mt-3 tab-card">
                                    <div class="" id="">
                                    <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                        <div style={{padding:30}}>
                                        <div class="row">
                                                
                                                
                                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                                <p className = "cardtitle">Gender</p>
                                                <p className = "bodymobile">{thispriest.gender}</p>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                                <p className = "cardtitle">Marital Status</p>
                                                <p className = "bodymobile">{thispriest.marital_status}</p>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                                <p className = "cardtitle">Name of Spouse</p>
                                                <p className = "bodymobile">{thispriest.name_of_spouse}</p>
                                                </div>
                                            
                                                
                                            </div>
                                    
                                            
                                    
                                        </div>     
                                    </div>
                                    </div>
                                </div>
                                <div class="card2 mt-3 tab-card">
                                    <div class="" id="">
                                    <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                        <div style={{padding:30}}>
                                        <div class="row" style={{marginTop:20}}>
                                                
                                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                                <p className = "cardtitle">Date Married</p>
                                                <p className = "bodymobile">{thispriest.date_married}</p>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                                <p className = "cardtitle">Spouse Qualification</p>
                                                <p className = "bodymobile">{thispriest.qualification_of_spouse}</p>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                                <p className = "cardtitle">Name of Spouse</p>
                                                <p className = "bodymobile">{thispriest.name_of_spouse}</p>
                                                </div>
                                            
                                                
                                            </div>
                                    
                                            
                                    
                                        </div>     
                                    </div>
                                    </div>
                                </div>
                                <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div class="row" style={{marginTop:20}}>
                                        
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                        <p className = "cardtitle">Present Parish</p>
                                                <p className = "bodymobile">{ thispriest.parish_id != null ? ParishName(thispriest.parish_id) : 'Not Defined' }</p>
                                        </div>
                                       
                                    </div>
                            
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>      
             
                                </div>
                            }
                            {
                                IsFetching == true &&
                                <div class="card mt-3">
                                
                                    <div className = "" style={{textAlign:'center'}}>
                                    
                                    <div style={{textAlign:'center'}}>
                                        <Loader
                                            visible={true}
                                            type="Puff"
                                            color="#7a00ff"
                                            height={30}
                                            width={30}
                                            timeout= {0} //3 secs
                                        />
                                    </div>

                                    </div>
                                </div>
                            } 
                        </div>
                    }
                    {
                        which == 'Education' &&
                        <div>
                            <div class="header_btn" style={{width:200,marginTop:20}}>
                            {
                                ( user.userType == 'superadmin' || user.userType == 'admin' ) &&  
                                <button onClick = { () => ToShow('Education') }  type ="submit" class="mdc-button mdc-button--raised w-100">
                                <i class="mdc-drawer-arrow material-icons">add</i> Add Education
                                </button>
                            }  
                            </div>   
                                {
                                    IsFetching == false && thispriest != null &&  thispriest.education != null && thispriest.education.map((education) => 
                                    <div class="card2 mt-3 tab-card">
                                        <div class="desktop" id="">
                                          <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                            <div style={{padding:30}}>
                                            <div className="row">
                                                    <div className="col-xl-6 col-lg-4 col-md-4">
                                                    <p className = "cardtitle">Name of Institution</p>
                                                    <p style={{marginBottom:5,fontSize:16}} class="">{education.institution}</p>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-4 col-md-4">
                                                    <p className = "cardtitle">Course</p>
                                                    <p style={{marginBottom:5,fontSize:16}} class="">{education.course}</p>
                                                    </div>
                                            </div>
                                        
                                            <div className="row" style={{marginTop:40}}>
                                            <div className="col-xl-4 col-lg-4 col-md-4">
                                                    <p className = "cardtitle">Start Date Attended</p>
                                                    <p style={{marginBottom:5,fontSize:16}} class="">{education.startmonth} {education.startyear}</p>
                                                </div>
                                                <div className="col-xl-4 col-lg-6 col-md-3" >
                                                    <p className = "cardtitle">End Date Attended</p>
                                                    <p style={{marginBottom:5,fontSize:16}} class="">{education.endmonth} {education.endyear}</p>
                                                </div>
                                                <div className="col-xl-4 col-lg-6 col-md-3">
                                                    <p className = "cardtitle">Qualification</p>
                                                    <p style={{marginBottom:5,fontSize:16}} class="">{education.qualification}</p>
                                                </div>
                    
                                                
                                            </div>
                                            <div style={{position:'relative',bottom:30,right:0}}>
                                                <button onClick = { () => seteducation(education) } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                                <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                                </button>
                                                
                                            </div> 
                                        
                                            </div>     
                                        </div>
                                        </div>
                                        <div class="mobile" id="">
                                          <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                            <div style={{padding:30}}>
                                            <div className="row">
                                                    <div className="col-xl-6 col-lg-4 col-md-4">
                                                    <p className = "cardtitle">Name of Institution</p>
                                                    <p class="bodymobile">{education.institution}</p>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-4 col-md-4" style={{marginTop:20}}>
                                                    <p className = "cardtitle">Course</p>
                                                    <p class="bodymobile">{education.course}</p>
                                                    </div>
                                            </div>
                                        
                                            <div className="row" style={{marginTop:20}}>
                                            <div className="col-xl-4 col-lg-4 col-md-4">
                                                    <p className = "cardtitle">Start Date Attended</p>
                                                    <p class="bodymobile">{education.startmonth} {education.startyear}</p>
                                                </div>
                                                <div className="col-xl-4 col-lg-6 col-md-3" style={{marginTop:20}}>
                                                    <p className = "cardtitle">End Date Attended</p>
                                                    <p class="bodymobile">{education.endmonth} {education.endyear}</p>
                                                </div>
                                                <div className="col-xl-4 col-lg-6 col-md-3" style={{marginTop:20}}>
                                                    <p className = "cardtitle">Qualification</p>
                                                    <p class="bodymobile">{education.qualification}</p>
                                                </div>
                    
                                                
                                            </div>
                                            <div style={{position:'relative',bottom:30,right:0}}>
                                                <button onClick = { () => seteducation(education) } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                                <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                                </button>
                                                
                                            </div> 
                                        
                                            </div>     
                                        </div>
                                        </div>
                                   
                                    </div>
                                    )
                                } 
                            
                                {
                                    IsFetching == false && thispriest != null && thispriest.education.length == 0 &&
                                    <div class="card mt-3">
                                    
                                        <div className = "" style={{textAlign:'center'}}>
                                        
                                        <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                                            No Education Added Yet
                                        </div>

                                        </div>
                                    </div>
                                } 
                                {
                                    IsFetching == true &&
                                    <div class="card mt-3">
                                    
                                        <div className = "" style={{textAlign:'center'}}>
                                        
                                        <div style={{textAlign:'center'}}>
                                            <Loader
                                                visible={true}
                                                type="Puff"
                                                color="#7a00ff"
                                                height={30}
                                                width={30}
                                                timeout= {0} //3 secs
                                            />
                                        </div>

                                        </div>
                                    </div>
                                } 
                        </div>
                    }
                    {
                        which == 'Parishes' &&
                        <div>
                            <div class="header_btn" style={{width:200,marginTop:20}}>
                            {
                                ( user.userType == 'superadmin' || user.userType == 'admin' ) &&  
                                <button onClick = { () => ToShow('Parishes') }  type ="submit" class="mdc-button mdc-button--raised w-100">
                                <i class="mdc-drawer-arrow material-icons">add</i> Add Parish
                                </button>
                            }
                            </div> 
                            {
                                IsFetching == false && thispriest != null && thispriest.parishtilldate != null && thispriest.parishtilldate.map((parish) =>
                                <div class="card2 mt-3 tab-card">
                                    <div class="desktop" id="">
                                      <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                        <div style={{padding:30}}>
                                        <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-3">
                                                <p className = "cardtitle">Parish Name</p>
                                                <p className = "body">{parish.parishId != null ? ParishName(parish.parishId) : parish.name} </p>
                                                </div>
                                        </div>    
                                        <div className="row" style ={{marginTop:20}}>    
                                                <div className="col-xl-6 col-lg-6 col-md-3">
                                                <p className = "cardtitle">Start Date</p>
                                                <p className = "body">{parish.startmonth} {parish.startyear}</p>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-3">
                                                <p className="cardtitle">End Date</p>
                                                <p className = "body">{parish.endmonth} {parish.endyear}</p>
                                                </div>
                                                
                
                                            
                                        </div>
                                        <div style={{position:'relative',bottom:30,right:0}}>
                                            <button onClick = { () => setparish(parish) } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                            <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                            </button>
                                            
                                        </div> 
                                    
                                        </div>     
                                    </div>
                                    </div>
                                    <div class="mobile" id="">
                                      <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                        <div style={{padding:30}}>
                                        <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-3">
                                                <p className = "cardtitle">Parish Name</p>
                                                <p className = "bodymobile">{parish.parishId != null ? ParishName(parish.parishId) : parish.name} </p>
                                                </div>
                                        </div>    
                                        <div className="row" style ={{marginTop:20}}>    
                                                <div className="col-xl-6 col-lg-6 col-md-3" style={{width:'50%'}}>
                                                <p className = "cardtitle">Start Date</p>
                                                <p className = "bodymobile">{parish.startmonth} {parish.startyear}</p>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-3" style={{width:'50%'}}>
                                                <p className="cardtitle">End Date</p>
                                                <p className = "bodymobile">{parish.endmonth} {parish.endyear}</p>
                                                </div>
                                                
                
                                            
                                        </div>
                                        <div style = {{display:'flex',flexDirection:"row", justifyContent:'flex-end', margin:7,width:'100%',marginTop:0}}>
                                            <div style={{}} className="btnlist">
                                           
                                                
                                            </div>
                                            <div style={{}} className="btnlist">
                                            <button onClick = { () => setparish(parish) } type ="submit" class="mdc-button w-10 actionshow" style={{textDecoration:'none', border:'2px solid #7a00ff',padding:2}}>
                                            <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                            </button>
                                        
                                            </div>
                                        </div> 
                                       
                                    
                                        </div>     
                                    </div>
                                    </div>
                                </div>   
                                
                                )
                            }  
                            {
                                IsFetching == false && thispriest != null && thispriest.parishtilldate.length == 0 &&
                                <div class="card mt-3">
                                
                                    <div className = "" style={{textAlign:'center'}}>
                                    
                                    <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                                        No Parish Added Yet
                                    </div>

                                    </div>
                                </div>
                            } 
                            {
                                IsFetching == true &&
                                <div class="card mt-3">
                                
                                    <div className = "" style={{textAlign:'center'}}>
                                    
                                    <div style={{textAlign:'center'}}>
                                        <Loader
                                            visible={true}
                                            type="Puff"
                                            color="#7a00ff"
                                            height={30}
                                            width={30}
                                            timeout= {0} //3 secs
                                        />
                                    </div>

                                    </div>
                                </div>
                            }   
                        </div>
                    }
                    {
                        which == 'Perferment' &&
                        <div>
                            <div class="header_btn" style={{width:200,marginTop:20}}>
                            {
                                ( user.userType == 'superadmin' || user.userType == 'admin' ) &&  
                                <button onClick = { () => ToShow('Perferment') }  type ="submit" class="mdc-button mdc-button--raised w-100">
                                <i class="mdc-drawer-arrow material-icons">add</i> Add Preferment
                                </button>
                            } 
                            </div> 
                            {
                                IsFetching == false && thispriest != null && thispriest.preferment != null && thispriest.preferment.map((per) => 
                                <div class="card2 mt-3 tab-card">
                                    <div class="desktop" id="">
                                    <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                        <div style={{padding:30}}>
                                        <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-3">
                                                <p className = "cardtitle">Status</p>
                                                <p className = "body">{per.status}</p>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-3">
                                                <p className = "cardtitle">Start Date</p>
                                                <p className = "body">{per.startMonth} {per.startYear}</p>
                                                </div>
                                                
                
                                            
                                        </div>
                                        <div style={{position:'relative',bottom:30,right:0}}>
                                            <button onClick = { () => setperferment(per) } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                            <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                            </button>
                                            
                                        </div> 
                                    
                                        </div>     
                                    </div>
                                    </div>
                                    <div class="mobile" id="">
                                    <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                        <div style={{padding:30}}>
                                        <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-3" style={{width:'50%'}}>
                                                <p className = "cardtitle">Status</p>
                                                <p className = "bodymobile">{per.status}</p>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-3" style={{width:'50%'}}>
                                                <p className = "cardtitle">Start Date</p>
                                                <p className = "bodymobile">{per.startMonth} {per.startYear}</p>
                                                </div>
                                                
                
                                            
                                        </div>
                                       
                                        <div style = {{display:'flex',flexDirection:"row", justifyContent:'flex-end', margin:7,width:'100%',marginTop:0}}>
                                            <div style={{}} className="btnlist">
                                             
                                            </div>
                                            <div style={{}} className="btnlist">
                                          
                                                <button onClick = { () => setperferment(per) } type ="submit" class="mdc-button w-10 actionshow" style={{textDecoration:'none', border:'2px solid #7a00ff',padding:2}}>
                                                <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                                </button>
                                            </div>
                                        </div>
                                    
                                        </div>     
                                    </div>
                                    </div>
                                </div>
                                
                                )
                            }  
                            {
                                    IsFetching == false && thispriest != null && thispriest.preferment.length == 0 &&
                                    <div class="card mt-3">
                                    
                                        <div className = "" style={{textAlign:'center'}}>
                                        
                                        <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                                            No Perferment Added Yet
                                        </div>

                                        </div>
                                    </div>
                                } 
                                {
                                    IsFetching == true &&
                                    <div class="card mt-3">
                                    
                                        <div className = "" style={{textAlign:'center'}}>
                                        
                                        <div style={{textAlign:'center'}}>
                                            <Loader
                                                visible={true}
                                                type="Puff"
                                                color="#7a00ff"
                                                height={30}
                                                width={30}
                                                timeout= {0} //3 secs
                                            />
                                        </div>

                                        </div>
                                    </div>
                                }
                        </div>
                    }
                 </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    );
}


export default withRouter(Priest);