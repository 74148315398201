import {
    ARCHDEACONRIES,
    MYEDUCATION,
    MYPARISHES,
    MYPERFERMENT,
    NEWARCHDEACONRY,
    NEWPARISH,
    NEWPROVINCE,
    OFFICIALS,
    PARISHES,
    PRIESTS,
    NEWPRIEST,
    USER_LOGIN, 
    USER_REGISTER,
    NEWOFFICIAL,
    REFRESH,
    PROVINCES,
    DIOCESES,
    DIOCEANOFFICIALS,
    NEWDIOCESE,
    MYPROFILE,
    NEWDIOCEANOFFICIAL,
    USER_ONBOARD_REGISTER,
    NEWPROVINCEOFFICIAL,
    PROVINCEOFFICIALS,
    THISPRIEST,
    UPDATETHISPRIEST,
    YEAR,
    PARISHINARCHDEACONARY,
    PRIESTSINPARISH,
    ADMINS,
    NEWADMIN,
    PORTALS,
    NEWPORTAL,
    ONBOARDINGUSER,
    DASHBOARD
} from '../action/constant';
import { act } from 'react-dom/test-utils';
import storage from 'redux-persist/lib/storage';

const initialState = {
    user_login:{},
    IsFetchingdata:false,
    officials:[],
    provinces : [],
    dioceses : [],
    dioceanofficials:[],
    archdeaconries:[],
    parishes:[],
    priests:[],
    myprofile:null,
    myeducation:[],
    myperferments:[],
    myparishes:[],
    token:null,
    IsLoggedIn:false,
    user_onboard_login:null,
    onboard_token:null,
    thispriest:null,
    allparishes:[],
    year:[],
    parishesInarchdeaconries:[],
    admins:[],
    portals:[],
    onboard_diocese:null,
    onboard_information:null,
    onboard_education:null,
    onboard_parish:null,
    onboard_perferment:null,
    dashboard:null,
    VicarsInDiocese:null,
    month: ['January','February','March','April','May','June','July','August','September','October','November','December']

}

export function RootReducer(state = initialState, action)
{
    if(action.type === USER_LOGIN){
        return Object.assign({}, state, {
            user_login: action.payload.user,
            token:action.payload.access_token,
            IsLoggedIn: true,
            
        });
    }

    if(action.type === DASHBOARD){
        return Object.assign({}, state, {
            dashboard: action.payload
            
        });
    }
    if(action.type === ONBOARDINGUSER){

        ////alert(JSON.stringify(action.payload.parishes));
        return Object.assign({}, state, {
            onboard_information: action.payload.onboard_information,
            onboard_education:action.payload.onboard_education,
            onboard_parish: action.payload.onboard_parish,
            onboard_perferment:action.payload.onboard_perferment,
            allparishes:action.payload.parishes
            
        });
    }
    
    if(action.type === ADMINS){
        return Object.assign({}, state, {
            admins: action.payload.admins
        });
    }
    if(action.type === NEWADMIN){
        return Object.assign({}, state, {
            admins: state.admins.concat(action.payload.admin)
        });
    }
    if(action.type === NEWPORTAL){
        return Object.assign({}, state, {
            portals: state.portals.concat(action.payload.portal)
        });
    }
    if(action.type === PORTALS){
        return Object.assign({}, state, {
            portals: action.payload.portals
        });
    }
    if(action.type === YEAR){
        return Object.assign({}, state, {
            year: action.payload
            
        });
    }
    if(action.type == USER_REGISTER)
    {
        return Object.assign({}, state, {
            user_login: action.payload.user,
            token:action.payload.access_token

        });
    }
    if(action.type == USER_ONBOARD_REGISTER)
    {
        return Object.assign({}, state, {
            user_onboard_login: action.payload.user,
            onboard_token:action.payload.access_token,
            onboard_diocese:action.payload.diocese
        });
    }
    if(action.type == PROVINCES)
    {
        return Object.assign({}, state, {
            provinces: action.payload.provinces
        });
    }
    if(action.type == OFFICIALS)
    {
        return Object.assign({}, state, {
            officials: action.payload.officials
        });
    }
    if(action.type == NEWPROVINCEOFFICIAL)
    {
        return Object.assign({}, state, {
            officials: state.officials.concat(action.payload.official)
        });
    }
    if(action.type == DIOCEANOFFICIALS)
    {
        return Object.assign({}, state, {
            dioceanofficials: action.payload.officials
        });
    }
    if(action.type == NEWDIOCEANOFFICIAL)
    {
        return Object.assign({}, state, {
            dioceanofficials: state.dioceanofficials.concat(action.payload.official)
        });
    }
    if(action.type == NEWOFFICIAL)
    {
        return Object.assign({}, state, {
            officials: state.officials.concat(action.payload.official)
        });
    }
    if(action.type == NEWPROVINCE)
    {
        ////alert(JSON.stringify(action.payload.province));
        return Object.assign({}, state, {
            provinces: state.provinces.concat(action.payload.province)
        });
    }
    if(action.type == DIOCESES)
    {
        return Object.assign({}, state, {
            dioceses: action.payload.dioceses
        });
    }
    if(action.type == NEWDIOCESE)
    {
        return Object.assign({}, state, {
            dioceses: state.dioceses.concat(action.payload.diocese)
        });
    }
    if(action.type == ARCHDEACONRIES)
    {
       ////alert(JSON.stringify(action.payload.archdeaconaries));
        return Object.assign({}, state, {
            archdeaconries: action.payload.archdeaconaries,
            VicarsInDiocese: action.payload.profiles
        });
    }
    if(action.type == PARISHINARCHDEACONARY)
    {
       ////alert(JSON.stringify(action.payload.parishes));
        return Object.assign({}, state, {
            parishesInarchdeaconries: action.payload.parishes
        });
    }
    if(action.type == PRIESTSINPARISH)
    {
       ////alert(JSON.stringify(action.payload.parishes));
        return Object.assign({}, state, {
            priestsInParish: action.payload.priests
        });
    }
    if(action.type == NEWARCHDEACONRY)
    {
        return Object.assign({}, state, {
            archdeaconries: state.archdeaconries.concat(action.payload.archdeaconary)
        });
    }
    if(action.type == PARISHES)
    {
        return Object.assign({}, state, {
            parishes: action.payload.parishes
        });
    }
    if(action.type == NEWPARISH)
    {
        return Object.assign({}, state, {
            parishes: state.parishes.concat(action.payload.parish)
        });
    }
    if(action.type == PRIESTS)
    {
        state.priests = []
        return Object.assign({}, state, {
            priests: state.priests.concat(action.payload.priests)
        });
    }
    if(action.type == THISPRIEST)
    {
        return Object.assign({}, state, {
            thispriest: action.payload.priest,
            allparishes:action.payload.parishes
        });
    }
    if(action.type == UPDATETHISPRIEST)
    {
        return Object.assign({}, state, {
            thispriest: action.payload.priest
        });
    }
    if(action.type == NEWPRIEST)
    {
        
        return Object.assign({}, state, {
            priests: state.priests.concat(action.payload.priest)
        });
    }
    if(action.type == NEWPARISH)
    {
        return Object.assign({}, state, {
            priests: state.priests.concat(action.payload.priest)
        });
    }
    if(action.type == MYPROFILE)
    {
        return Object.assign({}, state, {
            myprofile: action.payload.myprofile
        });
    }
    if(action.type == MYEDUCATION)
    {
        return Object.assign({}, state, {
            myprofile: action.payload.myeducation
        });
    }
    if(action.type == MYPARISHES)
    {
        return Object.assign({}, state, {
            myparishes: action.payload.myparishes
        });
    }
    if(action.type == MYPERFERMENT)
    {
        return Object.assign({}, state, {
            myperferments: action.payload.myperferments
        });
    }
    if(action.type == REFRESH)
    {
        return Object.assign({}, state, {
            user_login:{},
            IsFetchingdata:false,
            officials:[],
            provinces : [],
            dioceses : [],
            dioceanofficials:[],
            archdeaconries:[],
            parishes:[],
            priests:[],
            myprofile:null,
            myeducation:[],
            myperferments:[],
            myparishes:[],
            token:null,
            IsLoggedIn:false,
            user_onboard_login:null,
            onboard_token:null,
            thispriest:null,
            allparishes:[],
            year:[],
            parishesInarchdeaconries:[],
            admins:[],
            portals:[],
            onboard_diocese:null,
            onboard_information:null,
            onboard_education:null,
            onboard_parish:null,
            onboard_perferment:null,
        });
    }
    
    
    

    return state;

}