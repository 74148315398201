import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Aside2 from './aside_boarding';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import CreateNewEducation from './createpriesteducation';
import OnboardLink from './onboardlinks';


const OnboardEducation = (props) => {
	const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
  const [IsSubmitting, setIsSubmitting ] = useState(false);
  const [ show, setshow ] = useState(false);
  const [ education, seteducation ] = useState(false);
  const thispriest = useSelector(state=>state.root.onboard_information);
  const onboard_education = useSelector(state=>state.root.onboard_education);
  const [type, settype ] = useState('create');

  useEffect(() => {
      if(thispriest == null) props.history.push('/onboard_information');
  },[]);
  const onSubmit = async (data,e) => {
    setIsSubmitting(true);
    //await dispatch(CreateDocumentType(data,e));
    setIsSubmitting(false);
  }

  const EditEducationModal = () => {

  }

  const dioceseSubmit = async (data,e) => {
    setIsSubmitting(true);
    //await dispatch(CreateDocumentType(data,e));
    setIsSubmitting(false);
  }
	
    return (
        <div class="body-wrapper">
        <Modal show = {show} size="lg" Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshow(false)}>
                <Modal.Title style={{color:'#fff'}}>Edit Priest Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container" style={{marginTop:40}}>
                   <CreateNewEducation type = {type} education = {education} />

                 </div>
                </Modal.Body>
          </Modal> 
        <Aside2 />
        <div class="main-wrapper mdc-drawer-app-content">
          <Header />
          <div className = "mobile">
             <OnboardLink />
          </div>
          <div class="page-wrapper mdc-toolbar-fixed-adjust">
            <main class="content-wrapper" style={{marginTop:-20}}>
              <div class="">
                <div class="">
                <div className ="header1">
                  <Link class="mdc-button" style ={{textDecorationLine:'none',fontSize:20}}>
                  Complete Your Profile 
                </Link>
                </div>  
                 <div className="">
                 <div class="header_btn" style={{width:200,marginTop:20}}>
                    <button onClick = { () => { setshow(true); settype('create'); } }  type ="submit" class="mdc-button mdc-button--raised w-100">
                    <i class="mdc-drawer-arrow material-icons">add</i> Add Education
                    </button>
                  </div>    
                  {
                    onboard_education != null && onboard_education.map((education) => 
                    <div class="card2 mt-3 tab-card">
                        <div class="desktop" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                    <div className="col-xl-6 col-lg-4 col-md-4">
                                    <p className = "cardtitle">Name of Institution</p>
                                    <p style={{marginBottom:5,fontSize:16}} class="">{education.institution}</p>
                                    </div>
                                    <div className="col-xl-6 col-lg-4 col-md-4">
                                    <p className = "cardtitle">Course</p>
                                    <p style={{marginBottom:5,fontSize:16}} class="">{education.course}</p>
                                    </div>
                            </div>
                           
                            <div className="row" style={{marginTop:40}}>
                               <div className="col-xl-4 col-lg-4 col-md-4">
                                    <p className = "cardtitle">Start Date Attended</p>
                                    <p style={{marginBottom:5,fontSize:16}} class="">{education.startmonth} {education.startyear}</p>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-3" >
                                    <p className = "cardtitle">End Date Attended</p>
                                    <p style={{marginBottom:5,fontSize:16}} class="">{education.endmonth} {education.endyear}</p>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-3">
                                    <p className = "cardtitle">Qualification</p>
                                    <p style={{marginBottom:5,fontSize:16}} class="">{education.qualification}</p>
                                </div>
    
                                
                            </div>
                            <div style={{position:'relative',bottom:30,right:0}}>
                                <button onClick = { () => { seteducation(education); setshow(true); settype('edit') } } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                </button>
                                
                            </div> 
                        
                            </div>     
                        </div>
                        </div>
                        <div class="mobile" id="">
                          <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                    <div className="col-xl-6 col-lg-4 col-md-4">
                                    <p className = "cardtitle">Name of Institution</p>
                                    <p class="bodymobile">{education.institution}</p>
                                    </div>
                                    <div className="col-xl-6 col-lg-4 col-md-4" style={{marginTop:20}}>
                                    <p className = "cardtitle">Course</p>
                                    <p class="bodymobile">{education.course}</p>
                                    </div>
                            </div>
                        
                            <div className="row" style={{marginTop:20}}>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                    <p className = "cardtitle">Start Date Attended</p>
                                    <p class="bodymobile">{education.startmonth} {education.startyear}</p>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-3" style={{marginTop:20}}>
                                    <p className = "cardtitle">End Date Attended</p>
                                    <p class="bodymobile">{education.endmonth} {education.endyear}</p>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-3" style={{marginTop:20}}>
                                    <p className = "cardtitle">Qualification</p>
                                    <p class="bodymobile">{education.qualification}</p>
                                </div>
    
                                
                            </div>
                            <div style={{position:'relative',bottom:30,right:0}}>
                                <button onClick = { () => { seteducation(education); setshow(true); settype('edit') } } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                </button>
                                
                            </div> 
                        
                            </div>     
                        </div>
                        </div>
                                   
                    </div>
                    )
                  }
                  {
                        (onboard_education == null || onboard_education.length == 0 ) &&
                        <div class="card mt-3">
                        
                            <div className = "" style={{textAlign:'center'}}>
                            
                            <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                                No Education Added Yet
                            </div>

                            </div>
                        </div>
                    }
                 </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    );
}


export default withRouter(OnboardEducation);