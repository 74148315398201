import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, useParams, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Aside from './aside';
import { Modal } from 'react-bootstrap';
import { GetDioceses, EditDiocese } from '../redux/action';
import { IMAGEPATH } from '../redux/action/constant';



const Card9 = (props) => {
    const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
    const [IsSubmitting, setIsSubmitting ] = useState(false);
    const [ showdiocese, setshowdiocese ] = useState(false);
    const [ IsFetching, setIsFetching] = useState(false);
    const dioceses = useSelector(state => state.root.dioceses);
    const [ edit, setedit ] = useState(false);
    const [ diocese, setdiocese ] = useState(null);
    const [ file, setfile ] = useState(null);
    const user = useSelector(state => state.root.user_login);
    const { id } = useParams();
    useEffect(() => {
      Process();
    },[]);

    const Process = async () => {
      setIsFetching(true);
      let dioceseId = window.atob(id);
      let index = dioceses.findIndex(x=> x.id == dioceseId);
      setdiocese(dioceses[index]);
      //alert(JSON.stringify(index));
      //await dispatch(GetBishopInformation(dioceseId));
      setIsFetching(false);
    }
    const onSubmit = async (data,e) => {
        setIsSubmitting(true);
        //await dispatch(CreateDocumentType(data,e));
        setIsSubmitting(false);
    }
    const handleChange = (event) => {
      setfile(event.target.files[0]);
   }

   const dioceseSubmit = async (data,e) => {
    setIsSubmitting(true);
    const formdata = new FormData();
    formdata.append('name',data.name);
    formdata.append('firstname', data.firstname);
    formdata.append('address', data.address);
    formdata.append('lastname',data.lastname);
    formdata.append('status', data.status);
    formdata.append('position',data.position);
    formdata.append('email1',data.email1);
    formdata.append('phonenumber1', data.phonenumber1);
    if(file != null) formdata.append('file', file);
    await dispatch(EditDiocese(data,diocese.id, e));
    setIsSubmitting(false);
  }

  const PriestParish = (parishId) => {
        if(parishId == null) return 'Not Defined';

  }


    return (
        <div className="">
              <Modal show = {edit} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setedit(false)}>
                <Modal.Title style={{color:'#fff'}}>Edit Diocese</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(dioceseSubmit)}>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Diocese Name" 
                            defaultValue = { diocese != null && diocese.name }
                            name ="name"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.province_name?.type == "required" && "Province Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="First Name" 
                            defaultValue = { diocese != null && diocese.leader.firstname }
                            style={{marginBottom:20}}
                            name ="firstname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Last Name" 
                            defaultValue = { diocese != null && diocese.leader.lastname }
                            style={{marginBottom:20}}
                            name ="lastname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.lastname?.type == "required" && "Last Name is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                             className="form-control"  
                             style={{marginBottom:20}}
                             defaultValue = { diocese != null && diocese.leader.position }
                             name ="position"
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Position</option>
                         <option value="Primate">Primate</option>
                         <option value="Archbishop">Archbishop</option>
                         <option value="Bishop">Bishop</option>
                         <option value="Provost">Provost</option>
                         <option value="Dean">Dean</option>
                         <option value="Vicar">Vicar</option>
                         <option value="Curate">Curate</option>
                         <option value="Deacon">Deacon</option>
                         <option value="Evangelist">Evangelist</option>
                        </select>     
                       
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                             className="form-control" 
                             placeholder ="Status" 
                             style={{marginBottom:20}}
                             defaultValue = { diocese != null && diocese.leader.status }
                             name ="status"
                             type ="text" 
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Status</option>
                         <option value="Most Revd">Most Revd</option>
                         <option value="Right Revd">Right Revd</option>
                         <option value="Very Revd">Very Revd</option>
                         <option value="Venerable">Venerable</option>
                         <option value="Revd Canon">Revd Canon</option>
                         <option value="Revd">Revd</option>
                         <option value="Evangelist">Evangelist</option>
                         <option value="Mr.">Mr.</option>
                        </select>    
                        {/* <input 
                           
                        /> */}
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Phone Number 1"
                            defaultValue = { diocese != null && diocese.leader.phonenumber1 }
                            style={{marginBottom:20}} 
                            name ="phonenumber1"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.phonenumber1?.type == "required" && "Phone Number is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Email 1" 
                            defaultValue = { diocese != null && diocese.leader.email1 }
                            style={{marginBottom:20}}
                            name ="email1"
                            type ="email" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            onChange={handleChange}
                          
                            type ="file" 
                            ref={register({
                               
                                })}
                        />
                            <small className="text-primary">Image file</small><br/>
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <textarea
                          className="form-control" 
                          placeholder ="Diocese Address" 
                          style={{marginBottom:20}}
                          name ="address"
                          rows="5"
                          ref={register({
                              required: "Required"
                              })}
                        >
                        {diocese != null && diocese.address }
                        </textarea>    
                      
                            <small className="text-danger">{errors.address?.type == "required" && "Address is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>  
              
             {
               diocese != null && 
              <div> 
              <div class="card2 mt-3 tab-card">
                  <div class="card-header tab-card-header" style={{backgroundColor:'#fff',color:'#7A00FF',padding:4}}>
                          <div>
                          <a class="nav-link title">{diocese.name} Diocese</a>
                          {
                           ( user.userType == 'superadmin' || user.userType == 'admin') && 
                          <button onClick = { () => { setedit(true); setdiocese(diocese) } } type ="submit" class="mdc-button w-10" style={{borderRadius:4,float:'right',marginTop:-40}}>
                          <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit Information
                          </button>
                          }
                          
                          </div>
                          
                  
                  </div>
                  <div class="desktop" id="">
                  <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                      <div className="padding">
                      <div className="row">
                          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-4">
                              <div className="" style={{width:'100%'}}>
                              {
                                (diocese.leader.image == '' || diocese.leader.image == null) &&
                                <img class="card-img-top" style={{height:80,width:80}} src={require(`../asset/images/profile.jpg`)}/>

                              }
                              {
                                    diocese.leader.image != '' && diocese.leader.image != null &&
                                    <img class="card-img-top" style={{height:80,width:80}} src={`${IMAGEPATH}files/image400/${diocese.leader.image}`} />

                              } 
                              </div>
                          </div>
                          
                          <div className="col-xl-10 col-lg-10 col-md-3" style={{width:'55%'}}>
                          <div class="row">
                              <div className="col-xl-6 col-lg-6 col-md-3 col-sm-12">
                                <p className ="cardtitle">Name</p>
                                <p className ="body">{diocese.leader.firstname} {diocese.leader.lastname}</p>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-3 col-sm-6">
                                <p className ="cardtitle">Status</p>
                                <p className ="body">{diocese.leader.status}</p>
                              </div> 
                              </div>
                              <div class="row displaymargin">
                            
                              <div className="col-xl-6 col-lg-6 col-md-3 col-sm-6">
                                <p className ="cardtitle">Position</p>
                                <p className ="body">{diocese.leader.position}</p>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-3 col-sm-12">
                                <p className ="cardtitle">Email</p>
                                <p className ="body">{diocese.leader.email1}</p>
                              </div>
                              
                              </div>  
                          </div>
                          
                      </div>
                      
                      </div>     
                  </div>
                  </div>
                  <div class="mobile" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                <div className="col-xl-1 col-lg-2 col-md-2 pixside">
                                    <div className="" style={{width:'100%'}}>
                                     {
                                         diocese.leader.image == '' &&
                                         <img class="card-img-top" style={{height:"100%",width:'100%'}} src={require(`../asset/images/profile.jpg`)} alt="Card image cap"/>

                                     }
                                     {
                                         diocese.leader.image != '' &&
                                         <img class="card-img-top" style={{height:"100%",width:'100%'}} src={`${IMAGEPATH}files/image400/${diocese.leader.image}`} alt="Card image cap"/>

                                     }   

                                    </div>
                                </div>
                               
                                <div className="col-xl-9 col-lg-9 col-md-3" style={{width:'55%'}}>
                                <div>
                                  <p class="cardtitle">{diocese.leader.firstname} {diocese.leader.lastname} </p>
                                  <p class="bodymobile">{diocese.leader.status} </p>
                                  <p class="bodymobile">{diocese.leader.position} </p>
                                </div>    
                               
                                </div>
                                <hr  />
                                 
                               
                            </div>
                            </div>     
                        </div>
                  </div>
              </div>
              <div className ="desktop">    
                       
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                    <div class="row">
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Email 2</p>
                                        <p className = "body">{diocese.leader.email2}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Phone Number 1</p>
                                        <p className = "body">{diocese.leader.phonenumber1}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg- col-md-3">
                                        <p className = "cardtitle">Phone Number 2</p>
                                        <p className = "body">{diocese.leader.phonenumber2}</p>
                                        </div>
                                    
                                        
                                    </div>
                                   
                                </div>     
                            </div>
                            </div>
                        </div>
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                    <div class="row" style={{marginTop:20}}>
                                        
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Date Deaconed</p>
                                        <p className = "body">{diocese.leader.date_deaconed}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Date of Birth</p>
                                        <p className = "body">{diocese.leader.date_of_birth}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Date of Priested</p>
                                        <p className = "body">{diocese.leader.date_priested}</p>
                                        </div>
                                    
                                        
                                    </div>
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div class="row" style={{marginTop:20}}>
                                        
                                        
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Gender</p>
                                        <p className = "body">{diocese.leader.gender}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Marital Status</p>
                                        <p className = "body">{diocese.leader.marital_status}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Name of Spouse</p>
                                        <p className = "body">{diocese.leader.name_of_spouse}</p>
                                        </div>
                                    
                                        
                                    </div>
                            
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div class="row" style={{marginTop:20}}>
                                        
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Date Married</p>
                                        <p className = "body">{diocese.leader.date_married}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Spouse Qualification</p>
                                        <p className = "body">{diocese.leader.qualification_of_spouse}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Name of Spouse</p>
                                        <p className = "body">{diocese.leader.name_of_spouse}</p>
                                        </div>
                                    
                                        
                                    </div>
                            
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div class="row" style={{marginTop:20}}>
                                        
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                        <p className = "cardtitle">Present Parish</p>
                                        <p className = "body">Not Defined</p>
                                        </div>
                                       
                                    </div>
                            
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        
                        </div>
             
              <div className = "mobile">
                <div class="card2 mt-3 tab-card">
                    <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                    <div class="row">
                                        <div className="col-xl-12 col-lg-4 col-md-4" style={{marginBottom:20}}>
                                            <p className ="cardtitle">Email 1</p>
                                            <p className ="bodymobile">{diocese.leader.email1 == null ? 'Unknown' : diocese.leader.email1} </p>
                                        </div>
                                        <div className="col-xl-12 col-lg-4 col-md-4" style={{marginBottom:20}}>
                                            <p className ="cardtitle">Email 2</p>
                                            <p className ="bodymobile">{diocese.leader.email2 == null ? 'Unknown' : diocese.leader.email2} </p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-4" style={{marginBottom:20}}>
                                            <p className ="cardtitle">Phone Number 1</p>
                                            <p className ="bodymobile">{diocese.leader.phonenumber1 == null ? 'Unknown' : diocese.leader.phonenumber1}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-4" style={{marginBottom:20}}>
                                            <p class="cardtitle">Phone Number 2</p>
                                            <p className="bodymobile">{diocese.leader.phonenumber2 == null ? 'Unknown' : diocese.leader.phonenumber2}</p>
                                        </div>
                                    </div>
                                    
                        
                                </div>     
                            </div>
                    </div>
                </div>
                <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                    <div class="row">
                                        
                                        <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                        <p className = "cardtitle">Date Deaconed</p>
                                        <p className = "bodymobile">{diocese.leader.date_deaconed}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                        <p className = "cardtitle">Date of Birth</p>
                                        <p className = "bodymobile">{diocese.leader.date_of_birth}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                        <p className = "cardtitle">Date of Priested</p>
                                        <p className = "bodymobile">{diocese.leader.date_priested}</p>
                                        </div>
                                    
                                        
                                    </div>
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        
                <div class="card2 mt-3 tab-card">
                    <div class="" id="">
                    <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                        <div style={{padding:30}}>
                        <div class="row">
                                
                                
                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                <p className = "cardtitle">Gender</p>
                                <p className = "bodymobile">{diocese.leader.gender}</p>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                <p className = "cardtitle">Marital Status</p>
                                <p className = "bodymobile">{diocese.leader.marital_status}</p>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                <p className = "cardtitle">Name of Spouse</p>
                                <p className = "bodymobile">{diocese.leader.name_of_spouse}</p>
                                </div>
                            
                                
                            </div>
                    
                            
                    
                        </div>     
                    </div>
                    </div>
                </div>
                <div class="card2 mt-3 tab-card">
                    <div class="" id="">
                    <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                        <div style={{padding:30}}>
                        <div class="row" style={{marginTop:20}}>
                                
                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                <p className = "cardtitle">Date Married</p>
                                <p className = "bodymobile">{diocese.leader.date_married}</p>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                <p className = "cardtitle">Spouse Qualification</p>
                                <p className = "bodymobile">{diocese.leader.qualification_of_spouse}</p>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                <p className = "cardtitle">Name of Spouse</p>
                                <p className = "bodymobile">{diocese.leader.name_of_spouse}</p>
                                </div>
                            
                                
                            </div>
                    
                            
                    
                        </div>     
                    </div>
                    </div>
                </div>
                <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div class="row" style={{marginTop:20}}>
                                        
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                        <p className = "cardtitle">Present Parish</p>
                                        <p className = "bodymobile">Not Defined</p>
                                        </div>
                                       
                                    </div>
                            
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>      
             
              </div>
                </div>
             }
        </div>
    );
}

export default Card9;