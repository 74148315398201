/* global jQuery, padding */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import '../src/script/js/vendors/js/vendor.bundle.base.js';
//import '../src/script/js/js/material.js';
//import '../src/script/js/js/misc';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
