import React, {useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Aside from './aside';
import Header from './header';
import Footer from './footer';
import { CreateAdmin, GetAdmins, EditAdmin, GetPortals, CreatePortal, EditPortal } from '../redux/action/index';
import Loader from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import '../asset/css/bootstrap.css';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import SearchData from './search';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { GetDiocese } from '../Message/getdata';
import { ONBOARDLINK } from '../redux/action/constant';

const OnboardSettings = (props) => {
    const users = useSelector(state => state.root.users);
    const userstotalpages = useSelector(state => state.root.userstotalpages);
    const dispatch = useDispatch();
    const [IsFetching, setIsFetching] = useState(false);
    const [IsSubmitting, setIsSubmitting] = useState(false);
    const [ show, setshow ] = useState(false);
    const { handleSubmit, errors, register, watch } = useForm();
    const admins = useSelector(state => state.root.admins);
    const [ edit, setedit ] = useState(null);
    const portals = useSelector(state => state.root.portals);
    const [ diocese, setdiocese ] = useState(null);

    useEffect(() => {
      process();
    },[]);

    const loadOptions = async (inputValue, callback) => {
        let r = await GetDiocese(inputValue);
        return r;
     }

    const process = async () => {
      setIsFetching(true);
      await dispatch(GetPortals());
      setIsFetching(false);
    }

    const onSubmit = async (data,e) => {
        setIsSubmitting(true);
        if(diocese == null) return false;
        data['dioceseId'] = diocese;
        await dispatch(CreatePortal(data,e));
        setIsSubmitting(false);
    }

    const updateSubmit = async (data) => {
        setIsSubmitting(true);
        if(edit == null) return false;
        data['dioceseId'] = edit.dioceseId;
        await dispatch(EditPortal(data,edit.id));
        setIsSubmitting(false);
    }

    return (
        <div class="body-wrapper">
        <Aside />
        <Modal show = {show} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshow(false)}>
                <Modal.Title style={{color:'#fff'}}>Open Portal for New Users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                   <div className="row" style={{marginBottom:20}}>
                        <div className="col-lg-12 col-sm-12">
                        <small className="text-secondary" style={{fontSize:14,marginBottom:4}}>Diocese Name</small> 
                        <AsyncSelect   
                            cacheOptions
                            defaultOptions
                            placeholder="Choose Diocese"
                            loadOptions = {loadOptions}
                            isClearable
                            onChange = {(opt) => setdiocese(opt==null ? '' : opt.value) }
                        />    
                      
                            <small className="text-danger">{errors.diocese?.type == "required" && "Name is required"}</small>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:10}}>
                        <div className="col-lg-12 col-sm-12">
                        <small className="text-secondary" style={{fontSize:14,marginBottom:4}}>Expiration Date</small>     
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            name ="expirationdate"
                            type ="date" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.startdate?.type == "required" && "Start Date is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>
          <Modal show = {edit == null ? false : true} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setedit(null)}>
                <Modal.Title style={{color:'#fff'}}>Create Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(updateSubmit)}>
                   <div className="row" style = {{marginBottom:20}}>
                        <div className="col-lg-12 col-sm-12">
                        <small className="text-secondary" style={{fontSize:14}}>Diocese Name</small> 
                        <AsyncSelect   
                            cacheOptions
                            defaultOptions = { edit != null ? [{'label':edit.diocese.name,"id":edit.dioceseId}] : ''}
                            placeholder="Choose Diocese"
                            loadOptions = {loadOptions}
                            isClearable
                            onChange = {(opt) => setdiocese(opt==null ? '' : opt.value) }
                        />    
                      
                            <small className="text-danger">{errors.diocese?.type == "required" && "Name is required"}</small>
                        </div>
                    </div>
                    <div className="row" style = {{marginBottom:10}}>
                        <div className="col-lg-12 col-sm-12">
                        <small className="text-secondary" style={{fontSize:14,marginBottom:4}}>Expiration Date</small>       
                        <input 
                            className="form-control" 
                            name ="expirationdate"
                            defaultValue = { edit != null && edit.expirationdate }
                            type ="date" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.startdate?.type == "required" && "Start Date is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>

    <div class="main-wrapper mdc-drawer-app-content">
    <Header />

      <div class="page-wrapper mdc-toolbar-fixed-adjust">
        <main class="content-wrapper" style={{marginTop:-20}}>
          <div class="">
            <div class="">
               <div className ="header1">
                  <Link class="mdc-button" style ={{textDecorationLine:'none',fontSize:20}}>
                  Church of Nigeria 
                </Link>
                </div>  
              <div class="header_btn2">
                    <div>
                    <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-100">
                    <i class="mdc-drawer-arrow material-icons">add</i> Open Portal for New Users
                    </button>
                    </div>
               
                </div>  
              <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                <div class="mdc-card p-0">
                  <h6 class="card-title card-padding pb-0">Portal History</h6>
                      
                  <div>
                  <p class="card-padding pb-0" style = {{marginTop:-30}}>Link to Register : { ONBOARDLINK }/onboard_register</p>  
                  <p class="card-padding pb-0" style = {{marginTop:-40}}>Link to Login : { ONBOARDLINK }/onboard_login</p>  
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                        <th className="text-center">S/N</th>
                          <th className="text-center">Diocese Name</th>
                          <th className="text-center">Code</th>
                          <th className="text-center">Code Expiration Date</th>
                          <th className="text-center">Option</th>
                        </tr>
                      </thead>
                      <tbody>
                       {
                         portals != null && portals.map((portal,x) =>
                           <tr>
                           <td className ="text-center">{x+1}</td>  
                           <td className ="text-center">{portal.diocese.name}</td>
                           <td className ="text-center">{portal.code}</td>
                           <td className ="text-center">{portal.expirationdate}</td>
                           <td onClick = { () => setedit(portal)  } className ="text-center"> <i class="mdc-drawer-arrow material-icons">edit</i> </td>
                           </tr>
                         )
                       }
                     </tbody>
                    </table>
                  </div>
                  {
                    IsFetching == false && portals != null && portals.length == 0 &&
                    <div style={{textAlign:'center'}}>
                       <p>No Data Found</p>
                    </div>
                  }
                  {
                        IsFetching == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                          visible={true}
                          type="Puff"
                          color="#7a00ff"
                          height={30}
                          width={30}
                          timeout= {0} //3 secs
                      
                        />
                        </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {/* {
            userstotalpages != null && userstotalpages > 1 &&
            <div style={{textAlign:'center'}}>
              <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={<a href="">...</a>}
                  pageCount={userstotalpages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  containerClassName={'pagination'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  activeClassName={'active'}
              /> 
            </div>
          } */}
        </main>
        <Footer />
      </div>
    </div>
  </div>
    );
}

export default OnboardSettings;