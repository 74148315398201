import React, {useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
    Link,
    withRouter
} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { Refresh, UserOnboardRegister } from '../redux/action/index';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";



const OnboardRegister = (props) => {

    const dispatch =  useDispatch();
    const { register, handleSubmit, watch, errors } = useForm();
    const [IsSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (data,e) => {
      setIsSubmitting(true);
      await dispatch(UserOnboardRegister(data,props,e));
      setIsSubmitting(false);
    }

    useEffect(() => {
      dispatch(Refresh());
    },[]);
  


    return (
        <div class="body-wrapper">
        <div class="main-wrapper">
          <div class="page-wrapper full-page-wrapper d-flex align-items-center justify-content-center">
            <main class="auth-page">
              <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                  <div class="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"></div>
                  <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet">
                    <div class="mdc-card">
                    
                    <form onSubmit={handleSubmit(onSubmit)}>
                       
                       
                        <div class="form-group">
                            <input 
                                type="text" 
                                placeholder="Name"
                                class="form-control" 
                                id="exampleInputEmail1" 
                                aria-describedby="emailHelp" 
                                name ="name"
                                ref={register({
                                  required: "Required"
                                  })}
                            />
                            <small className="text-danger">{errors.name?.type == "required" && "Name is required"}</small>
                        </div>
                       
                       
                        <div class="form-group">
                            <input 
                                type="email" 
                                placeholder = "Email Address"
                                class="form-control" 
                                id="exampleInputEmail1" 
                                aria-describedby="emailHelp" 
                                name ="email"
                                ref={register({
                                  required: "Required"
                                  })}
                            />
                            <small className="text-danger">{errors.email?.type == "required" && "Email is required"}</small>
                        </div>
                       
                        <div class="form-group">
                            <input 
                                type="password" 
                                class="form-control"
                                 id="exampleInputPassword1" 
                                 placeholder="Password"
                                 name ="password"
                                 ref={register({
                                  required: "Required"
                                  })}
                            />
                            <small className="text-danger">{errors.password?.type == "required" && "Password is required"}</small>
                        </div>
                        <div class="form-group">
                            <input 
                              type="password" 
                              class="form-control" 
                              id="exampleInputPassword1" 
                              placeholder="Confirm Password"
                              name ="password_confirmation"
                              ref={register({ required: true, validate: (value) => {
                                return value === watch('password'); 
                              } })}
                          />
                          <span className="text-danger">{errors.password_confirmation?.type == 'validate' && "Password Mismatch"}</span>
                          <small className="text-danger">{errors.password_confirmation?.type == "required" && "Confirm Password is required"}</small>
                        </div>
                        <div class="form-group">
                            <input 
                                type="password" 
                                class="form-control"
                                 id="exampleInputPassword1" 
                                 placeholder="Onboard Code"
                                 name ="code"
                                 ref={register({
                                  required: "Required"
                                  })}
                            />
                            <small className="text-danger">{errors.password?.type == "required" && "Password is required"}</small>
                        </div>
                       
                          <div style={{marginTop:30}} class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                              {
                                IsSubmitting == false &&
                                <button type = "submit" class="mdc-button mdc-button--raised w-100">
                                  Create Account
                                </button>
                              }
                          </div>
                          {
                              IsSubmitting == true &&
                              <div style ={{textAlign:'center'}}>
                                <Loader
                                  visible={true}
                                  type="Puff"
                                  color="#7a00ff"
                                  height={30}
                                  width={30}
                                  timeout= {0} //3 secs
                              
                                />
                              </div>
                            }
           
                        <div style={{marginTop:30,textAlign:'center'}} class="mdc-layout-grid__cell">
                          <Link to="/onboard_login">Have Account ? Login</Link>
                        </div>
                    </form>
                    </div>
                  </div>
                  <div class="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"></div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
}

export default withRouter(OnboardRegister);