import React, {useState, useEffect} from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, useParams, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { CreateProfile, GetProfiles, DeleteProfile } from '../redux/action';
import { IMAGEPATH } from '../redux/action/constant';
import CreatePriest from './createpriest';
import CreateNewEducation  from './createpriesteducation';
import CreateNewParish from './createpriestparish';
import CreateNewPerferment from './createnewperferment';
import SearchData from './search';


const Card7 = (props) => {

    const [ show, setshow ] = useState(false);
    const [ edit, setedit ] = useState(false);
    const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
    const [IsSubmitting, setIsSubmitting ] = useState(false);
    const [ create, setcreate ] = useState(false);
    const priests = useSelector(state => state.root.priests);
    const { id } = useParams();
    const [ IsFetching, setIsFetching ] = useState(false);
    const [ editdata, seteditdata ] = useState(null);
    const user = useSelector(state => state.root.user_login);
    

    useEffect(() => {
        Process();
        
    },[]);

    const Process = async () => {
        setIsFetching(true);
        let dioceseId = window.atob(id);
        await dispatch(GetProfiles(dioceseId));
        setIsFetching(false);
    }

    const ViewModal = () => {
        setshow(true);
    }

    const EditModal = () => {
        setedit(true);
    }
    
    const eduSubmit = async (data,e) => {
        setIsSubmitting(true);
        alert(JSON.stringify(data));
        await dispatch(CreateProfile(data,e));
        setIsSubmitting(false);
    }
    const parishSubmit = async (data,e) => {
        setIsSubmitting(true);
        alert(JSON.stringify(data));
        await dispatch(CreateProfile(data,e));
        setIsSubmitting(false);
    }
    const perSubmit = async (data,e) => {
        setIsSubmitting(true);
        alert(JSON.stringify(data));
        await dispatch(CreateProfile(data,e));
        setIsSubmitting(false);
    }

    const Delete = async (id) => {
        if(window.confirm("Do you want to Delete this Priest Data?"))
        {
            await dispatch(DeleteProfile(id));
        }
    }
    return (
        <div className="">
        
          <Modal show = {create} size="lg" Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setcreate(false)}>
                <Modal.Title style={{color:'#fff'}}>Create Priest Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container" style={{marginTop:40}}>
                   <CreatePriest />

                 </div>
                </Modal.Body>
          </Modal> 
          <Modal show = {editdata != null ? true : false} size="lg" Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => seteditdata(null)}>
                <Modal.Title style={{color:'#fff'}}>Add Other Information for {editdata != null ? `${editdata.firstname} ${editdata.lastname}` : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container" style={{marginTop:40}}>
                 <Tabs defaultActiveKey="Education" id="uncontrolled-tab-example">
                  {/* <Tab eventKey="Information" title="Priest Information" style={{fontSize:16,fontSize:30,backgroundColor:'#fff'}}>
                    <CreatePriest />
                  </Tab> */}
                  <Tab eventKey="Education" title="Education">
                  <div>
                      
                    <CreateNewEducation id = { editdata != null ? editdata.id : ''} />
                  </div>
                  </Tab>
                  <Tab eventKey="Parishes" title="Parishes">
                    <CreateNewParish id = { editdata != null ? editdata.id : ''}  />
                  </Tab>
                  <Tab eventKey="Perferment" title="Perferment">
                      <CreateNewPerferment id = { editdata != null ? editdata.id : ''} />
                  
                  </Tab>
                 
                </Tabs>
                 </div>
                </Modal.Body>
          </Modal> 
            <div class="header_btn desktop">
                    <div>
                    {
                    (user.userType == 'superadmin' || (user.userType == 'admin' && user.dioceseId == window.atob(id)) ) &&    
                    <button onClick = { () => setcreate(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                    <i class="mdc-drawer-arrow material-icons">add</i> Create Priest
                    </button>
                    }
                    </div>
                    <SearchData page = 'Priest' id = {id} />
            </div>  
            <div class="mobile">
            <div class="header_btn mobile">
            <div>
            {
                (user.userType == 'superadmin' || (user.userType == 'admin' && user.dioceseId == window.atob(id)) ) && 
                <button onClick = { () => setcreate(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                <i class="mdc-drawer-arrow material-icons">add</i> Create Priest
                </button>
            }
            </div>
            <SearchData page = 'Priest' id = {id} />
            </div> 
            </div>
              
        {    
        IsFetching == false &&  priests != null && priests.map((priest) =>            
        <div class="card2 mt-3 tab-card">
            
            <div class="" id="">
            
            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
            
                <div style={{padding:30}}>
                
                        <div className="row">
                        <div className="col-xl-2 col-lg-1 col-md-3 col-sm-2">
                        {
                            (priest.image == '' || priest.image == null) &&
                            <img class="card-img-top" style={{height:80,width:80}} src={require(`../asset/images/profile.jpg`)}/>

                        }
                        {
                            priest.image != '' && priest.image != null &&
                            <img class="card-img-top" style={{height:80,width:80}} src={`${IMAGEPATH}files/image400/${priest.image}`} />

                        } 
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-3 col-sm-10" style={{marginLeft:-10}}>
                        <div class="">
                            <p style={{marginBottom:5,fontSize:16,fontWeight:500}} class="">{priest.firstname} {priest.lastname}</p>
                            <p style={{marginBottom:5,fontSize:13}} class="">{priest.status}</p>
                            <p style={{marginBottom:5,fontSize:13}} class="">{priest.position} </p>
                            </div>
                        </div>
                        </div>
                       
                   
                </div> 
                <div style = {{display:'flex',flexDirection:"row", justifyContent:'flex-end',margin:7,marginTop:-50}}>
                    <div style={{}} className="btnlist">
                    {
                        (user.userType == 'superadmin' || (user.userType == 'admin' && user.dioceseId == window.atob(id)) ) && 
                        <button onClick = { () => seteditdata(priest)} type ="submit" class="mdc-button w-10 actionshow" style={{border:'none',color:'#FF7A0',marginRight:10}}>
                        <i style={{marginRight:10}} class="mdc-drawer-arrow material-icons">add</i> Add
                        </button>
                    } 
                        
                    </div>
                    <div style={{}} className ="btnlist">
                    <Link to = {`/priest/${window.btoa(priest.id)}`} class="mdc-button w-10 actionshow" style={{textDecoration:'none', border:'2px solid #7a00ff',padding:5}}>
                    <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">visibility</i> View
                    </Link>
                    
                    </div>
                    {
                        (user.userType == 'superadmin' || (user.userType == 'admin' && user.dioceseId == window.atob(id)) ) && 
                        <button onClick = { () => Delete(priest.id)} type ="submit" class="mdc-button w-10 actionshow" style={{border:'none',color:'#FF7A0',marginRight:10}}>
                        <i style={{marginRight:10}} class="mdc-drawer-arrow material-icons">delete</i> 
                        </button>
                    } 
                  
                </div>
                
               
            </div>
            
            </div>
        </div>
        )}
         {
                    IsFetching == false && priests != null && priests.length == 0 &&
                    <div class="card mt-3">
                    
                        <div className = "" style={{textAlign:'center'}}>
                        
                        <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                            No Priest Added Yet
                        </div>

                        </div>
                </div>
            }  

       
        {
            IsFetching == true &&
                <div style={{textAlign:'center',marginTop:50}}>
                <Loader
                visible={true}
                type="Puff"
                color="#7a00ff"
                height={30}
                width={30}
                timeout= {0} //3 secs
            
                />
                </div>
        }
       
       
     </div>
    );
}

export default Card7;