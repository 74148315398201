import React, { useEffect, useState } from 'react';
import { SearchParam } from '../Message/getdata';
import { GetAdmins, GetArchdeaconries, GetDioceseOfficial, GetDioceses, GetOfficials, GetParishes, GetProfiles, GetProvinces } from '../redux/action';
import {useSelector, useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';




const SearchData = (props) => {
    const [ searchfor, setsearchfor ] = useState(null);
    const [ IsSearched, setIsSearched ] = useState(false);
    const [ id, setid] = useState(null);
    const [ IsFetching, setIsFetching ] = useState(false);
    const dispatch = useDispatch();
    const Search = (event) => {
        setsearchfor(event.target.value);
    }
    useEffect(() => {
       setid(props.id != '' ? window.atob(props.id) : '');
    },[props.id]);
    const Find = async () => {
        if(searchfor == null) return false;
        setIsFetching(true);
        await SearchParam(props.page,searchfor,window.atob(props.id));
        setIsFetching(false);
        setIsSearched(true);
    }
    const Return = async () => {
        setIsSearched(false);
        if(props.page == 'Province')
          await dispatch(GetProvinces());
        if(props.page == 'Diocese')
          await dispatch(GetDioceses(id));
        if(props.page == 'Province Official')
            await dispatch(GetOfficials(id));
        if(props.page == 'Diocean Official')
            await dispatch(GetDioceseOfficial(id));
        if(props.page == 'Archdeaconry')
            await dispatch(GetArchdeaconries(id));
        if(props.page == 'Parish')
            await dispatch(GetParishes(id));
        if(props.page == 'Priest')
            await dispatch(GetProfiles(id)); 
        if(props.page == 'User')
            await dispatch(GetAdmins());                     
        
    }
    return (
        <div> 
         <div className="" style={{display:'flex', flexDirection:'row'}}>
            {
                IsSearched == true && 
                <button onClick = { Return }  type ="submit" class="mdc-button mdc-button--raised w-100">
                    <i class="mdc-drawer-arrow material-icons">keyboard_arrow_left</i>  Return to List
                </button>
            }
        </div>
        {
            IsSearched == false &&
            <div>      
            <div class="desktop">
                <div style = {{display:'flex',flexDirection:'row'}}>
                <input onChange = { Search } className = "" style={{width:'90%',marginRight:3,padding:18}} className ="form-control" placeholder = {`Search for ${props.page}`}/>
                <button onClick = {  Find } style={{width:'10%'}}  type ="submit" class="mdc-button mdc-button--raised w-30">
                { IsFetching == false && <i class="mdc-drawer-arrow material-icons">search</i> }
                {
                          IsFetching == true &&
                          <div style={{textAlign:'center'}}>
                          <Loader
                            visible={true}
                            type="Puff"
                            color="#fff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        
                          />
                          </div>
                }
                </button>
                </div>
            </div>
            <div className="mobile">
            <div className='search'>
                    <div style = {{display:'flex',flexDirection:'row'}}>
                    <input onChange = { Search } className = "" style={{width:'90%',marginRight:3,padding:18}} className ="form-control" placeholder = {`Search for ${props.page}`}/>
                    <button onClick = {  Find }  style={{width:'10%'}}  type ="submit" class="mdc-button mdc-button--raised w-30">
                    { IsFetching == false && <i class="mdc-drawer-arrow material-icons">search</i> }
                {
                          IsFetching == true &&
                          <div style={{textAlign:'center'}}>
                          <Loader
                            visible={true}
                            type="Puff"
                            color="#fff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        
                          />
                          </div>
                }
                    </button>
                    </div>
            </div>
            </div>
            </div>
        }
    </div>
    );
}

export default SearchData;