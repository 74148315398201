import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Aside2 from './aside_boarding';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { GetOnboardingUserInformation } from '../redux/action';
import CreatePriest from './createpriest';
import { IMAGEPATH } from '../redux/action/constant';
import OnboardLink from './onboardlinks';


const OnboardPersonalInformation = (props) => {
	const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
  const [IsSubmitting, setIsSubmitting ] = useState(false);
  const [ show, setshow ] = useState(false);
  const [ showdiocese, setshowdiocese ] = useState(false);
  const [IsFetching, setIsFetching ] = useState(false);
  const thispriest = useSelector(state=>state.root.onboard_information);
   const onboard_education = useSelector(state=>state.root.onboard_education);
   const onboard_parish = useSelector(state=>state.root.onboard_parish);
   const onboard_perferment = useSelector(state=>state.root.onboard_perferment);
   const [ which, setwhich ] = useState('Information');
   const user_onboard_login = useSelector(state => state.root.user_onboard_login);

  const onSubmit = async (data,e) => {
    setIsSubmitting(true);
    //await dispatch(CreateDocumentType(data,e));
    setIsSubmitting(false);
  }

  const dioceseSubmit = async (data,e) => {
    setIsSubmitting(true);
    //await dispatch(CreateDocumentType(data,e));
    setIsSubmitting(false);
  }

  useEffect(() => {
      if(user_onboard_login == null)
      {
          window.location.href = "/onboard_login";
          return false;
      }
      Process();
  },[]);

  const Process = async () => {
      setIsFetching(true);
      await dispatch(GetOnboardingUserInformation());
      setIsFetching(false);
  }
	
    return (
        <div class="body-wrapper">
         
         <Modal show = {show} size="lg" Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshow(false)}>
                <Modal.Title style={{color:'#fff'}}>Create Priest Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container" style={{marginTop:40}}>
                   <CreatePriest />

                 </div>
                </Modal.Body>
          </Modal> 
        <Aside2 />
        <div class="main-wrapper mdc-drawer-app-content">
          <Header />
          <div className = "mobile">
             <OnboardLink />
          </div>
          <div class="page-wrapper mdc-toolbar-fixed-adjust">
            <main class="content-wrapper" style={{marginTop:-20}}>
              <div class="">
                <div class="">
                <div className ="header1">
                  <Link class="mdc-button" style ={{textDecorationLine:'none',fontSize:20}}>
                  Complete Your Profile 
                </Link>
                </div>  
                 <div className="">
                 <div class="header_btn">
                    <div>
                    <button  onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                    <i class="mdc-drawer-arrow material-icons">add</i> Update Personal Information
                    </button>
                    </div>
                    
                 </div>    
                 {    
                IsFetching == false &&  thispriest != null &&           
                <div>
               
                <div className ="desktop"> 
                       <div class="card2 mt-3 tab-card">
                          <div class="" id="">
                          <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                              <div style={{padding:30}}>
                              <div className="row">
                                  <div className="col-xl-3 col-lg-3 col-md-3">
                                      <div className="" style={{width:'100%'}}>
                                      <img class="card-img-top" style={{height:"100%",width:'100%'}} src={require(`../asset/images/profile.jpg`)} alt="Card image cap"/>
                                      <span style={{cursor:'pointer',textDecoration:'underline',fontSize:13 }}>Edit</span>

                                      </div>
                                  </div>
                                  
                                  <div className="col-xl-9 col-lg-9 col-md-3" style={{width:'55%'}}>
                                  <div class="row">
                                      <div className="col-xl-6 col-lg-6 col-md-3">
                                      <p className = "cardtitle">Name</p>
                                      <p className = "body">{thispriest.firstname} {thispriest.lastname} {thispriest.middlename}</p>
                                      </div>
                                      <div className="col-xl-6 col-lg-6 col-md-3">
                                      <p className = "cardtitle">Status</p>
                                      <p className="body">{thispriest.status}</p>
                                      </div>
                                  
                                  </div>
                                  <div class="row" style={{marginTop:20}}>
                                  
                                      <div className="col-xl-6 col-lg-6 col-md-3" style={{marginTop:10}}>
                                      <p className ="cardtitle">Position</p>
                                      <p className =" body">{thispriest.position}</p>
                                      </div>
                                      <div className="col-xl-6 col-lg-6 col-md-3" style={{marginTop:10}}>
                                      <p className="cardtitle">Email1</p>
                                      <p className="body">{thispriest.email1}</p>
                                      </div>
                                      
                                  </div>
                                  </div>
                                  
                              </div>
                          
                              </div>     
                          </div>
                          </div>
                       </div>
                       <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                    <div class="row">
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Email2</p>
                                        <p className = "body">{thispriest.email2}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Phone Number 1</p>
                                        <p className = "body">{thispriest.phonenumber1}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg- col-md-3">
                                        <p className = "cardtitle">Phone Number 2</p>
                                        <p className = "body">{thispriest.phonenumber2}</p>
                                        </div>
                                    
                                        
                                    </div>
                                   
                                </div>     
                            </div>
                            </div>
                        </div>
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                    <div class="row" style={{marginTop:20}}>
                                        
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Date Deaconed</p>
                                        <p className = "body">{thispriest.date_deaconed}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Date of Birth</p>
                                        <p className = "body">{thispriest.date_of_birth}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Date of Priested</p>
                                        <p className = "body">{thispriest.date_priested}</p>
                                        </div>
                                    
                                        
                                    </div>
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div class="row" style={{marginTop:20}}>
                                        
                                        
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Gender</p>
                                        <p className = "body">{thispriest.gender}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Marital Status</p>
                                        <p className = "body">{thispriest.marital_status}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Name of Spouse</p>
                                        <p className = "body">{thispriest.name_of_spouse}</p>
                                        </div>
                                    
                                        
                                    </div>
                            
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div class="row" style={{marginTop:20}}>
                                        
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Date Married</p>
                                        <p className = "body">{thispriest.date_married}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Spouse Qualification</p>
                                        <p className = "body">{thispriest.qualification_of_spouse}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3">
                                        <p className = "cardtitle">Name of Spouse</p>
                                        <p className = "body">{thispriest.name_of_spouse}</p>
                                        </div>
                                    
                                        
                                    </div>
                            
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div class="row" style={{marginTop:20}}>
                                        
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                        <p className = "cardtitle">Present Parish</p>
                                        <p className = "body">{thispriest.date_married}</p>
                                        </div>
                                       
                                    </div>
                            
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                </div>
                <div className = "mobile">
                <div class="card2 mt-3 tab-card">
                <div class="" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                <div className="col-xl-1 col-lg-2 col-md-2 pixside">
                                    <div className="" style={{width:'100%'}}>
                                     {
                                         thispriest.image == '' || thispriest.image == null &&
                                         <img class="card-img-top" style={{height:"100%",width:'100%'}} src={require(`../asset/images/profile.jpg`)} alt="Card image cap"/>

                                     }
                                     {
                                         thispriest.image != '' && thispriest.image != null &&
                                         <img class="card-img-top" style={{height:"100%",width:'100%'}} src={`${IMAGEPATH}files/image400/${thispriest.image}`} alt="Card image cap"/>

                                     }   

                                    </div>
                                </div>
                               
                                <div className="col-xl-9 col-lg-9 col-md-3" style={{width:'55%'}}>
                                <div>
                                  <p class="cardtitle">{thispriest.firstname} {thispriest.lastname} </p>
                                  <p class="bodymobile">{thispriest.status} </p>
                                  <p class="bodymobile">{thispriest.position} </p>
                                </div>    
                               
                                </div>
                                <hr  />
                                 
                               
                            </div>
                            </div>     
                        </div>
                  </div>
                </div>
                          
                <div class="card2 mt-3 tab-card">
                    <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                    <div class="row">
                                        <div className="col-xl-12 col-lg-4 col-md-4" style={{marginBottom:20}}>
                                            <p className ="cardtitle">Email 1</p>
                                            <p className ="bodymobile">{thispriest.email1 == null ? 'Unknown' : thispriest.email1} </p>
                                        </div>
                                        <div className="col-xl-12 col-lg-4 col-md-4" style={{marginBottom:20}}>
                                            <p className ="cardtitle">Email 2</p>
                                            <p className ="bodymobile">{thispriest.email2 == null ? 'Unknown' : thispriest.email2} </p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-4" style={{marginBottom:20}}>
                                            <p className ="cardtitle">Phone Number 1</p>
                                            <p className ="bodymobile">{thispriest.phonenumber1 == null ? 'Unknown' : thispriest.phonenumber1}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-4" style={{marginBottom:20}}>
                                            <p class="cardtitle">Phone Number 2</p>
                                            <p className="bodymobile">{thispriest.phonenumber2 == null ? 'Unknown' : thispriest.phonenumber2}</p>
                                        </div>
                                    </div>
                                    
                        
                                </div>     
                            </div>
                    </div>
                </div>
                <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                    <div class="row">
                                        
                                        <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                        <p className = "cardtitle">Date Deaconed</p>
                                        <p className = "bodymobile">{thispriest.date_deaconed}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                        <p className = "cardtitle">Date of Birth</p>
                                        <p className = "bodymobile">{thispriest.date_of_birth}</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                        <p className = "cardtitle">Date of Priested</p>
                                        <p className = "bodymobile">{thispriest.date_priested}</p>
                                        </div>
                                    
                                        
                                    </div>
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>
                        
                <div class="card2 mt-3 tab-card">
                    <div class="" id="">
                    <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                        <div style={{padding:30}}>
                        <div class="row">
                                
                                
                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                <p className = "cardtitle">Gender</p>
                                <p className = "bodymobile">{thispriest.gender}</p>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                <p className = "cardtitle">Marital Status</p>
                                <p className = "bodymobile">{thispriest.marital_status}</p>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                <p className = "cardtitle">Name of Spouse</p>
                                <p className = "bodymobile">{thispriest.name_of_spouse}</p>
                                </div>
                            
                                
                            </div>
                    
                            
                    
                        </div>     
                    </div>
                    </div>
                </div>
                <div class="card2 mt-3 tab-card">
                    <div class="" id="">
                    <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                        <div style={{padding:30}}>
                        <div class="row" style={{marginTop:20}}>
                                
                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                <p className = "cardtitle">Date Married</p>
                                <p className = "bodymobile">{thispriest.date_married}</p>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                <p className = "cardtitle">Spouse Qualification</p>
                                <p className = "bodymobile">{thispriest.qualification_of_spouse}</p>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-3" style={{marginBottom:20}}>
                                <p className = "cardtitle">Name of Spouse</p>
                                <p className = "bodymobile">{thispriest.name_of_spouse}</p>
                                </div>
                            
                                
                            </div>
                    
                            
                    
                        </div>     
                    </div>
                    </div>
                </div>
                <div class="card2 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div class="row" style={{marginTop:20}}>
                                        
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                        <p className = "cardtitle">Present Parish</p>
                                        <p className = "bodymobile">{thispriest.date_married}</p>
                                        </div>
                                       
                                    </div>
                            
                                  
                            
                                </div>     
                            </div>
                            </div>
                        </div>      
              </div>
                </div>
                }  
                 {
                    thispriest == null &&
                    <div class="card mt-3">
                    
                        <div className = "" style={{textAlign:'center'}}>
                        
                        <div class="" style={{width:'100%',marginLeft:'auto',marginRight:'auto'}}>
                            Complete your Profile
                        </div>

                        </div>
                    </div>
                 }
                 {
                    IsFetching == true &&
                    <div class="card mt-3">
                    
                        <div className = "" style={{textAlign:'center'}}>
                        
                        <div style={{textAlign:'center'}}>
                          <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        
                          />
                          </div>

                        </div>
                    </div>
                 } 
                 </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    );
}


export default withRouter(OnboardPersonalInformation);