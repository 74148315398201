const USER_LOGIN = 'USER_LOGIN';
const USER_REGISTER = 'USER_REGISTER';
//const PATH = 'http://localhost:8081/anglican/public/api/';
//const IMAGEPATH = 'http://localhost/anglican/public/';
//const ONBOARDLINK = 'http://localhost/anglican';

const PATH = 'https://www.dioceseofife.com/anglican_backend/public/api/';
const IMAGEPATH = 'https://www.dioceseofife.com/anglican_backend/public/';
const ONBOARDLINK = 'https://www.dioceseofife.com';

const PROVINCES = 'PROVINCES';
const NEWPROVINCE = 'NEWPROVINCE';
const DIOCESES = 'DIOCESES'; 
const NEWDIOCESE = 'NEWDIOCESE';
const ARCHDEACONRIES = 'ARCHDEACONRIES';
const NEWARCHDEACONRY = 'NEWARCHDEACONRY';
const PARISHES = 'PARISHES';
const NEWPARISH = 'NEWPARISHES';
const PROVINCEOFFICIALS = 'PROVINCEOFFICIALS';
const NEWPROVINCEOFFICIAL = 'NEWPROVINCEOFFICIAL';
const DIOCEANOFFICIALS = 'PROVINCEOFFICIALS';
const NEWDIOCEANOFFICIAL = 'NEWDIOCEANOFFICIAL';
const PRIESTS = 'PRIESTS';
const MYPROFILE = 'MYPROFILE';
const MYEDUCATION = 'MYEDUCATION';
const MYPERFERMENT = 'MYPERFERMENT';
const MYPARISHES = 'MYPARISHES';
const OFFICIALS = 'OFFICIALS';
const NEWOFFICIAL = 'NEWOFFICIAL';
const PROFILES = 'PROFILES';
const REFRESH = 'REFRESH';
const USER_ONBOARD_REGISTER = 'USER_ONBOARD_REGISTER';
const USER_ONBOARD_LOGIN = 'USER_ONBOARD_LOGIN';
const NEWPRIEST = 'NEWPRIEST';
const THISPRIEST = 'THISPRIEST';
const UPDATETHISPRIEST = 'UPDATETHISPRIEST';
const YEAR = 'YEAR';
const PARISHINARCHDEACONARY = 'PARISHINARCHDEACONARY';
const PRIESTSINPARISH = 'PRIESTSINPARISH';
const ADMINS = 'ADMINS';
const NEWADMIN = 'NEWADMIN';
const NEWPORTAL = 'NEWPORTAL';
const PORTALS = 'PORTALS';
const ONBOARDINGUSER = 'ONBOARDINGUSER';
const DASHBOARD = 'DASHBOARD';
const MONTH = ['January','February','March','April','May','June','July','August','September','October','November','December'];

export
{
    USER_LOGIN,
    USER_REGISTER,
    PROVINCES,
    PROVINCEOFFICIALS,
    DIOCESES,
    DIOCEANOFFICIALS,
    ARCHDEACONRIES,
    PARISHINARCHDEACONARY,
    PARISHES,
    MYPARISHES,
    MYPROFILE,
    MYPERFERMENT,
    MYEDUCATION,
    NEWARCHDEACONRY,
    NEWDIOCESE,
    NEWPARISH,
    NEWPROVINCE,
    NEWDIOCEANOFFICIAL,
    NEWPROVINCEOFFICIAL,
    PRIESTS,
    NEWPRIEST,
    PATH,
    IMAGEPATH,
    OFFICIALS,
    NEWOFFICIAL,
    PROFILES,
    REFRESH,
    USER_ONBOARD_REGISTER,
    USER_ONBOARD_LOGIN,
    MONTH,
    UPDATETHISPRIEST,
    THISPRIEST,
    YEAR,
    PRIESTSINPARISH,
    ADMINS,
    NEWADMIN,
    PORTALS,
    NEWPORTAL,
    ONBOARDINGUSER,
    DASHBOARD,
    ONBOARDLINK
};    