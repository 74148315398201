import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter, useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { LOADERCOLOR } from '../redux/action/constant';
import { CreateDiocese, CreateOfficial } from '../redux/action/index';
import Aside from './aside';
import { Tabs,Tab, Modal } from 'react-bootstrap';
import PHeader from './province_header';
import Card1 from './card1';
import Card8 from './card8';
import Card3 from './card3';
import SearchData from './search';


const ProvinceDetails = (props) => {
	const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
  const [IsSubmitting, setIsSubmitting ] = useState(false);
  const [ show, setshow ] = useState(false);
  const [ showdiocese, setshowdiocese ] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [ file, setfile ] = useState(null);
  const user = useSelector(state => state.root.user_login);
  const [ which, setwhich ] = useState('official');
  const provinces = useSelector(state => state.root.provinces);

  const dioceseSubmit = async (data,e) => {
    setIsSubmitting(true);
    const formdata = new FormData();
    formdata.append('name',data.name);
    formdata.append('firstname', data.firstname);
    formdata.append('address', data.address);
    formdata.append('lastname',data.lastname);
    formdata.append('status', data.status);
    formdata.append('position',data.position);
    formdata.append('email1',data.email1);
    formdata.append('phonenumber1', data.phonenumber1);
    formdata.append('file', file);
    let provinceid = window.atob(id);
    await dispatch(CreateDiocese(formdata,provinceid, e));
    setIsSubmitting(false);
  }

  const officialSubmit = async (data,e) => {
    setIsSubmitting(true);
    let provinceid = window.atob(id);
    const formdata = new FormData();
    formdata.append('firstname', data.firstname);
    formdata.append('lastname', data.lastname);
    formdata.append('position', data.position);
    formdata.append('file', file);
    formdata.append('email',data.email);
    formdata.append('phonenumber', data.phonenumber);
    await dispatch(CreateOfficial(formdata,provinceid, e));
    setIsSubmitting(false);
  }

  const handleChange = (event) => {
    setfile(event.target.files[0]);
  }

  const ProvinceName = () => {
      let index = provinces.findIndex(x=>x.id == window.atob(id) );
      return provinces[index].province_name;
  }
	
    return (
        <div class="body-wrapper">
        <Modal show = {show} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshow(false)}>
                <Modal.Title style={{color:'#fff'}}>Create Official</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(officialSubmit)}>
                   
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="First Name" 
                            style={{marginBottom:20}}
                            name ="firstname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Last Name" 
                            style={{marginBottom:20}}
                            name ="lastname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.lastname?.type == "required" && "Last Name is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Position" 
                            style={{marginBottom:20}}
                            name ="position"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Email" 
                            style={{marginBottom:20}}
                            name ="email"
                            type ="text" 
                            ref={register({
                                
                                })}
                        />
                            <small className="text-danger">{errors.email?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Phone Number" 
                            style={{marginBottom:20}}
                            name ="phonenumber"
                            type ="number" 
                            ref={register({
                                
                                })}
                        />
                            <small className="text-danger">{errors.phonenumber?.type == "required" && "Phone Number is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:0}}
                            onChange={handleChange}
                            name ="file"
                            type ="file" 
                            ref={register({
                               
                                })}
                        />
                            <small className="text-primary">Profile Image</small>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>  
        <Modal show = {showdiocese} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshowdiocese(false)}>
                <Modal.Title style={{color:'#fff'}}>Create Diocese</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(dioceseSubmit)}>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Diocese Name" 
                            name ="name"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.province_name?.type == "required" && "Province Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="First Name" 
                            style={{marginBottom:20}}
                            name ="firstname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Last Name" 
                            style={{marginBottom:20}}
                            name ="lastname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.lastname?.type == "required" && "Last Name is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                             className="form-control"  
                             style={{marginBottom:20}}
                             name ="position"
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Position</option>
                         <option value="Primate">Primate</option>
                         <option value="Archbishop">Archbishop</option>
                         <option value="Bishop">Bishop</option>
                         <option value="Provost">Provost</option>
                         <option value="Dean">Dean</option>
                         <option value="Vicar">Vicar</option>
                         <option value="Curate">Curate</option>
                         <option value="Deacon">Deacon</option>
                         <option value="Evangelist">Evangelist</option>
                         <option value="Catechist">Catechist</option>
                         <option value="Agent">Agent</option>
                        </select>     
                       
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                             className="form-control" 
                             placeholder ="Status" 
                             style={{marginBottom:20}}
                             name ="status"
                             type ="text" 
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Status</option>
                         <option value="Most Revd">Most Revd</option>
                         <option value="Right Revd">Right Revd</option>
                         <option value="Very Revd">Very Revd</option>
                         <option value="Venerable">Venerable</option>
                         <option value="Revd Canon">Revd Canon</option>
                         <option value="Revd">Revd</option>
                         <option value="Evangelist">Evangelist</option>
                         <option value="Mr.">Mr.</option>
                        </select>    
                        {/* <input 
                           
                        /> */}
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Phone Number 1"
                            style={{marginBottom:20}} 
                            name ="phonenumber1"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.phonenumber1?.type == "required" && "Phone Number is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Email 1" 
                            style={{marginBottom:20}}
                            name ="email1"
                            type ="email" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            onChange={handleChange}
                            name ="file"
                            type ="file" 
                            ref={register({
                               
                                })}
                        />
                            <small className="text-primary">Image file</small><br/>
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <textarea
                          className="form-control" 
                          placeholder ="Diocese Address" 
                          style={{marginBottom:20}}
                          name ="address"
                          rows="5"
                          ref={register({
                              required: "Required"
                              })}
                        >
                        
                        </textarea>    
                      
                            <small className="text-danger">{errors.address?.type == "required" && "Address is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>  
        
        <Aside />
        <div class="main-wrapper mdc-drawer-app-content">
          <Header />
          <div className="mobile">
          <div className = "bottomwrapper">
                <div style = {{display:'flex', flexDirection:'row',justifyContent:'space-around'}}>
                    <div onClick = { () => { setwhich('official'); window.scrollTo(0,0) } } className = "bottomicon">
                        <div  ><i style= {{color: which == 'official' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">person</i></div>
                        <div style = {{color: which == 'official' ? '#7a00ff' : ''}} className = "bottomtext">Province Officials</div>
                    </div>
                    <div onClick = { () => { setwhich('diocese'); window.scrollTo(0,0) } } className = "bottomicon">
                        <div ><i style = {{color: which == 'diocese' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">home</i></div>
                        <div style = {{color: which == 'diocese' ? '#7a00ff' : ''}} className = "bottomtext">Dioceses</div>
                    </div>
                </div>            
          </div>
          </div>
          <div class="page-wrapper mdc-toolbar-fixed-adjust">
            <main class="content-wrapper"  style={{marginTop:-20}}>
              <div class="">
                <div class="">
                <div className="header1">
                  <Link onClick = { () => history.goBack() } class="mdc-button" style ={{textDecorationLine:'none',fontSize:20}}>
                  <i style={{fontSize:40}} class="mdc-drawer-arrow material-icons">chevron_left</i>
                   {ProvinceName()} Province
                </Link>
              </div>
                 <div className = "desktop">
                 <div className="container" style={{marginTop:20}}>
                 <Tabs defaultActiveKey="offical" id="uncontrolled-tab-example">
                  <Tab class="activenav" eventKey="offical" title="Province Officials" style={{fontSize:16}}>
                    
                        <div class="header_btn desktop">
                            <div>
                            {
                                 user.userType == 'superadmin' &&    
                                <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                                <i class="mdc-drawer-arrow material-icons">add</i> Create Province Official
                                </button>
                            }
                            </div>
                            <SearchData page = 'Province Official' id = {id} />
                        </div>
                        <div class="mobile">
                        <div class="header_btn mobile">
                            <SearchData page = 'Province Official' id = {id} />
                        </div>
                        </div>
                       
                    <Card8 id ={id} />
                  </Tab>
                  <Tab eventKey="profile" title="Diocese In Province">
                      <div class="header_btn desktop">
                            <div>
                            {
                                user.userType == 'superadmin' &&     
                                <button onClick = { () => setshowdiocese(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                                <i class="mdc-drawer-arrow material-icons">add</i> Create Diocese
                                </button>
                            }
                            </div>
                            <SearchData page = 'Diocese' id = {id} />
                        </div>
                        <div class="mobile">
                        <div class="header_btn">
                            <SearchData page = 'Diocese' id = {id} />
                        </div>
                        </div>
                       
                    <Card3 id = {id} />
                  </Tab>
                 
                  </Tabs>
                 </div>
                 </div>
                 <div className = "mobile">
                 <div className="" style={{marginTop:20}}>
                 <div>
                 
                    {
                        which == 'official' &&
                        <div>
                                <div class="header_btn desktop">
                                    <div>
                                    {
                                        user.userType == 'superadmin' &&    
                                        <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                                        <i class="mdc-drawer-arrow material-icons">add</i> Create Province Official
                                        </button>
                                    }
                                    </div>
                                    <SearchData page = 'Province Official' id = {id} />
                                </div>
                                <div class="header_btn mobile">
                                    <div>
                                    {
                                        user.userType == 'superadmin' &&    
                                        <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                                        <i class="mdc-drawer-arrow material-icons">add</i> Create Province Official
                                        </button>
                                    }
                                    </div>
                                    <SearchData page = 'Province Official' id = {id} />
                                </div>
                            
                                <Card8 id ={id} />
                        </div>
                    }
                    {
                    which == 'diocese' &&    
                    <div>
                       <div class="header_btn desktop">
                            <div>
                            {
                                user.userType == 'superadmin' &&     
                                <button onClick = { () => setshowdiocese(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                                <i class="mdc-drawer-arrow material-icons">add</i> Create Diocese
                                </button>
                            }
                            </div>
                            <SearchData page = 'Diocese' id = {id} />
                        </div>
                        <div class="header_btn mobile">
                            <div>
                            {
                                user.userType == 'superadmin' &&     
                                <button onClick = { () => setshowdiocese(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                                <i class="mdc-drawer-arrow material-icons">add</i> Create Diocese
                                </button>
                            }
                            </div>
                            <SearchData page = 'Diocese' id = {id} />
                        </div>
                        
                        <Card3 id = {id} />
                    </div>
                    }
                  </div>
                 </div>
                
                </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    );
}


export default withRouter(ProvinceDetails);