import React from 'react';
import { useLocation, withRouter } from 'react-router-dom';



const OnboardLink = (props) => {
    const location = useLocation();
    return (
        <div className = "bottomwrapper">
                <div style = {{display:'flex', flexDirection:'row',justifyContent:'space-around'}}>
                    <div onClick = { () => { props.history.push('/onboard'); window.scrollTo(0,0) } } className = "bottomicon">
                        <div style={{textAlign:'center'}}><i style = {{color: location.pathname == '/onboard' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">person</i></div>
                        <div style = {{color: location.pathname == '/onboard' ? '#7a00ff' : ''}} className = "bottomtext">Information</div>
                    </div>
                    <div onClick = { () => { props.history.push('/onboard_priest_education'); window.scrollTo(0,0) } } className = "bottomicon">
                        <div ><i style = {{color: location.pathname == '/onboard_priest_education' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">book</i></div>
                        <div style = {{color: location.pathname == '/onboard_priest_education' ? '#7a00ff' : ''}} className = "bottomtext">Education</div>
                    </div>
                    <div onClick = { () => { props.history.push('/onboard_priest_parish'); window.scrollTo(0,0) } } className = "bottomicon">
                        <div ><i style = {{color: location.pathname == '/onboard_priest_parish' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">home</i></div>
                        <div style = {{color: location.pathname == '/onboard_priest_parish' ? '#7a00ff' : ''}} className = "bottomtext">Parish Till Date</div>
                    </div>
                    <div onClick = { () => { props.history.push('/onboard_priest_perferment'); window.scrollTo(0,0) } } className = "bottomicon">
                        <div ><i style = {{color: location.pathname == '/onboard_priest_perferment' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">home</i></div>
                        <div style = {{color: location.pathname == '/onboard_priest_perferment' ? '#7a00ff' : ''}} className = "bottomtext">Perferment</div>
                    </div>
                
                </div>            
        </div>
    );

}

export default withRouter(OnboardLink);