import { store } from '../redux/store/index';
import { PATH } from '../redux/action/constant';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { SearchProvince, SearchArchdeaconry, SearchDOfficial, SearchDiocese, SearchPOfficial, SearchParish,SearchPriest, SearchUser } from '../redux/action';


async function GetProvince(value){
    const AUTH = axios.create({
        baseURL: `${PATH}`,
        timeout: 20000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                   'Authorization': `Bearer ${store.getState().root.token}`
                }
    });
    return AUTH.get(`searchdata/${value}/province`)
    .then(async (response) => {
        if(response.data.status === 'success'){
           return response.data.provinces;
        }else {
            return [];
        }
    }).catch(err => {
        return [];
    }); 
}
async function GetDiocese(value){
    const AUTH = axios.create({
        baseURL: `${PATH}`,
        timeout: 20000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                   'Authorization': `Bearer ${store.getState().root.token}`
                }
    });
    return AUTH.get(`searchdata/${value}/diocese`)
    .then(async (response) => {
        if(response.data.status === 'success'){
            //alert(JSON.stringify(response.data));
           return response.data.dioceses;
        }else {
            return [];
        }
    }).catch(err => {
        return [];
    }); 
}

async function GetArchdeaconry(value){
    const AUTH = axios.create({
        baseURL: `${PATH}`,
        timeout: 20000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                   'Authorization': `Bearer ${store.getState().root.token}`
                }
    });
    return AUTH.get(`searchdata/${value}/archdeaconary`)
    .then(async (response) => {
        if(response.data.status === 'success'){
           return response.data.archdeaconaries;
        }else {
            return [];
        }
    }).catch(err => {
        return [];
    }); 
}

async function GetParish(value){
    const AUTH = axios.create({
        baseURL: `${PATH}`,
        timeout: 20000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                   'Authorization': `Bearer ${
                       store.getState().root.token == null ? 
                         store.getState().root.onboard_token : store.getState().root.token }`
                }
    });
    return AUTH.get(`searchparish/${value}`)
    .then(async (response) => {
        if(response.data.status === 'success'){
           return response.data.parishes;
        }else {
            return [];
        }
    }).catch(err => {
        //alert('ddd');
        return [];
    }); 
}

async function SearchParam(page,value,which){
    //alert(which)
    const AUTH = axios.create({
        baseURL: `${PATH}`,
        timeout: 20000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                   'Authorization': `Bearer ${store.getState().root.token}`
                }
    });
    return AUTH.get(`searchfor/${page}/${value}/${which}`)
    .then(async (response) => {
        if(response.data.status === 'success'){
            //alert(JSON.stringify(response.data));
            if(page == 'Province')
                await store.dispatch(SearchProvince(response.data));
            if(page == 'Diocese')
                await store.dispatch(SearchDiocese(response.data)); 
            if(page == 'Province Official')
                await store.dispatch(SearchPOfficial(response.data));
            if(page == 'Diocean Official')
                await store.dispatch(SearchDOfficial(response.data)); 
            if(page == 'Archdeaconry')
                await store.dispatch(SearchArchdeaconry(response.data));  
            if(page == 'Parish')
                await store.dispatch(SearchParish(response.data));  
            if(page == 'Priest')
                await store.dispatch(SearchPriest(response.data));     
            if(page == 'User')
                await store.dispatch(SearchUser(response.data));                      
            
        }else {
            return [];
        }
    }).catch(err => {
        //alert(JSON.stringify(err.response))
        return [];
    }); 
}

export {
    GetParish,
    GetDiocese,
    GetProvince,
    GetArchdeaconry,
    SearchParam
};















 