import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Aside from './aside';
import { Modal } from 'react-bootstrap';
import { CreateProvince, CreateDiocese, EditProvince, GetProvinces } from '../redux/action';
import { IMAGEPATH } from '../redux/action/constant';
import SearchData from './search';


const Provinces = (props) => {
	const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
  const [IsSubmitting, setIsSubmitting ] = useState(false);
  const [ show, setshow ] = useState(false);
  const [ showdiocese, setshowdiocese ] = useState(false);
  const [ province, setprovince ] = useState(null);
  const [ file, setfile ] = useState(null);
  const provinces = useSelector(state => state.root.provinces);
  const [ edit, setedit ] = useState(false);
  const [ IsFetching, setIsFetching ] = useState(false);
  const user = useSelector(state => state.root.user_login);



  useEffect(() => {
      process();
  },[])

  const process = async () => {
      setIsFetching(true);
      await dispatch(GetProvinces());
      setIsFetching(false);
  }

  

  const onSubmit = async (data,e) => {
    setIsSubmitting(true);
    const formdata = new FormData();
    formdata.append('province_name',data.province_name);
    formdata.append('firstname', data.firstname);
    formdata.append('lastname',data.lastname);
    formdata.append('status', data.status);
    formdata.append('position',data.position);
    formdata.append('email1',data.email1);
    formdata.append('phonenumber1', data.phonenumber1);
    formdata.append('file', file);
    await dispatch(CreateProvince(formdata,e));
    setIsSubmitting(false);
  }

  const dioceseSubmit = async (data,e) => {
    setIsSubmitting(true);
    const formdata = new FormData();
    formdata.append('province_name',data.province_name);
    formdata.append('firstname', data.firstname);
    formdata.append('address', data.address);
    formdata.append('lastname',data.lastname);
    formdata.append('status', data.status);
    formdata.append('position',data.position);
    formdata.append('email1',data.email1);
    formdata.append('phonenumber1', data.phonenumber1);
    formdata.append('file', file);
    await dispatch(CreateDiocese(data,province.id, e));
    setIsSubmitting(false);
  }

  const updateSubmit = async (data,e) => {
    setIsSubmitting(true);
    const formdata = new FormData();
    formdata.append('province_name',data.province_name);
    formdata.append('firstname', data.firstname);
    formdata.append('address', data.address);
    formdata.append('lastname',data.lastname);
    formdata.append('status', data.status);
    formdata.append('position',data.position);
    formdata.append('email1',data.email1);
    formdata.append('phonenumber1', data.phonenumber1);
    if(file != null) formdata.append('file', file);
    await dispatch(EditProvince(formdata,province.id));
    setIsSubmitting(false);
  }

  const handleChange = (event) => {
      setfile(event.target.files[0]);
  }
	
    return (
        <div class="body-wrapper">
          <Modal show = {show} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshow(false)}>
                <Modal.Title style={{color:'#fff'}}>Create Province</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Province Name" 
                            name ="province_name"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.province_name?.type == "required" && "Province Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="First Name" 
                            style={{marginBottom:20}}
                            name ="firstname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Last Name" 
                            style={{marginBottom:20}}
                            name ="lastname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.lastname?.type == "required" && "Last Name is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                             className="form-control" 
                             placeholder ="Status" 
                             style={{marginBottom:20}}
                             name ="position"
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Position</option>
                         <option value="Primate">Primate</option>
                         <option value="Archbishop">Archbishop</option>
                         <option value="Bishop">Bishop</option>
                         <option value="Provost">Provost</option>
                         <option value="Dean">Dean</option>
                         <option value="Vicar">Vicar</option>
                         <option value="Curate">Curate</option>
                         <option value="Deacon">Deacon</option>
                         <option value="Evangelist">Evangelist</option>
                         <option value="Catechist">Catechist</option>
                         <option value="Agent">Agent</option>
                        </select>     
                       
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                             className="form-control" 
                             placeholder ="Status" 
                             style={{marginBottom:20}}
                             name ="status"
                             type ="text" 
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Status</option>
                         <option value="Most Revd">Most Revd</option>
                         <option value="Right Revd">Right Revd</option>
                         <option value="Very Revd">Very Revd</option>
                         <option value="Venerable">Venerable</option>
                         <option value="Revd Canon">Revd Canon</option>
                         <option value="Revd">Revd</option>
                         <option value="Evangelist">Evangelist</option>
                         <option value="Mr.">Mr.</option>
                        </select>    
                        {/* <input 
                           
                        /> */}
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Phone Number 1"
                            style={{marginBottom:20}} 
                            name ="phonenumber1"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.phonenumber1?.type == "required" && "Phone Number is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Email 1" 
                            style={{marginBottom:20}}
                            name ="email1"
                            type ="email" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            onChange={handleChange}
                            name ="file"
                            type ="file" 
                            ref={register({
                               
                                })}
                        />
                            <small className="text-primary">Image file is required</small><br/>
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>
                   
                  
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>
          <Modal show = {edit} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setedit(false)}>
                <Modal.Title style={{color:'#fff'}}>Edit Province</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(updateSubmit)}>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            defaultValue = {province != null && province.province_name}
                            placeholder ="Province Name" 
                            name ="province_name"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.province_name?.type == "required" && "Province Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="First Name" 
                            defaultValue = {province != null &&  province.leader.firstname}
                            style={{marginBottom:20}}
                            name ="firstname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Last Name" 
                            defaultValue = {province != null &&  province.leader.lastname}
                            style={{marginBottom:20}}
                            name ="lastname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.lastname?.type == "required" && "Last Name is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                             className="form-control" 
                             placeholder ="" 
                             defaultValue = {province != null &&  province.leader.position}
                             style={{marginBottom:20}}
                             name ="position"
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Position</option>
                         <option value="Primate">Primate</option>
                         <option value="Archbishop">Archbishop</option>
                         <option value="Bishop">Bishop</option>
                         <option value="Provost">Provost</option>
                         <option value="Dean">Dean</option>
                         <option value="Vicar">Vicar</option>
                         <option value="Curate">Curate</option>
                         <option value="Deacon">Deacon</option>
                         <option value="Evangelist">Evangelist</option>
                         <option value="Catechist">Catechist</option>
                         <option value="Agent">Agent</option>
                        </select>     
                       
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                             className="form-control" 
                             placeholder ="Status" 
                             defaultValue = {province != null &&  province.leader.status}
                             style={{marginBottom:20}}
                             name ="status"
                             type ="text" 
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Status</option>
                         <option value="Most Revd">Most Revd</option>
                         <option value="Right Revd">Right Revd</option>
                         <option value="Very Revd">Very Revd</option>
                         <option value="Venerable">Venerable</option>
                         <option value="Revd Canon">Revd Canon</option>
                         <option value="Revd">Revd</option>
                        </select>    
                        {/* <input 
                           
                        /> */}
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Phone Number 1"
                            defaultValue = {province != null && province.leader.phonenumber1}
                            style={{marginBottom:20}} 
                            name ="phonenumber1"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.phonenumber1?.type == "required" && "Phone Number is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Email 1" 
                            defaultValue = {province != null && province.leader.email1}
                            style={{marginBottom:20}}
                            name ="email1"
                            type ="email" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            onChange={handleChange}
                            name ="file"
                            type ="file" 
                            ref={register({
                               
                                })}
                        />
                            <small className="text-primary">Image file is required</small><br/>
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>
          <Modal show = {showdiocese} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshowdiocese(false)}>
                <Modal.Title style={{color:'#fff'}}>Create Diocese</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(dioceseSubmit)}>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Diocese Name" 
                            name ="name"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.province_name?.type == "required" && "Province Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="First Name" 
                            style={{marginBottom:20}}
                            name ="firstname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Last Name" 
                            style={{marginBottom:20}}
                            name ="lastname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.lastname?.type == "required" && "Last Name is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                             className="form-control"  
                             style={{marginBottom:20}}
                             name ="position"
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Position</option>
                         <option value="Primate">Primate</option>
                         <option value="Archbishop">Archbishop</option>
                         <option value="Bishop">Bishop</option>
                         <option value="Provost">Provost</option>
                         <option value="Dean">Dean</option>
                         <option value="Vicar">Vicar</option>
                         <option value="Curate">Curate</option>
                         <option value="Deacon">Deacon</option>
                         <option value="Evangelist">Evangelist</option>
                        </select>     
                       
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                             className="form-control" 
                             placeholder ="Status" 
                             style={{marginBottom:20}}
                             name ="status"
                             type ="text" 
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Status</option>
                         <option value="Most Revd">Most Revd</option>
                         <option value="Right Revd">Right Revd</option>
                         <option value="Very Revd">Very Revd</option>
                         <option value="Venerable">Venerable</option>
                         <option value="Revd Canon">Revd Canon</option>
                         <option value="Revd">Revd</option>
                        </select>    
                        {/* <input 
                           
                        /> */}
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Phone Number 1"
                            style={{marginBottom:20}} 
                            name ="phonenumber1"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.phonenumber1?.type == "required" && "Phone Number is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Email 1" 
                            style={{marginBottom:20}}
                            name ="email1"
                            type ="email" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            onChange={handleChange}
                            name ="file"
                            type ="file" 
                            ref={register({
                               
                                })}
                        />
                            <small className="text-primary">Image file</small><br/>
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <textarea
                          className="form-control" 
                          placeholder ="Diocese Address" 
                          style={{marginBottom:20}}
                          name ="address"
                          rows="5"
                          ref={register({
                              required: "Required"
                              })}
                        >
                        
                        </textarea>    
                      
                            <small className="text-danger">{errors.address?.type == "required" && "Address is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>  
    
        <Aside />

        <div class="main-wrapper mdc-drawer-app-content">
          <Header />
         
          <div class="page-wrapper mdc-toolbar-fixed-adjust">
            <main class="content-wrapper" style={{marginTop:-20}}>
              <div class="">
                <div class="">
                {/* <div className ="header1">
                  <Link class="mdc-button" style ={{textDecorationLine:'none',fontSize:20}}>
                  Church of Nigeria 
                </Link>
                </div>   */}
                <div>
                    <div class="header_btn desktop">
                        <div>
                        {
                            user.userType == 'superadmin' &&    
                            <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                            <i class="mdc-drawer-arrow material-icons">add</i> Create Province
                            </button>
                        }
                        </div>
                        <SearchData page = 'Province' id ='' />
                    </div>
                    <div class="mobile" style={{marginTop:40}}>
                    <div class="header_btn mobile">
                    <div>
                        {
                            user.userType == 'superadmin' &&    
                            <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                            <i class="mdc-drawer-arrow material-icons">add</i> Create Province
                            </button>
                        }
                        </div>
                        <SearchData page = 'Province' id = '' />
                    </div>
                    </div>
                
                 </div>
                 <div className="">
                    {
                        provinces != null && provinces.map((province) => 
                        <div class="card2 mt-3 tab-card">
                        <div class="card-header tab-card-header" style={{backgroundColor:'#fff',color:'#7A00FF',padding:4}}>
                                <div>
                                <a class="nav-link title">Province of {province.province_name} </a>
                               
                                
                                </div>
                                
                       
                        </div>
                        <div class="desktop" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                <div className="col-xl-2 col-lg-2 col-md-2">
                                    <div className="" style={{width:'100%'}}>
                                     {
                                         province.leader.image == '' &&
                                         <img class="card-img-top" style={{height:"100%",width:'100%',borderRadius:'50%'}} src={require(`../asset/images/profile.jpg`)} alt="Card image cap"/>

                                     }
                                     {
                                         province.leader.image != '' &&
                                         <img class="card-img-top" style={{height:"100%",width:'100%',borderRadius:'50%'}} src={`${IMAGEPATH}files/image400/${province.leader.image}`} alt="Card image cap"/>

                                     }   
                                    {/* <span  style={{cursor:'pointer',textDecoration:'underline',fontSize:13 }}>Edit</span> */}

                                    </div>
                                </div>
                               
                                <div className="col-xl-9 col-lg-9 col-md-3" style={{width:'55%'}}>
                                <div class="row">
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                      <p class="cardtitle">Name</p>
                                      <p class="body">{province.leader.firstname} {province.leader.lastname} </p>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                      <p class="cardtitle">Status</p>
                                      <p class="body">{province.leader.status} </p>
                                    </div>                                   
                                </div>
                                <div class="row" style={{marginTop:12}}>
                                    <div className="col-xl-6 col-lg-6 col-md-3" style={{marginTop:10}}>
                                      <p class="cardtitle">Position</p>
                                      <p class="body">{province.leader.position} </p>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-3" style={{marginTop:10}}>
                                      <p class="cardtitle">Email</p>
                                      <p class="body">{province.leader.email1} </p>
                                    </div>
                                   
                                </div>
                                </div>
                                <hr  />
                                <div style = {{display:'flex',flexDirection:"row", justifyContent:'flex-end', margin:7,width:'100%'}}>
                                    <div style={{}} className="btnlist">
                                    {
                                         user.userType == 'superadmin' && 
                                        <button onClick = { () => { setedit(true); setprovince(province); setfile(null); } } type ="submit" class="mdc-button w-10">
                                        <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit Province
                                        </button>  
                                    } 
                                        
                                    </div>
                                    <div style={{}} className="btnlist">
                                       <Link to = {`/provincedetails/${window.btoa(province.id)}`} type ="submit" class="mdc-button w-100 actionshow" style={{textDecoration:'none', border:'2px solid #7a00ff',padding:5}}>
                                        <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">visibility</i> View More
                                        </Link>
                                
                                    </div>
                                </div>  
                                {/* <div style={{ display:'flex',flexDirection:'row'}}> 
                                <div style={{}} className="actionbtn">
                                {
                                    user.userType == 'superadmin' && 
                                    <button onClick = { () => { setedit(true); setprovince(province); setfile(null); } } type ="submit" class="mdc-button w-10 btnshow2">
                                    <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">add</i> Edit Province
                                    </button>  
                                }
                               
                                </div>
                                <div style={{}} className="actionbtn">
                                   <Link to = {`/provincedetails/${window.btoa(province.id)}`} type ="submit" class="mdc-button w-100 btnshow">
                                    <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">visibility</i> View More
                                    </Link>
                                
                                </div>
                                </div> 
                                */}
                            </div>
                            </div>     
                        </div>
                        </div>
                        <div class="mobile" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                <div className="col-xl-1 col-lg-2 col-md-2 pixside">
                                    <div className="" style={{width:'100%'}}>
                                     {
                                         province.leader.image == '' &&
                                         <img class="card-img-top" style={{height:"70%",width:'70%'}} src={require(`../asset/images/profile.jpg`)} alt="Card image cap"/>

                                     }
                                     {
                                         province.leader.image != '' &&
                                         <img class="card-img-top" style={{height:"100%",width:'100%'}} src={`${IMAGEPATH}files/image400/${province.leader.image}`} alt="Card image cap"/>

                                     }   

                                    </div>
                                </div>
                               
                                <div className="col-xl-9 col-lg-9 col-md-3" style={{width:'55%'}}>
                                <div>
                                  <p class="cardtitle">{province.leader.firstname} {province.leader.lastname} </p>
                                  <p class="bodymobile">{province.leader.status} </p>
                                  <p class="bodymobile">{province.leader.position} </p>
                                </div>    
                               
                                </div>
                                <hr  />
                                <div style = {{display:'flex',flexDirection:"row", justifyContent:'flex-end', margin:7,width:'100%',marginTop:-10}}>
                                    <div style={{}} className="btnlist">
                                    {
                                         user.userType == 'superadmin' && 
                                        <button onClick = { () => { setedit(true); setprovince(province); setfile(null); } } type ="submit" class="mdc-button w-10 actionshow">
                                        <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">add</i> Edit
                                        </button>  
                                    } 
                                        
                                    </div>
                                    <div style={{}} className="btnlist">
                                       <Link to = {`/provincedetails/${window.btoa(province.id)}`} type ="submit" class="mdc-button w-100 actionshow" style={{textDecoration:'none', border:'2px solid #7a00ff',padding:2}}>
                                        <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">visibility</i> View More
                                        </Link>
                                
                                    </div>
                                </div>  
                               
                            </div>
                            </div>     
                        </div>
                        </div>
                    </div>
                        )
                    } 
                 </div>
                {
                    provinces != null && provinces.length == 0 &&
                    <div class="card mt-3">
                    
                        <div className = "" style={{textAlign:'center'}}>
                        
                        <div class="" style={{textAlign:'center', marginLeft:'auto',marginRight:'auto'}}>
                            No Provinces Added Yet
                        </div>

                        </div>
                    </div>
                } 
               
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    );
}


export default withRouter(Provinces);