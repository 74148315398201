import React, {useState, useEffect} from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { CreateArchdeaconry, GetArchdeaconries, EditArchdeaconry, GetParishInArchdeaconary } from '../redux/action';
import { GetParish } from '../Message/getdata';
import SearchData from './search';


const Card5 = () => {
  const [ show, setshow ] = useState(false);
  const [ showparish,setshowparish ] = useState(false);
  const { handleSubmit, errors, register, watch } = useForm();
  const dispatch = useDispatch();
  const [IsSubmitting, setIsSubmitting ] = useState(false);
  const { id } = useParams();
  const archdeaconries  = useSelector(state => state.root.archdeaconries);
  const [IsFetching, setIsFetching ] = useState(false);
  const [ edit, setedit ] = useState(false);
  const [ arch, setarch ] = useState(null);
  const parishesInarchdeaconries = useSelector(state => state.root.parishesInarchdeaconries);
  const user = useSelector(state => state.root.user_login);
  const VicarsInDiocese = useSelector(state => state.root.VicarsInDiocese);

  const onSubmit = async (data,e) => {
    setIsSubmitting(true);
    let dioceseId = window.atob(id);
    await dispatch(CreateArchdeaconry(data,dioceseId,e));
    setIsSubmitting(false);
  }

  const GetParish = async (arch) => {
    setIsFetching(true);
    await dispatch(GetParishInArchdeaconary(arch.id));
    setIsFetching(false);
  }

  const updateSubmit = async (data,e) => {
    setIsSubmitting(true);
    let dioceseId = window.atob(id);
    await dispatch(EditArchdeaconry(data,arch.id,e));
    setIsSubmitting(false);
  }
  useEffect(() => {
      let dioceseId = window.atob(id);
      dispatch(GetArchdeaconries(dioceseId));
  },[]);

  const ReturnVicar = (parishId) => {
        if(VicarsInDiocese == null) return false;
        let index = VicarsInDiocese.findIndex(x => x.parish_id == parishId);
        if(index > -1)
        {
            return `${VicarsInDiocese[index].firstname} ${VicarsInDiocese[index].lastname}`;
        }
        else 
        {
            return 'Not Defined';
        }
  }
    return (
        <div className="">
             <Modal show = {show}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshow(false)}>
                <Modal.Title style={{color:'#fff'}}>Create Archdeaconry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Archdeaconry Name" 
                            name ="name"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.name?.type == "required" && "Archdeaconry Name is required"}</small>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
             </Modal>
             <Modal show = {edit}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setedit(false)}>
                <Modal.Title style={{color:'#fff'}}>Edit Archdeaconry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(updateSubmit)}>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Archdeaconry Name" 
                            defaultValue = { arch != null && arch.name }
                            name ="name"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.name?.type == "required" && "Archdeaconry Name is required"}</small>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
             </Modal>
             
             <Modal show = {showparish} size="lg">
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshowparish(false)}>
                <Modal.Title style={{color:'#fff'}}>Parish In Archdeaconry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="" style={{marginTop:40}}>
                {
                    IsFetching == false && parishesInarchdeaconries != null && parishesInarchdeaconries.map((parish) =>
                    <div class="card12 mt-3 tab-card">
                            <div class="" id="">
                            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div style={{padding:30}}>
                                <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-3">
                                        <p className = "cardtitle">Parish Name</p>
                                        <p className = "body">{parish.name}</p>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-3">
                                        <p className="cardtitle">Address</p>
                                        <p className="body">{parish.address}</p>
                                        </div>
                                        
                                </div>
                                </div>     
                            </div>
                            </div>
                    </div>
                        
                    )
                }
                {
                        IsFetching == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                }
                {
                    IsFetching == false && parishesInarchdeaconries != null && parishesInarchdeaconries.length == 0 &&
                    <div class="card mt-3">
                    
                        <div className = "" style={{textAlign:'center'}}>
                        
                        <div class="" style={{width:'100%',marginLeft:'auto',marginRight:'auto'}}>
                            No Parish Found In {arch != null && arch.name}
                        </div>

                        </div>
                    </div>
                } 
                  
                 </div>
                </Modal.Body>
          </Modal>
              <div class="header_btn desktop">
                    <div>
                    {
                     (user.userType == 'superadmin' || (user.userType == 'admin' && user.dioceseId == window.atob(id)) ) &&     
                    <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                    <i class="mdc-drawer-arrow material-icons">add</i> Create Archdeaconry
                    </button>
                    }
                    </div>
                    <SearchData page = 'Archdeaconry' id = {id} />
              </div>
                <div class="mobile">
                <div class="header_btn mobile">
                    <div>
                    {
                        (user.userType == 'superadmin' || (user.userType == 'admin' && user.dioceseId == window.atob(id)) ) && 
                        <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                        <i class="mdc-drawer-arrow material-icons">add</i> Create Archdeaconry
                        </button>
                    }
                    </div>
                    <SearchData page = 'Archdeaconry' id = {id} />
                </div>
                </div>
             
            {
                archdeaconries != null && archdeaconries.map((arch) =>
                <div class="card2 mt-3 tab-card">
                <div class="card-header tab-card-header" style={{backgroundColor:'#fff',color:'#7A00FF',padding:4}}>
                        <div>
                        <a class="nav-link" style={{fontSize:20}}>{arch.name} </a>
                       
                        
                        </div>
                </div>
                <div class="" id="">
                <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                    <div style={{padding:30}}>
                        <div class="row">
                            <div className="col-xl-12 col-lg-12 col-md-12" style={{marginTop:0}}>
                              <p className = "cardtitle">Headquarter</p>
                              <p className = "body desktop">{arch.headquarter == null ? 'Not Defined' : arch.headquarter.name}</p>
                              <p className = "bodymobile mobile">{arch.headquarter == null ? 'Not Defined' : arch.headquarter.name}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div className="col-xl-6 col-lg-6 col-md-3" style={{marginTop:10}}>
                              <p className="cardtitle">Administrator</p>
                              <p className="body desktop">{arch.headquarter == null ? 'Not Defined' : ReturnVicar(arch.headquarter.id) }</p>
                              <p className="bodymobile mobile">{arch.headquarter == null ? 'Not Defined' : ReturnVicar(arch.headquarter.id) }</p>
                            </div> 
                        </div>
                       

                    </div>
                   
                    <div style = {{display:'flex',flexDirection:"row", justifyContent:'flex-end', margin:7, marginTop:-50}}>
                    <div style={{}} className="btnlist">
                    {
                        (user.userType == 'superadmin' || (user.userType == 'admin' && user.dioceseId == window.atob(id)) ) && 
                        <button onClick = { () => { setedit(true); setarch(arch); } } type ="submit" class="mdc-button w-10 actionshow" style={{marginRight:10}}>
                        <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                        </button>
                    } 
                        
                    </div>
                    <div style={{}} className ="btnlist">
                        <button onClick = { () => { setshowparish(true); GetParish(arch); setarch(arch);  } } type ="submit" class="mdc-button w-100 actionshow" style={{textDecoration:'none', border:'2px solid #7a00ff',padding:5}}>
                        <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">visibility</i> View Parish
                        </button>
                        
                        </div>
                    </div>  
                  
                    {/* <div class="" style={{width:200,marginTop:0,marginBottom:20,marginLeft:'auto',marginRight:'auto'}}>
                                    <button onClick = { () => { setshowparish(true); GetParish(arch); setarch(arch);  } } type ="submit" class="mdc-button w-100" style={{border:'2px solid #7A00FF',borderRadius:4}}>
                                    <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">visibility</i> View Parish
                                    </button>
                                </div> */}
                </div>
                </div>
    </div>
                )
            } 
            {
                    IsFetching == false && archdeaconries != null && archdeaconries.length == 0 &&
                    <div class="card mt-3">
                    
                        <div className = "" style={{textAlign:'center'}}>
                        
                        <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                            No Archdeaconry Added Yet
                        </div>

                        </div>
                </div>
            }  
             {
                    IsFetching == true && 
                    <div class="card mt-3">
                    
                        <div className = "" style={{textAlign:'center'}}>
                        
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>

                        </div>
                </div>
            }  
        </div>
    );
}

export default Card5;