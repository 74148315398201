import React, {useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Aside from './aside';
import Header from './header';
import Footer from './footer';
import { CreateAdmin, GetAdmins, EditAdmin, SetPassword } from '../redux/action/index';
import Loader from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import '../asset/css/bootstrap.css';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import SearchData from './search';
import { Link } from 'react-router-dom';
import { GetDiocese } from '../Message/getdata';
import AsyncSelect from 'react-select/async';

const Users = (props) => {
    const users = useSelector(state => state.root.users);
    const userstotalpages = useSelector(state => state.root.userstotalpages);
    const dispatch = useDispatch();
    const [IsFetching, setIsFetching] = useState(false);
    const [IsSubmitting, setIsSubmitting] = useState(false);
    const [ show, setshow ] = useState(false);
    const { handleSubmit, errors, register, watch } = useForm();
    const admins = useSelector(state => state.root.admins);
    const [ edit, setedit ] = useState(null);
    const [ diocese, setdiocese] = useState(null);
    const user = useSelector(state => state.root.user_login);
    const [ reset, setreset ] = useState(false);
    const [ thisuser, setthisuser ] = useState(null);

    useEffect(() => {
      process();
    },[]);

    const process = async () => {
      setIsFetching(true);
      await dispatch(GetAdmins());
      setIsFetching(false);
    }


    const onSubmit = async (data,e) => {
      if(diocese == null && data.userType == 'admin')
      {
        alert("Diocese Field is Required");
        return false;
      }
      data['dioceseId'] = diocese;
      setIsSubmitting(true);
      await dispatch(CreateAdmin(data,e));
      setIsSubmitting(false);
      }
    
      const updateSubmit = async (data,e) => {
        setIsSubmitting(true);
        await dispatch(EditAdmin(data,edit.id));
        setIsSubmitting(false);
      }

      const resetSubmit = async (data,e) => {
        setIsSubmitting(true);
        await dispatch(SetPassword(data,thisuser.id,e));
        setIsSubmitting(false);
      }

      const loadOptions = async (inputValue, callback) => {
        //alert(inputValue);
        let r = await GetDiocese(inputValue);
        return r;
      }

    return (
        <div class="body-wrapper">
        <Aside />
        <Modal show = {reset} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setreset(false)}>
                <Modal.Title style={{color:'#fff'}}>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(resetSubmit)}>
                   <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="New Password" 
                            name ="password"
                            type ="password" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.passsword?.type == "required" && "Password is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Confirm New Password" 
                            name ="password_confirmation"
                            type ="password" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.password_confirmation?.type == "required" && "Confirm Password is required"}</small>
                        </div>
                    </div>
                   <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>
        <Modal show = {show} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshow(false)}>
                <Modal.Title style={{color:'#fff'}}>Create Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                   <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Full Name" 
                            name ="name"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.name?.type == "required" && "Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Email" 
                            name ="email"
                            type ="email" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.name?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select
                          className="form-control" 
                          style={{marginBottom:20}} 
                          name ="userType"
                          ref={register({
                            required: "Required"
                            })}
                        >
                            <option value="">Select User Type</option>
                            <option value="admin">Admin</option>
                            <option value="superadmin">Super Admin</option>
                        </select>    
                            <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <AsyncSelect   
                              cacheOptions
                              placeholder="Choose User Diocese"
                              loadOptions = {loadOptions}
                              isClearable
                              onChange = {(opt) => setdiocese(opt==null ? '' : opt.value) }
                          /> 
                            <small className="text-danger">Diocese Name is required to add an admin user</small>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>
          <Modal show = {edit == null ? false : true} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setedit(null)}>
                <Modal.Title style={{color:'#fff'}}>Create Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(updateSubmit)}>
                   <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            defaultValue = { edit != null && edit.name}
                            placeholder ="Full Name" 
                            name ="name"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.name?.type == "required" && "Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Email" 
                            defaultValue = { edit != null && edit.email}
                            name ="email"
                            type ="email" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.name?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select
                          className="form-control" 
                          style={{marginBottom:20}} 
                          name ="userType"
                          defaultValue = { edit != null && edit.userType}
                          ref={register({
                            required: "Required"
                            })}
                        >
                            <option value="">Select User type</option>
                            <option value="admin">Admin</option>
                            <option value="superadmin">Super Admin</option>
                        </select>    
                            <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <AsyncSelect   
                              cacheOptions
                              placeholder="Choose User Diocese"
                              loadOptions = {loadOptions}
                              isClearable
                              onChange = {(opt) => setdiocese(opt==null ? '' : opt.value) }
                          /> 
                            <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>

    <div class="main-wrapper mdc-drawer-app-content">
    <Header />

      <div class="page-wrapper mdc-toolbar-fixed-adjust">
        <main class="content-wrapper" style={{marginTop:-20}}>
          <div class="">
            <div class="">
            {/* <div class="header_btn" style={{width:200,marginTop:20}}>
                    <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-100">
                    <i class="mdc-drawer-arrow material-icons">add</i> Create Admin
                    </button>
                    <SearchData page = "User" id = '' />
                </div> */}
               <div className ="header1">
                  <Link class="mdc-button" style ={{textDecorationLine:'none',fontSize:20}}>
                  Church of Nigeria 
                </Link>
                </div>  
              {/* <div class="header_btn2">
                    <div>
                    <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-100">
                    <i class="mdc-drawer-arrow material-icons">add</i> Create Admin
                    </button>
                    </div>
                <SearchData page = 'User' id = '' />
                </div>   */}
                <div class="header_btn2 desktop">
                        <div>
                        {
                            user.userType == 'superadmin' &&    
                            <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                            <i class="mdc-drawer-arrow material-icons">add</i> Create Admin
                            </button>
                        }
                        </div>
                        <SearchData page = 'User' id = '' />
                </div>
                <div class="mobile" style={{marginTop:40}}>
                    <div class="header_btn mobile">
                    <div>
                        {
                            user.userType == 'superadmin' &&    
                            <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                            <i class="mdc-drawer-arrow material-icons">add</i> Create Admin
                            </button>
                        }
                        </div>
                        <SearchData page = 'User' id = '' />
                    </div>
                </div>  
              <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                <div class="mdc-card p-0">
                  <h6 class="card-title card-padding pb-0">Users <span style={{fontSize:12}}>( Super admin can create, edit and view all Information, admin can not create a province and diocese) </span></h6>
                 
                  <div>
                    
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                        <th className="text-center">S/N</th>
                          <th className="text-center">Full Name</th>
                          <th className="text-center">Email</th>
                          <th className="text-center">User Type</th>
                          <th className="text-center">Diocese</th>
                          <th className="text-center">Option</th>
                        </tr>
                      </thead>
                      <tbody>
                       {
                         admins != null && admins.map((admin,x) =>
                           <tr>
                           <td className ="text-center">{x+1}</td>  
                           <td className ="text-center">{admin.name}</td>
                           <td className ="text-center">{admin.email}</td>
                           <td className ="text-center">{admin.userType}</td>
                           <td className ="text-center">{admin.diocese != null && admin.diocese.name}</td>
                           <td  className ="text-center">
                              <i onClick = { () => setedit(admin) } class="mdc-drawer-arrow material-icons">edit</i> 
                              <i onClick = {  () => { setthisuser(admin); setreset(true); }} class="mdc-drawer-arrow material-icons">https</i>
                           </td>
                           </tr>
                         )
                       }
                     </tbody>
                    </table>
                  </div>
                  {
                    IsFetching == false && admins != null && admins.length == 0 &&
                    <div style={{textAlign:'center'}}>
                       <p>No Data Found</p>
                    </div>
                  }
                  {
                        IsFetching == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                          visible={true}
                          type="Puff"
                          color="#7a00ff"
                          height={30}
                          width={30}
                          timeout= {0} //3 secs
                      
                        />
                        </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {/* {
            userstotalpages != null && userstotalpages > 1 &&
            <div style={{textAlign:'center'}}>
              <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={<a href="">...</a>}
                  pageCount={userstotalpages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  containerClassName={'pagination'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  activeClassName={'active'}
              /> 
            </div>
          } */}
        </main>
        <Footer />
      </div>
    </div>
  </div>
    );
}

export default Users;