import React, {useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {useForm } from 'react-hook-form';
import { UserLogin, Refresh } from '../redux/action/index';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";



const Login = (props) => {

  const { handleSubmit, register, errors } = useForm();
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    //alert(JSON.stringify(data));
    await dispatch(UserLogin(data, props));
    setIsSubmitting(false);
  }

  useEffect(() => {
    dispatch(Refresh());
  },[]);

    return (
        <div class="body-wrapper">
        <div class="main-wrapper">
          <div class="page-wrapper full-page-wrapper d-flex align-items-center justify-content-center">
            <main class="auth-page">
              <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                  <div class="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"></div>
                  <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet">
                    <div class="mdc-card">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Email address</label>
                            <input 
                              type="email" 
                              class="form-control" 
                              id="exampleInputEmail1" 
                              aria-describedby="emailHelp" 
                              placeholder="Enter email"
                              name ="email"
                              ref={register({
                                required: "Required"
                                })}
                            />
                            <small className="text-danger">{errors.email?.type == "required" && "Email is required"}</small>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Password</label>
                            <input 
                              type="password" 
                              class="form-control" 
                              name ="password"
                              id="exampleInputPassword1" 
                              placeholder="Password"
                              ref={register({
                                required: "Required"
                                })}
                            />
                            <small className="text-danger">{errors.password?.type == "required" && "Password is required"}</small>
                        </div>
                       
                        <div style={{marginTop:30}} class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                          
                          {
                            IsSubmitting == false &&
                            <button type ="submit" class="mdc-button mdc-button--raised w-100">
                               Login
                            </button>
                          }
                          {
                          IsSubmitting == true &&
                          <div style={{textAlign:'center'}}>
                          <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        
                          />
                          </div>
                        }
                        </div>
                        
                        <div style={{marginTop:30,textAlign:'center'}} class="mdc-layout-grid__cell">
                          <Link to="/register">Don't Have Account ? Register</Link>
                        </div>
                    </form>
                    </div>
                  </div>
                  <div class="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"></div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
}

export default withRouter(Login);