


import React, {useState, useEffect } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { CreateDioceseOfficial, CreateProfile, CreateEducation, EditEducation } from '../redux/action';
import { IMAGEPATH } from '../redux/action/constant';


const CreateNewEducation = (props) => {

    const [ show, setshow ] = useState(false);
    const [ edit, setedit ] = useState(false);
    const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
    const [IsSubmitting, setIsSubmitting ] = useState(false);
    const { id } = useParams();
    const [ file, setfile ] = useState(null);
    const dioceanofficials = useSelector(state=>state.root.dioceanofficials);
    const month = useSelector(state => state.root.month);
    const year = useSelector(state => state.root.year);
    const thispriest = useSelector(state=>state.root.onboard_information);

    const eduSubmit = async (data,e) => {
        //alert('gate');
        setIsSubmitting(true);

        
        //return false;
        //alert(JSON.stringify(props.id));
        //let dioceseId = window.atob(id);
        //alert(JSON.stringify(thispriest.id));
        //console.log(data);
        if(props.type != undefined && props.type == 'create')
           await dispatch(CreateEducation(data, thispriest.id,e));
        else if(props.type != undefined && props.type == 'edit')
           await dispatch(EditEducation(data, props.education.id,e));
        else 
            //alert(JSON.stringify(props.id));
           await dispatch(CreateEducation(data, props.id,e));
        setIsSubmitting(false);
    }


    useEffect(() => {
        //alert(JSON.stringify(year));
       // alert(month[0]);
        // let start = 1950;
        // let allstart = [];
        // for(let r = 0; r < 100; r++)
        // {
        //     allstart.push(start+r);
        // }
        // setyear(allstart);
    },[])

    return (
        <div className="">
        <div style={{marginTop:30}}>
        <form onSubmit={handleSubmit(eduSubmit)}>
        <div className="row"  style={{marginBottom:20}}>
            <div className="col-lg-12 col-sm-6">
            <input 
                type = "text"
                placeholder = "Name of Institution"
                className="form-control" 
                defaultValue = { props.type == 'edit' ? props.education.institution : '' }
                name = "institution"
                ref={register({
                required: "Required"
                })}
            />
                <small className="text-danger">{errors.name?.type == "required" && "Institution is required"}</small>
            </div>
            
        </div>
        <div className="row"  style={{marginBottom:20}}>
            <div className="col-lg-6 col-sm-6">
            <input 
                className="form-control" 
                placeholder ="Course" 
                name ="course"
                defaultValue = { props.type == 'edit' ? props.education.course : '' }
                type ="text" 
                ref={register({
                    required: "Required"
                    })}
            />
                <small className="text-danger">{errors.institution?.type == "required" && "Name is required"}</small>
            </div>
            <div className="col-lg-6 col-sm-6">
            <input 
                className="form-control" 
                placeholder ="Qualification" 
                name ="qualification"
                defaultValue = { props.type == 'edit' ? props.education.qualification : '' }
                type ="text" 
                ref={register({
                    required: "Required"
                    })}
            />
                <small className="text-danger">{errors.qualification?.type == "required" && "Required"}</small>
            </div>
        </div>
        <div className="row"  style={{marginBottom:20}}>
            <div className="col-lg-6 col-sm-6">
            <select 
                ref={register({
                required: "Required"
               
                })}
                defaultValue = { props.type == 'edit' ? props.education.startmonth : '' }
                name = "startmonth"
                className="form-control">
            <option value="">Select Start Month</option>
            {
                month != null && month.map((mon,x) =>
                <option value={mon}>{mon}</option>
                )
            }
            </select>
                <small className="text-danger">{errors.startmonth?.type == "required" && "Required"}</small>
            </div>
            <div className="col-lg-6 col-sm-6">
            <select 
                defaultValue = { props.type == 'edit' ? props.education.startyear : '' }
                name = "startyear"
                ref={register({
                required: "Required"
                })}
                className="form-control">
            <option value="">Select Start Year</option>
            {
                year != null && year.map((y) =>
                <option value={y.id}>{y.id}</option>
                )
            }
            </select>
                <small className="text-danger">{errors.startyear?.type == "required" && "Start Year is required"}</small>
            </div>
        </div>
        <div className="row"  style={{marginBottom:20}}>
            <div className="col-lg-6 col-sm-6">
            <select 
                ref={register({
                    required: "Required"
                    })}
                defaultValue = { props.type == 'edit' ? props.education.endmonth : '' }    
                name = "endmonth"
                className="form-control">
            <option value="">Select End Month</option>
            {
                month != null && month.map((mon) =>
                <option value={mon}>{mon}</option>
                )
            }
            </select>
                <small className="text-danger">{errors.endmonth?.type == "required" && "Required"}</small>
            </div>
            <div className="col-lg-6 col-sm-6">
            <select 
                name = "endyear"
                ref={register({
                    required: "Required"
                    })}
                defaultValue = { props.type == 'edit' ? props.education.endyear : '' }    
                className="form-control">
            <option value="">Select End Year</option>
            {
                year != null && year.map((y) =>
                <option value={y.id}>{y.id}</option>
                )
            }
            </select>
                <small className="text-danger">{errors.endyear?.type == "required" && "Required"}</small>
            </div>
        </div>
                      
        <div className="row" style={{marginTop:10}}>
            <div className="col-lg-4 col-sm-4">
            <div style={{marginTop:0}} class="">
            {
            IsSubmitting == false &&
            <button type ="submit" class="mdc-button mdc-button--raised">
                Submit
            </button>
            }
            {
            IsSubmitting == true &&
            <div style={{textAlign:'center'}}>
            <Loader
                visible={true}
                type="Puff"
                color="#7a00ff"
                height={30}
                width={30}
                timeout= {0} //3 secs
            />
            </div>
            }
        </div>
            </div>
            
        </div>
        </form>
        </div>
     </div>
    );
}

export default CreateNewEducation;

