import React, {useState, useEffect } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { CreateDioceseOfficial, EditDioceseOfficial, GetDioceseOfficial } from '../redux/action';
import { IMAGEPATH } from '../redux/action/constant';
import SearchData from './search';


const Card2 = (props) => {

    const [ show, setshow ] = useState(false);
    const [ edit, setedit ] = useState(false);
    const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
    const [IsSubmitting, setIsSubmitting ] = useState(false);
    const { id } = useParams();
    const [ file, setfile ] = useState(null);
    const dioceanofficials = useSelector(state=>state.root.dioceanofficials);
    const [ official, setofficial ] = useState(null);
    const user = useSelector(state => state.root.user_login);

    const onSubmit = async (data,e) => {
        setIsSubmitting(true);
        let dioceseId = window.atob(id);
        const formdata = new FormData();
        formdata.append('firstname',data.firstname);
        formdata.append('lastname', data.lastname);
        formdata.append('middlename', data.middlename);
        formdata.append('position', data.position);
        formdata.append('email',data.email);
        formdata.append('phonenumber', data.phonenumber);
        if(file != null) formdata.append('file', file);
        await dispatch(CreateDioceseOfficial(formdata,dioceseId, e));
        setIsSubmitting(false);
    }

    const updateSubmit = async (data,e) => {
        setIsSubmitting(true);
        let dioceseId = window.atob(id);
        const formdata = new FormData();
        formdata.append('firstname',data.firstname);
        formdata.append('lastname', data.lastname);
        formdata.append('middlename', data.middlename);
        formdata.append('position', data.position);
        formdata.append('email',data.email);
        formdata.append('phonenumber', data.phonenumber);
        if(file != null) formdata.append('file', file);
        await dispatch(EditDioceseOfficial(formdata,official.id, e));
        setIsSubmitting(false);
    }

    useEffect(() => {
        GetData();
    },[])

    const GetData = () => {
        let dioceseId = window.atob(id);
        //alert(dioceseId);
        dispatch(GetDioceseOfficial(dioceseId));
    }

    const handleChange = (event) => {
        setfile(event.target.files[0]);
    }
    return (
        <div className="">
         
        <div class="header_btn desktop">
            <div>
            <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
            <i class="mdc-drawer-arrow material-icons">add</i> Create Diocesian Official
            </button>
            </div>
            <SearchData page = 'Diocean Official' id = {id} />
        </div> 
        <div class="mobile">
        <div class="header_btn mobile">
            <div>
            {
                (user.userType == 'superadmin' || (user.userType == 'admin' && user.dioceseId == window.atob(id)) ) &&    
                <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                <i class="mdc-drawer-arrow material-icons">add</i> Create Diocesian Official
                </button>
            }
            </div>
            <SearchData page = 'Diocean Official' id = {id} />
        </div>
        </div>
       
          <Modal show = {show} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshow(false)}>
                <Modal.Title style={{color:'#fff'}}>Create Official</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="First Name" 
                            name ="firstname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Last Name" 
                            style={{marginBottom:20}}
                            name ="lastname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.lastname?.type == "required" && "Last Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Middle Name" 
                            style={{marginBottom:20}}
                            name ="middlename"
                            type ="text" 
                            ref={register({
                               
                                })}
                        />
                            <small className="text-danger">{errors.middlename?.type == "required" && "Middle Name is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Position" 
                            style={{marginBottom:20}}
                            name ="position"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Email" 
                            style={{marginBottom:20}}
                            name ="email"
                            type ="text" 
                            ref={register({
                                
                                })}
                        />
                            <small className="text-danger">{errors.email?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Phone Number" 
                            style={{marginBottom:20}}
                            name ="phonenumber"
                            type ="number" 
                            ref={register({
                                
                                })}
                        />
                            <small className="text-danger">{errors.phonenumber?.type == "required" && "Phone Number is required"}</small>
                        </div>
                    </div>
            
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            onChange = {handleChange}
                            type ="file" 
                            ref={register({
                               
                                })}
                        />
                            <small className="text-danger">{errors.file?.type == "required" && "Image is required"}</small>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>
          <Modal show = {edit} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setedit(false)}>
                <Modal.Title style={{color:'#fff'}}>Edit Official</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(updateSubmit)}>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="First Name" 
                            defaultValue = {official != null && official.firstname}
                            name ="firstname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Last Name" 
                            style={{marginBottom:20}}
                            name ="lastname"
                            defaultValue = {official != null && official.lastname}
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.lastname?.type == "required" && "Last Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Middle Name" 
                            style={{marginBottom:20}}
                            defaultValue = {official != null && official.middlename}
                            name ="middlename"
                            type ="text" 
                            ref={register({
  
                                })}
                        />
                            <small className="text-danger">{errors.middlename?.type == "required" && "Middle Name is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Position" 
                            style={{marginBottom:20}}
                            name ="position"
                            defaultValue = {official != null && official.position}
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Email" 
                            defaultValue = {official != null && official.email}
                            style={{marginBottom:20}}
                            name ="email"
                            type ="text" 
                            ref={register({
                                
                                })}
                        />
                            <small className="text-danger">{errors.email?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Phone Number" 
                            defaultValue = {official != null && official.phonenumber}
                            style={{marginBottom:20}}
                            name ="phonenumber"
                            type ="number" 
                            ref={register({
                                
                                })}
                        />
                            <small className="text-danger">{errors.phonenumber?.type == "required" && "Phone Number is required"}</small>
                        </div>
                    </div>
            

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Status" 
                            onChange = {handleChange}
                            style={{marginBottom:20}}
                            name ="file"
                            type ="file" 
                            ref={register({
                               
                                })}
                        />
                            <small className="text-danger">{errors.file?.type == "required" && "Image is required"}</small>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>   
        {
            dioceanofficials != null && dioceanofficials.map((official) =>
            <div class="card2 mt-3 tab-card">
            <div class="" id="">
                <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                    <div style={{padding:30}}>
                            <div className="row">
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                            {
                                official.image == '' &&
                                <img class="card-img-top" style={{height:80,width:80,borderRadius:40}} src={require(`../asset/images/profile.jpg`)} alt="Card image cap"/>

                            }
                            {
                                official.image != '' &&
                                <img class="card-img-top" style={{height:80,width:80,borderRadius:40}} src={`${IMAGEPATH}files/image400/${official.image}`} alt="Card image cap"/>

                            }     
                            </div>
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10" >
                            <div class="" style={{marginLeft:-20}}>
                                <p className="cardtitle">{official.firstname} {official.lastname}</p>
                                <p className="bodymobile">{official.email != null ? official.email : 'Email not Defined'}</p>
                                <p className="bodymobile">{official.phonenumber != null ? official.phonenumber : 'Phone Number not Defined'}</p>
                                <p className="bodymobile">{official.position}</p>
                                </div>
                            </div>
                            </div>   
                    </div> 
                    <div style={{position:'relative',top:-35,right:10,marginBottom:10}}>
                    {
                      (user.userType == 'superadmin' || (user.userType == 'admin' && user.dioceseId == window.atob(id)) ) &&   
                    <button onClick = { () => { setedit(true); setofficial(official) } } type ="submit" class="mdc-button w-10 actionshow" style={{textDecoration:'none', border:'2px solid #7a00ff',padding:5,borderRadius:4,float:'right'}}>
                    <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                    </button>
                    }
                    
                    </div>    
                </div>
            
            </div>
            </div>
            )
        }  
       
        {
            dioceanofficials != null && dioceanofficials.length == 0 &&
            <div class="card mt-3">
            
                <div className = "" style={{textAlign:'center'}}>
                
                <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                    No Dioceans Official Added Yet
                </div>

                </div>
            </div>
        }   
     </div>
    );
}

export default Card2;