import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Aside2 from './aside_boarding';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import CreateNewPerferment from './createnewperferment';
import OnboardLink from './onboardlinks';


const OnboardPerferment = (props) => {
	const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
  const [IsSubmitting, setIsSubmitting ] = useState(false);
  const [ show, setshow ] = useState(false);
  const [ perferment, setperferment ] = useState(false);
  const [ type, settype ] = useState(false);
  const onboard_perferment = useSelector(state=>state.root.onboard_perferment);
  const [ IsFetching, setIsFetching ] = useState(false);

  const onSubmit = async (data,e) => {
    setIsSubmitting(true);
    //await dispatch(CreateDocumentType(data,e));
    setIsSubmitting(false);
  }

  const EditEducationModal = () => {

  }

  const dioceseSubmit = async (data,e) => {
    setIsSubmitting(true);
    //await dispatch(CreateDocumentType(data,e));
    setIsSubmitting(false);
  }
	
    return (
        <div class="body-wrapper">
         
         <Modal show = {show} size="lg" Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshow(false)}>
                <Modal.Title style={{color:'#fff'}}>Add Perferment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container" style={{marginTop:40}}>
                   <CreateNewPerferment type = {type} perferment = {perferment} />
                 </div>
                </Modal.Body>
          </Modal>  
        <Aside2 />
        <div class="main-wrapper mdc-drawer-app-content">
          <Header />
          <div className = "mobile">
             <OnboardLink />
          </div>
          <div class="page-wrapper mdc-toolbar-fixed-adjust">
            <main class="content-wrapper" style={{marginTop:-20}}>
              <div class="">
                <div class="">
                <div className ="header1">
                  <Link class="mdc-button" style ={{textDecorationLine:'none',fontSize:20}}>
                  Complete Your Profile 
                </Link>
                </div>  
                 <div className="">
                 <div class="header_btn" style={{width:200,marginTop:20}}>
                    <button onClick = { () => { setshow(true); settype('create'); } } type ="submit" class="mdc-button mdc-button--raised w-100">
                    <i class="mdc-drawer-arrow material-icons">add</i> Add Preferment
                    </button>
                  </div>   
                  {
                      onboard_perferment != null && onboard_perferment.map((per) => 
                       <div class="card2 mt-3 tab-card">
                        <div class="desktop" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                    <p className = "cardtitle">Status</p>
                                    <p className = "body">{per.status}</p>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                    <p className = "cardtitle">Start Date</p>
                                    <p className = "body">{per.startMonth} {per.startYear}</p>
                                    </div>
                                    
    
                                
                            </div>
                            <div style={{position:'relative',bottom:30,right:0}}>
                                <button onClick = { () => { setperferment(per); setshow(true); settype('edit') } } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                </button>
                                
                            </div> 
                           
                            </div>     
                        </div>
                        </div>
                        <div class="mobile" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                    <p className = "cardtitle">Status</p>
                                    <p className = "bodymobile">{per.status}</p>
                                    </div> 
                            </div>
                            <div className="row" style={{marginTop:20}}>
                                    <div className="col-xl-6 col-lg-6 col-md-3">
                                    <p className = "cardtitle">Start Date</p>
                                    <p className = "bodymobile">{per.startMonth} {per.startYear}</p>
                                    </div>   
                            </div>
                            <div style={{position:'relative',bottom:30,right:0}}>
                                <button onClick = { () => { setperferment(per); setshow(true); settype('edit') } } type ="submit" class="mdc-button w-10" style={{backgroundColor:'#FF7A00',color:'#fff',borderRadius:4,float:'right'}}>
                                <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                </button>
                                
                            </div> 
                           
                            </div>     
                        </div>
                        </div>
                    </div>
                    
                    )
                   }  
                    {
                        onboard_perferment != null && onboard_perferment.length == 0 &&
                        <div class="card mt-3">
                        
                            <div className = "" style={{textAlign:'center'}}>
                            
                            <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                                No Perferment Added Yet
                            </div>

                            </div>
                        </div>
                    } 
                    {
                        IsFetching == true &&
                        <div class="card mt-3">
                        
                            <div className = "" style={{textAlign:'center'}}>
                            
                            <div style={{textAlign:'center'}}>
                                <Loader
                                    visible={true}
                                    type="Puff"
                                    color="#7a00ff"
                                    height={30}
                                    width={30}
                                    timeout= {0} //3 secs
                                />
                            </div>

                            </div>
                        </div>
                    }
                 
                 </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    );
}


export default withRouter(OnboardPerferment);