import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Aside from './aside';
import { Modal } from 'react-bootstrap';
import { GetDioceses, EditDiocese } from '../redux/action';
import { IMAGEPATH } from '../redux/action/constant';



const Card3 = (props) => {
    const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
    const [IsSubmitting, setIsSubmitting ] = useState(false);
    const [ showdiocese, setshowdiocese ] = useState(false);
    const [ IsFetching, setIsFetching] = useState(false);
    const dioceses = useSelector(state => state.root.dioceses);
    const [ edit, setedit ] = useState(false);
    const [ diocese, setdiocese ] = useState(null);
    const [ file, setfile ] = useState(null);
    const user = useSelector(state => state.root.user_login);
    useEffect(() => {
      Process();
    },[]);

    const Process = async () => {
      setIsFetching(true);
      let provinceId = window.atob(props.id);
      //alert(provinceId);
      await dispatch(GetDioceses(provinceId));
      setIsFetching(false);
    }
    const onSubmit = async (data,e) => {
        setIsSubmitting(true);
        //await dispatch(CreateDocumentType(data,e));
        setIsSubmitting(false);
    }
    const handleChange = (event) => {
      setfile(event.target.files[0]);
   }

   const dioceseSubmit = async (data,e) => {
    setIsSubmitting(true);
    const formdata = new FormData();
    formdata.append('name',data.name);
    formdata.append('firstname', data.firstname);
    formdata.append('address', data.address);
    formdata.append('lastname',data.lastname);
    formdata.append('status', data.status);
    formdata.append('position',data.position);
    formdata.append('email1',data.email1);
    formdata.append('phonenumber1', data.phonenumber1);
    if(file != null) formdata.append('file', file);
    //console.log(file);
    await dispatch(EditDiocese(formdata,diocese.id, e));
    setIsSubmitting(false);
  }


    return (
        <div className="">
              <Modal show = {edit} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setedit(false)}>
                <Modal.Title style={{color:'#fff'}}>Edit Diocese</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(dioceseSubmit)}>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Diocese Name" 
                            defaultValue = { diocese != null && diocese.name }
                            name ="name"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.province_name?.type == "required" && "Province Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="First Name" 
                            defaultValue = { diocese != null && diocese.leader.firstname }
                            style={{marginBottom:20}}
                            name ="firstname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.firstname?.type == "required" && "First Name is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Last Name" 
                            defaultValue = { diocese != null && diocese.leader.lastname }
                            style={{marginBottom:20}}
                            name ="lastname"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.lastname?.type == "required" && "Last Name is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                             className="form-control"  
                             style={{marginBottom:20}}
                             defaultValue = { diocese != null && diocese.leader.position }
                             name ="position"
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Position</option>
                         <option value="Primate">Primate</option>
                         <option value="Archbishop">Archbishop</option>
                         <option value="Bishop">Bishop</option>
                         <option value="Provost">Provost</option>
                         <option value="Dean">Dean</option>
                         <option value="Vicar">Vicar</option>
                         <option value="Curate">Curate</option>
                         <option value="Deacon">Deacon</option>
                         <option value="Evangelist">Evangelist</option>
                         <option value="Catechist">Catechist</option>
                         <option value="Agent">Agent</option>
                        </select>     
                       
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                             className="form-control" 
                             placeholder ="Status" 
                             style={{marginBottom:20}}
                             defaultValue = { diocese != null && diocese.leader.status }
                             name ="status"
                             type ="text" 
                             ref={register({
                                 required: "Required"
                                 })}
                        >
                         <option value="">Status</option>
                         <option value="Most Revd">Most Revd</option>
                         <option value="Right Revd">Right Revd</option>
                         <option value="Very Revd">Very Revd</option>
                         <option value="Venerable">Venerable</option>
                         <option value="Revd Canon">Revd Canon</option>
                         <option value="Revd">Revd</option>
                         <option value="Evangelist">Evangelist</option>
                         <option value="Mr.">Mr.</option>
                        </select>    
                        {/* <input 
                           
                        /> */}
                            <small className="text-danger">{errors.position?.type == "required" && "Position is required"}</small>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Phone Number 1"
                            defaultValue = { diocese != null && diocese.leader.phonenumber1 }
                            style={{marginBottom:20}} 
                            name ="phonenumber1"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.phonenumber1?.type == "required" && "Phone Number is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Email 1" 
                            defaultValue = { diocese != null && diocese.leader.email1 }
                            style={{marginBottom:20}}
                            name ="email1"
                            type ="email" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            onChange={handleChange}
                            name ="file"
                            type ="file" 
                            ref={register({
                               
                                })}
                        />
                            <small className="text-primary">Image file</small><br/>
                            <small className="text-danger">{errors.email1?.type == "required" && "Email is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <textarea
                          className="form-control" 
                          placeholder ="Diocese Address" 
                          style={{marginBottom:20}}
                          name ="address"
                          rows="5"
                          ref={register({
                              required: "Required"
                              })}
                        >
                        {diocese != null && diocese.address }
                        </textarea>    
                      
                            <small className="text-danger">{errors.address?.type == "required" && "Address is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
          </Modal>  
              
             {
               dioceses != null && dioceses.map((diocese) => 
              <div class="card2 mt-3 tab-card">
                  <div class="card-header tab-card-header" style={{backgroundColor:'#fff',color:'#7A00FF',padding:4}}>
                          <div>
                          <a class="nav-link title">{diocese.name} Diocese</a>
                          
                          
                          </div>
                          
                  
                  </div>
                  <div class="desktop" id="">
                  <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                      <div className="padding">
                      <div className="row">
                          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-4">
                              <div className="" style={{width:'100%'}}>
                              {
                                diocese.leader.image == '' &&
                                <img class="card-img-top" style={{height:80,width:80,borderRadius:40}} src={require(`../asset/images/profile.jpg`)} alt="Card image cap"/>

                            }
                            {
                                diocese.leader.image != '' &&
                                <img class="card-img-top" style={{height:80,width:80,borderRadius:40}} src={`${IMAGEPATH}files/image400/${diocese.leader.image}`} alt="Card image cap"/>

                            }  
                              </div>
                          </div>
                          
                          <div className="col-xl-10 col-lg-10 col-md-3" style={{width:'55%'}}>
                          <div class="row">
                              <div className="col-xl-6 col-lg-6 col-md-3 col-sm-12">
                                <p className ="cardtitle">Name</p>
                                <p className ="body">{diocese.leader.firstname} {diocese.leader.lastname} {diocese.leader.middlename}</p>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-3 col-sm-6">
                                <p className ="cardtitle">Status</p>
                                <p class="body">{diocese.leader.status}</p>
                              </div> 
                              </div>
                              <div class="row displaymargin">
                            
                              <div className="col-xl-6 col-lg-6 col-md-3 col-sm-6">
                                <p className ="cardtitle">Position</p>
                                <p className ="body">{diocese.leader.position}</p>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-3 col-sm-12">
                                <p className ="cardtitle">Email</p>
                                <p className ="body">{diocese.leader.email1}</p>
                              </div>
                              
                              </div>  
                          </div>
                          
                      </div>
                      <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}> 
                        <div style={{}} className="actionbtn">
                        {
                          user.userType == 'superadmin' &&   
                          <button onClick = { () => { setedit(true); setdiocese(diocese) } } type ="submit" class="mdc-button w-10">
                          <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                          </button>
                        }  
                            
                        </div>
                        <div style={{}} className="actionbtn">
                            <Link to = {`/diocesedetails/${window.btoa(diocese.id)}`} type ="submit" class="mdc-button w-100 actionshow" style={{textDecoration:'none', border:'2px solid #7a00ff',padding:5}}>
                            <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">visibility</i> View
                            </Link>
                        
                        </div>
                        </div> 
                      <div class="" style={{width:200,marginTop:20,marginLeft:'auto',marginRight:'auto'}}>
                                     
                                  </div>
                      </div>     
                  </div>
                  </div>
                   <div class="mobile" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                                <div className="col-xl-1 col-lg-2 col-md-2 pixside">
                                    <div className="" style={{width:'100%'}}>
                                     {
                                         diocese.leader.image == '' &&
                                         <img class="card-img-top" style={{height:"100%",width:'100%'}} src={require(`../asset/images/profile.jpg`)} alt="Card image cap"/>

                                     }
                                     {
                                         diocese.leader.image != '' &&
                                         <img class="card-img-top" style={{height:"100%",width:'100%'}} src={`${IMAGEPATH}files/image400/${diocese.leader.image}`} alt="Card image cap"/>

                                     }   

                                    </div>
                                </div>
                               
                                <div className="col-xl-9 col-lg-9 col-md-3" style={{width:'55%'}}>
                                <div>
                                  <p class="cardtitle">{diocese.leader.firstname} {diocese.leader.lastname} </p>
                                  <p class="bodymobile">{diocese.leader.status} </p>
                                  <p class="bodymobile">{diocese.leader.position} </p>
                                </div>    
                               
                                </div>
                                <hr  />
                                <div style = {{display:'flex',flexDirection:"row", justifyContent:'flex-end', margin:7,width:'100%'}}>
                                    <div style={{}} className="btnlist">
                                    {
                                    user.userType == 'superadmin' &&   
                                    <button onClick = { () => { setedit(true); setdiocese(diocese) } } type ="submit" class="mdc-button w-10">
                                    <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                                    </button>
                                    }  
                                        
                                    </div>
                                    <div style={{}} className="btnlist">
                                    <Link to = {`/diocesedetails/${window.btoa(diocese.id)}`} type ="submit" class="mdc-button w-100 actionshow" style={{textDecoration:'none', border:'2px solid #7a00ff',padding:5}}>
                                        <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">visibility</i> View
                                    </Link>
                                            
                                    </div>
                                </div>  
                               
                            </div>
                            </div>     
                        </div>
                    </div>
              </div>
             )}
              {
                    dioceses != null && dioceses.length == 0 &&
                    <div class="card mt-3">
                    
                        <div className = "" style={{textAlign:'center'}}>
                        
                        <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                            No Diocese Added Yet
                        </div>

                        </div>
                    </div>
            }
        </div>
    );
}

export default Card3;