


import React, {useState, useEffect } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { CreatePriestParish, CreateProfile, CreateEducation, CreateParish, EditParish, EditPriestParish } from '../redux/action';
import { IMAGEPATH } from '../redux/action/constant';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { GetParish } from '../Message/getdata';


const EditSelectedParish = (props) => {

    const [ show, setshow ] = useState(false);
    const [ edit, setedit ] = useState(false);
    const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
    const [IsSubmitting, setIsSubmitting ] = useState(false);
    const { id } = useParams();
    const [ file, setfile ] = useState(null);
    const dioceanofficials = useSelector(state=>state.root.dioceanofficials);
    const month = useSelector(state => state.root.month);
    const year = useSelector(state => state.root.year);
    const [ parish, setparish ] = useState('');


    const parishSubmit = async (data,e) => {
        //alert('gate');
        setIsSubmitting(true);
        data['parishId'] = parish;
        let dioceseId = window.atob(id);
        await dispatch(EditPriestParish(data, props.parish.id));
        setIsSubmitting(false);
    }

    const loadOptions = async (inputValue, callback) => {
        //alert(inputValue);
        let r = await GetParish(inputValue);
        return r;
     }


    useEffect(() => {
       // alert(month[0]);
        // let start = 1950;
        // let allstart = [];
        // for(let r = 0; r < 100; r++)
        // {
        //     allstart.push(start+r);
        // }
        // setyear(allstart);
    },[])

    return (
        <div className="">
        <div style={{marginTop:30}}>
        <form onSubmit={handleSubmit(parishSubmit)}>
                   
                    <div className="row"  style={{marginBottom:20}}>
                        <div className="col-lg-6 col-sm-6">
                        <AsyncSelect   
                            cacheOptions
                            defaultOptions
                            placeholder="Choose Parish"
                            loadOptions = {loadOptions}
                            isClearable
                            onChange = {(opt) => setparish(opt==null ? '' : opt.value) }
                        />    
                        </div>
                        <div className="col-lg-6 col-sm-6">
                        <input 
                            className="form-control" 
                            placeholder ="Parish Name" 
                            defaultValue = {(props.parish != null && props.parish.name != null) ? props.parish.name :'' }
                            name ="name"
                            type ="text" 
                            ref={register({
                               
                                })}
                        />
                            <small className="text-danger">{errors.name?.type == "required" && "Required"}</small>
                        </div>
                    </div>
                    <div className="row"  style={{marginBottom:20}}>
                        <div className="col-lg-6 col-sm-6">
                        <select 
                          name = "startmonth"
                          defaultValue = { props.parish != null && props.parish.startmonth}
                          ref={register({
                            required: "Required"
                            })}
                          className="form-control">
                        <option value="">Select Start Month</option>
                        {
                            month != null && month.map((mon,x) =>
                            <option value={mon}>{mon}</option>
                            )
                        }
                        </select>
                            <small className="text-danger">{errors.startmonth?.type == "required" && "Required"}</small>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                        <select 
                          name = "startyear"
                          defaultValue = { props.parish != null && props.parish.startyear}
                          ref={register({
                            required: "Required"
                            })}
                          className="form-control">
                        <option value="">Select Start Year</option>
                        {
                            year != null && year.map((y) =>
                            <option value={y.id}>{y.id}</option>
                            )
                        }
                        </select>
                            <small className="text-danger">{errors.startyear?.type == "required" && "Year is required"}</small>
                        </div>
                    </div>

                    <div className="row"  style={{marginBottom:20}}>
                        <div className="col-lg-6 col-sm-6">
                        <select 
                          name = "endmonth"
                          defaultValue = {props.parish != null && props.parish.endmonth}
                          ref={register({
                           
                            })}
                          className="form-control">
                        <option value="">Select End Month</option>
                        {
                            month != null && month.map((mon,x) =>
                            <option value={mon}>{mon}</option>
                            )
                        }
                        </select>
                            <small className="text-danger">{errors.endmonth?.type == "required" && "Required"}</small>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                        <select 
                          name = "endyear"
                          defaultValue = {props.parish != null && props.parish.endyear}
                          ref={register({
                          
                            })}
                          className="form-control">
                        <option value="">Select End Year</option>
                        {
                            year != null && year.map((y) =>
                            <option value={y.id}>{y.id}</option>
                            )
                        }
                        </select>
                            <small className="text-danger">{errors.endyear?.type == "required" && "Required"}</small>
                        </div>
                    </div>
                    <div className="row"  style={{marginBottom:20}}>
                        <div className="col-lg-12 col-sm-12">
                        <select 
                          name = "IsCurrentParish"
                          ref={register({
                            required: "Required"
                            })}
                          className="form-control">
                        <option value="">Is Current Parish</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                        </select>
                            <small className="text-danger">{errors.IsCurrentParish?.type == "required" && "Is Current Parish is required"}</small>
                        </div>
                    </div>    
                    <div className="row" style={{marginTop:20}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                  </form>
                  
        </div>
     </div>
    );
}

export default EditSelectedParish;

