import React, {useState, useEffect} from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, useParams, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { CreateParish, GetParishes, EditSelectedParish, GetPriestsInParish } from '../redux/action';
import { IMAGEPATH } from '../redux/action/constant';
import SearchData from './search';


const Card6 = () => {
  const [ show, setshow ] = useState(false);
  const [ edit, setedit ] = useState(false);
  const { handleSubmit, errors, register, watch } = useForm();
  const dispatch = useDispatch();
  const [IsSubmitting, setIsSubmitting ] = useState(false);
  const [ viewPriests, setviewPriests ] = useState(false);
  const { id } = useParams();
  const archdeaconries  = useSelector(state => state.root.archdeaconries);
  const parishes = useSelector(state =>state.root.parishes);
  const [IsFetching, setIsFetching ] = useState(false);
  const [ parish, setparish ] = useState(null);
  const priestsInParish = useSelector(state => state.root.priestsInParish);
  const user = useSelector(state => state.root.user_login);
  const [ parishname, setparishname] = useState(null);

  const onSubmit = async (data,e) => {
    setIsSubmitting(true);
    let dioceseId = window.atob(id);
    await dispatch(CreateParish(data,data.archdeaconary_id,e));
    //await dispatch(CreateDocumentType(data,e));
    setIsSubmitting(false);
  }

  const updateSubmit = async (data,e) => {
    setIsSubmitting(true);
    console.log(data);
    await dispatch(EditSelectedParish(data,parish.id,e));
    setIsSubmitting(false);
  }

  useEffect(() => {
    process();
  },[]);

  const GetPriests = async (parish) => {
    setIsFetching(true);
    setparishname(parish.name);
    await dispatch(GetPriestsInParish(parish.id));
    setIsFetching(false);
  }

  const process = async () => {
      let dioceseId = window.atob(id);
      setIsFetching(true);
      await dispatch(GetParishes(dioceseId));
      setIsFetching(false);
  }

  const EditParish = () => {
      setedit(true);
  }
    return (
        <div className="">
        <Modal show = {show}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setshow(false)}>
                <Modal.Title style={{color:'#fff'}}>Create Parish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Parish Name" 
                            name ="name"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.name?.type == "required" && "Archdeaconry Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Address" 
                            style={{marginBottom:20}}
                            name ="address"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.address?.type == "required" && "Address is required"}</small>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                            style={{marginBottom:20}}
                            name="archdeaconary_id" 
                            ref={register({
                                required: "Required"
                                })}
                            class="form-control">
                                <option value="">Select Archdeaconry</option>
                                {
                                    archdeaconries != null && archdeaconries.map((arch) =>
                                    <option value={arch.id}>{arch.name}</option>
                                )}
                        </select>    
                            
                            <small className="text-danger">{errors.archdeaconary_id?.type == "required" && "Archdeaconary Name is required"}</small>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                            style={{marginBottom:20}}
                            name="Is_archdeaconary_headquarter" 
                            ref={register({
                                required: "Required"
                                })}
                            class="form-control">
                                <option value="">Is Archdeaconry Headquarter</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                        </select>    
                            
                            <small className="text-danger">{errors.archdeaconary_id?.type == "required" && "Archdeaconary Name is required"}</small>
                        </div>
                    </div>
                    
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
        </Modal>
        <Modal show = { parish != null ? true : false}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setparish(null)}>
                <Modal.Title style={{color:'#fff'}}>Edit Parish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <form onSubmit={handleSubmit(updateSubmit)}>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            style={{marginBottom:20}}
                            placeholder ="Parish Name" 
                            defaultValue = {parish != null && parish.name}
                            name ="name"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.name?.type == "required" && "Archdeaconry Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <input 
                            className="form-control" 
                            placeholder ="Address" 
                            defaultValue = {parish != null && parish.address}
                            style={{marginBottom:20}}
                            name ="address"
                            type ="text" 
                            ref={register({
                                required: "Required"
                                })}
                        />
                            <small className="text-danger">{errors.address?.type == "required" && "Address is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                            style={{marginBottom:20}}
                            defaultValue = { parish != null && parish.archdeaconary_id}
                            name="archdeaconary_id" 
                            ref={register({
                                required: "Required"
                                })}
                            class="form-control">
                                <option value="">Select Archdeaconry</option>
                                {
                                    archdeaconries != null && archdeaconries.map((arch) =>
                                    <option value={arch.id}>{arch.name}</option>
                                )}
                        </select>      
                        
                            <small className="text-danger">{errors.archdeaconary_id?.type == "required" && "Archdeaconary Name is required"}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                        <select 
                            style={{marginBottom:20}}
                            name="Is_archdeaconary_sit" 
                            ref={register({
                                required: "Required"
                                })}
                            defaultValue = { parish != null && parish.Is_archdeaconary_sit}
                            class="form-control">
                                <option value="">Is Archdeaconry Headquarter</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                        </select>    
                            
                            <small className="text-danger">{errors.archdeaconary_id?.type == "required" && "Archdeaconary Name is required"}</small>
                        </div>
                    </div>
                  
                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </form>
                </div>
                </Modal.Body>
        </Modal>   
        <Modal show = {viewPriests} size="lg">
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setviewPriests(false)}>
                    <Modal.Title style={{color:'#fff'}}>Priests in {parishname != null && parishname}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="" style={{marginTop:40}}>
                    {
                        IsFetching == false && priestsInParish != null && priestsInParish.map((priest) => 
                        <div class="card12 mt-3 tab-card">
                
                        <div class="" id="">
            
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                        
                            <div style={{padding:30}}>
                            
                                    <div className="row">
                                    <div className="col-xl-2 col-lg-1 col-md-3 col-sm-2">
                                    {
                                        (priest.image == '' || priest.image == null) &&
                                        <img class="card-img-top" style={{height:80,width:80}} src={require(`../asset/images/profile.jpg`)}/>

                                    }
                                    {
                                        priest.image != '' && priest.image != null &&
                                        <img class="card-img-top" style={{height:80,width:80}} src={`${IMAGEPATH}files/image400/${priest.image}`} />

                                    } 
                                    </div>
                                    <div className="col-xl-9 col-lg-9 col-md-3 col-sm-10" style={{marginLeft:-30}}>
                                    <div class="">
                                        <p style={{marginBottom:5,fontSize:16,fontWeight:500}} class="">{priest.firstname} {priest.lastname}</p>
                                        <p style={{marginBottom:5,fontSize:13}} class="">{priest.status}</p>
                                        <p style={{marginBottom:5,fontSize:13}} class="">{priest.position} </p>
                                        </div>
                                    </div>
                                    </div>
                                
                            
                            </div> 
                            
                            
                        
                        </div>
                        
                        </div>
                        </div>
                        
                    )
                    }
                    {
                        IsFetching == false && priestsInParish != null && priestsInParish.length == 0 &&
                        <div class="card mt-3">
                        
                            <div className = "" style={{textAlign:'center'}}>
                            
                            <div class="" style={{width:'100%',marginLeft:'auto',marginRight:'auto'}}>
                                No Parish Found
                            </div>

                            </div>
                        </div>
                    } 
                    {
                        IsFetching == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                    }
                 </div>
                </Modal.Body>
          </Modal>  
        <div class="header_btn desktop">
                    <div>
                    {
                    (user.userType == 'superadmin' || (user.userType == 'admin' && user.dioceseId == window.atob(id)) ) && 
                    <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                    <i class="mdc-drawer-arrow material-icons">add</i> Create Parish
                    </button>
                    }
                    </div>
                    <SearchData page = 'Parish' id = {id} />
                    
        </div>
        <div className="mobile">
        <div class="header_btn mobile">
            <div>
            {
                (user.userType == 'superadmin' || (user.userType == 'admin' && user.dioceseId == window.atob(id)) ) && 
                <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                <i class="mdc-drawer-arrow material-icons">add</i> Create Parish
                </button>
            }
            </div>
            <SearchData page = 'Parish' id = {id} />
        </div>
        </div>
                               
                              
       
        {

        parishes != null && parishes.map((parish) =>
        <div class="card2 mt-3 tab-card">
            
            <div class="" id="">
            
            <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
            
                <div style={{padding:30}}>
                
                <div class="row">
                    <div className="col-xl-12 col-lg-12 col-md-12" style={{marginTop:0}}>
                        <p  class="cardtitle">Name</p>
                        <p class ="body desktop">{parish.name}</p>
                        <p className="bodymobile mobile">{parish.name}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-3" style={{marginTop:10}}>
                        <p class="cardtitle">Address</p>
                        <p class="body desktop">{parish.address}</p>
                        <p class="bodymobile mobile">{parish.address}</p>
                    </div>
                </div>
                       
                   
                </div>
                <div style = {{display:'flex',flexDirection:"row", justifyContent:'flex-end', margin:7,marginTop:-50}}>
                    <div style={{}} className="btnlist">
                    {
                        (user.userType == 'superadmin' || (user.userType == 'admin' && user.dioceseId == window.atob(id)) ) && 
                        <button onClick = { () => setparish(parish) } type ="submit" class="mdc-button w-10 actionshow" style={{marginRight:10}}>
                        <i style={{marginRight:10}} class="mdc-drawer-arrow material-icons">edit</i> Edit
                        </button>
                    } 
                        
                    </div>
                    <div style={{}} className ="btnlist">
                    <Link onClick = { () => { setviewPriests(true); GetPriests(parish) } } class="mdc-button w-10 actionshow" style={{textDecoration:'none', border:'2px solid #7a00ff',padding:5}}>
                    <i style={{marginRight:5}} class="mdc-drawer-arrow material-icons">visibility</i> View
                    </Link>
                    
                    </div>
                </div>  
                  
            </div>
            
            </div>
        </div>
        )}
         {
                    IsFetching == false && parishes != null && parishes.length == 0 &&
                    <div class="card mt-3">
                    
                        <div className = "" style={{textAlign:'center'}}>
                        
                        <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                            No Parish Added Yet
                        </div>

                        </div>
                </div>
            }  

        {
            IsFetching == true &&
                <div style={{textAlign:'center',marginTop:50}}>
                <Loader
                visible={true}
                type="Puff"
                color="#7a00ff"
                height={30}
                width={30}
                timeout= {0} //3 secs
            
                />
                </div>
        }
     </div>
    );
}

export default Card6;