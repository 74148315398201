import React, {useState, useEffect} from 'react';


const Footer = () => {
   const date = new Date();
   const year = date.getFullYear();
    return (
        <footer>
        <div class="mdc-layout-grid">
          <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-6-desktop">
              <span class="tx-14">Copyright © {year} Church of Nigeria. All rights reserved.</span>
            </div>
            <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-6-desktop d-flex justify-content-end">
              <div class="d-flex align-items-center">
               
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
}

export default Footer;