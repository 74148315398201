import React, {useState, useEffect } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { CreateDioceseOfficial, CreateProfile, EditProfile, GetDioceseOfficial } from '../redux/action';
import { IMAGEPATH } from '../redux/action/constant';
import onboard_information from './onboard_information';
import AsyncSelect from 'react-select/async';
import { GetParish } from '../Message/getdata';


const CreatePriest = (props) => {

    const [ show, setshow ] = useState(false);
    const [ edit, setedit ] = useState(false);
    const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
    const [IsSubmitting, setIsSubmitting ] = useState(false);
    const { id } = useParams();
    const [ file, setfile ] = useState(null);
    const dioceanofficials = useSelector(state=>state.root.dioceanofficials);
    const [ IsMarried, setIsMarried ] = useState(false);
    const user_onboard_login = useSelector(state => state.root.user_onboard_login);
    const onboarddiocese = useSelector(state => state.root.onboard_diocese);
    const thispriest = useSelector(state=>state.root.onboard_information);
    const [ parish, setparish ] = useState(null);


    const handleStatus = (event) => {
        setIsMarried(event.target.value);
    }
    const onSubmit = async (data,e) => {
        //alert('gate');
        
        if(IsMarried == "Married" && (data.name_of_spouse == '' || data.qualification_of_spouse == ''))
        {
            alert('Spouse Information is Required');
            return false;
        }

        if(data.date_married == '')
        {
            alert('Date Married is Required');
            return false;
        }

        setIsSubmitting(true);
        const formdata = new FormData();
        formdata.append('firstname',data.firstname);
        formdata.append('lastname',data.lastname);
        formdata.append('middlename', data.middlename);
        formdata.append('email1',data.email1);
        formdata.append('email2', data.email2);
        formdata.append('phonenumber1', data.phonenumber1);
        formdata.append('phonenumber2', data.phonenumber2);
        formdata.append('date_priested', data.date_priested);
        formdata.append('date_deaconed', data.date_deaconed);
        formdata.append('position', data.position);
        formdata.append('status', data.status);
        formdata.append('date_of_birth', data.date_of_birth);
        formdata.append('name_of_spouse', data.name_of_spouse == undefined ? '' : data.name_of_spouse);
        formdata.append('qualification_of_spouse', data.qualification_of_spouse == undefined ? '' : data.qualification_of_spouse);
        if(data.date_married != null) formdata.append('date_married', data.date_married);
        formdata.append('gender', data.gender);
        if(parish != null) formdata.append('parishId', parish);
        formdata.append('marital_status', data.marital_status);
        formdata.append('date_commissioned', data.date_commissioned);
        //formdata.append('archdeaconry_id', data.archdeaconry_id);
        if(file != null) formdata.append('image', file != null ? file : '');
        if(user_onboard_login != null && thispriest == null)
            await dispatch(CreateProfile(formdata,e, onboarddiocese.id));
        else if(user_onboard_login != null && thispriest != null)
            await dispatch(EditProfile(formdata,thispriest.id));  
        else{
            let dioceseId = window.atob(id);
            await dispatch(CreateProfile(formdata,e, dioceseId));
        }
          
        setIsSubmitting(false);
    }

    const loadOptions = async (inputValue, callback) => {
        let r = await GetParish(inputValue);
        return r;
     }

    const handleFile = (event) => {
        setfile(event.target.files[0]);
    }

    useEffect(() => {
 
    },[])

    return (
        <div className="">
         
        
        <div style={{marginTop:30}}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row" style={{marginBottom:10}}>
                <div className="col-lg-6 col-sm-6">  
                <small className="text-secondary" style={{fontSize:14}}>First name</small>  
                <input 
                    className="form-control"
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.firstname : '' }
                    name ="firstname"
                    type ="text" 
                    ref={register({
                        required: "Required"
                        })}
                />
                    <small className="text-danger" style={{fontSize:13}}>{errors.firstname?.type == "required" && "First Name is required"}</small>
                </div>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Last name</small>     
                <input 
                    className="form-control" 
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.lastname : '' }
                    name ="lastname"
                    type ="text" 
                    ref={register({
                        required: "Required"
                        })}
                />
                    <small className="text-danger" style={{fontSize:13}}>{errors.lastname?.type == "required" && "Last Name is required"}</small>
                </div>
            </div>
            <div className="row"    style={{marginBottom:10}}>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Middlename</small>     
                <input 
                    className="form-control" 
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.middlename : '' }
                    
                    name ="middlename"
                    type ="text" 
                    ref={register({
                       
                        })}
                />
                    <small className="text-danger" style={{fontSize:13}}>{errors.middlename?.type == "required" && "Middle Name is required"}</small>
                </div>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Phone Number 1</small>     
                <input 
                    className="form-control" 
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.phonenumber1 : '' }
                    name ="phonenumber1"
                    type ="number" 
                    ref={register({
                        required: "Required"
                        })}
                />
                    <small className="text-danger" style={{fontSize:13}}>{errors.phonenumber1?.type == "required" && "Phone Number 1 is required"}</small>
                </div>
            </div>
            <div className="row"    style={{marginBottom:10}}>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Phone Number 2</small>     
                <input 
                    className="form-control" 
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.phonenumber2 : '' }
                    name ="phonenumber2"
                    type ="number" 
                    ref={register({
                        
                        })}
                />
                    <small className="text-danger" style={{fontSize:13}}>{errors.phonenumber2?.type == "required" && "Phone NUmber 2 is required"}</small>
                </div>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Email 1</small>     
                <input 
                    className="form-control" 
                    
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.email1 : '' }
                    name ="email1"
                    type ="email" 
                    ref={register({
                        required: "Required"
                        })}
                />
                    <small className="text-danger" style={{fontSize:13}}>{errors.email1?.type == "required" && "Email is required"}</small>
                </div>
            </div>

            <div className="row"    style={{marginBottom:10}}>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Email 2</small>     
                <input 
                    className="form-control" 
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.email2 : '' }
                    name ="email2"
                    type ="email" 
                    ref={register({
                    
                        })}
                />
                    <small className="text-danger" style={{fontSize:13}}>{errors.email2?.type == "required" && "Email 2 is required"}</small>
                </div>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Status</small>     
                <select name ="status"
                        name = "status"
                        defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.status : '' }
                        className="form-control" 
                        ref={register({
                        required: "Required"
                        })}
                >
                    <option value="">Status</option>
                    <option value="Most Revd">Most Revd</option>
                    <option value="Right Revd">Right Revd</option>
                    <option value="Very Revd">Very Revd</option>
                    <option value="Venerable">Venerable</option>
                    <option value="Revd Canon">Revd Canon</option>
                    <option value="Revd">Revd</option>
                    <option value="Evangelist">Evangelist</option>
                    <option value="Mr.">Mr.</option>
                </select>
                    <small className="text-danger" style={{fontSize:13}}>{errors.status?.type == "required" && "Status is required"}</small>
                </div>
            </div>

            <div className="row"    style={{marginBottom:10}}>
                
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Date Of Birth</small>     
                <input 
                    className="form-control" 
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.date_of_birth : '' }
                    name ="date_of_birth"
                    type ="date" 
                    ref={register({
                        required: "Required"
                        })}
                />
                    <small className="text-danger">{errors.file?.type == "required" && "Image is required"}</small>
                </div>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Date Deaconed</small>     
                <input 
                    className="form-control" 
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.date_deaconed : '' }
                    name ="date_deaconed"
                    type ="date" 
                    ref={register({
                        
                        })}
                />
                    <small className="text-danger" style={{fontSize:13}}>{errors.file?.type == "required" && "Image is required"}</small>
                </div>
            </div>
            <div className="row"    style={{marginBottom:10}}>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Date Priested</small>    
                <input 
                    className="form-control"                            
                    name ="date_priested"
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.date_priested : '' }
                    type ="date" 
                    ref={register({
                    
                        })}
                />
                    <small className="text-danger" style={{fontSize:13}}>{errors.file?.type == "required" && "Image is required"}</small>
                </div>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Gender</small>     
                <select 
                className="form-control" 
                defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.gender : '' }
                name ="gender"
                ref={register({
                    })}
                >
                    <option value=""></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </select>    
                <small className="text-danger" style={{fontSize:13}}>{errors.gender?.type == "required" && "Gender is required"}</small>
                </div>
            
                
            
            </div>
            <div className="row"    style={{marginBottom:10}}>
                <div className="col-lg-12 col-sm-12">
                <small className="text-secondary" style={{fontSize:14}}>Date Commissioned</small>    
                <input 
                    className="form-control"                            
                    name ="date_commissioned"
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.date_commissioned : '' }
                    type ="date" 
                    ref={register({
                    
                        })}
                />
                </div>
            </div>
            <div className="row"    style={{marginBottom:10}}>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Position</small>     
                <select
                        name ="position"
                        className="form-control" 
                        defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.position : '' }
                        ref={register({
                        required: "Required"
                        })}
                >
                    <option value="">Position</option>
                    <option value="Primate">Primate</option>
                    <option value="Archbishop">Archbishop</option>
                    <option value="Bishop">Bishop</option>
                    <option value="Provost">Provost</option>
                    <option value="Dean">Dean</option>
                    <option value="Vicar">Vicar</option>
                    <option value="Curate">Curate</option>
                    <option value="Deacon">Deacon</option>
                    <option value="Evangelist">Evangelist</option>
                    <option value="Catechist">Catechist</option>
                    <option value="Agent">Agent</option>
                </select>
                    <small className="text-danger" style={{fontSize:13}}>{errors.position?.type == "required" && "Position is required"}</small>
                </div>
                
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Marital Status</small>     
                <select 
                className="form-control" 
                defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.marital_status : '' }
                name ="marital_status"
                onChange = {handleStatus}
                ref={register({
                    required: "Required"
                    })}
                >
                    <option value=""></option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                </select>    
                <small className="text-danger" style={{fontSize:13}}>{errors.marital_status?.type == "required" && "Marital Status is required"}</small>
                </div>
                
            </div>
          
           
            {
                IsMarried == "Married" &&
            <div className="row"    style={{marginBottom:10}}>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Name Of Spouse</small>    
                <input 
                    className="form-control"                            
                    name ="name_of_spouse"
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.name_of_spouse : '' }
                    type ="text" 
                    ref={register({
                    
                        })}
                />
                </div>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Qualification Of Spouse</small>     
                <input 
                    className="form-control"                            
                    name ="qualification_of_spouse"
                    defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.qualification_of_spouse : '' }
                    type ="text" 
                    ref={register({
                    
                        })}
                />   
                </div>
            
            </div>
            }
            {
                IsMarried == "Married" &&
                <div className="row"  style={{marginBottom:10}}>
                    <div className="col-lg-6 col-sm-6">
                    <small className="text-secondary" style={{fontSize:14}}>Date Married</small>    
                    <input 
                        className="form-control"                            
                        name ="date_married"
                        defaultValue = { (user_onboard_login != null && thispriest != null) ? thispriest.date_married : null }
                        type ="date" 
                        ref={register({
                        
                            })}
                    />
                    </div>
                </div>  
            }  
            <div className="row"  style={{marginBottom:10}}>    
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Priest Image</small>     
                <input 
                    className="form-control" 
                    placeholder ="Image" 
                    onChange = { handleFile }
                    type ="file" 
                    ref={register({
                    
                        })}
                />   
                <small className="text-danger" style={{fontSize:13}}>{errors.file?.type == "required" && "Image is required"}</small>
                </div>
                <div className="col-lg-6 col-sm-6">
                <small className="text-secondary" style={{fontSize:14}}>Parish Name (search for Parish)</small>     
                 <AsyncSelect   
                        cacheOptions
                        defaultOptions = { props.type == 'edit' ? [ { name : props.parish.name, id : props.parish.id }] : '' }
                        placeholder="Choose Parish"
                        loadOptions = {loadOptions}
                        isClearable
                        onChange = {(opt) => setparish(opt==null ? null : opt.value) }
                    />  
                </div>
            
            </div>
            <div className="row" style={{marginTop:10}}>
                <div className="col-lg-4 col-sm-4">
                <div style={{marginTop:0}} class="">
                {
                IsSubmitting == false &&
                <button type ="submit" class="mdc-button mdc-button--raised">
                    Submit
                </button>
                }
                {
                IsSubmitting == true &&
                <div style={{textAlign:'center'}}>
                <Loader
                    visible={true}
                    type="Puff"
                    color="#7a00ff"
                    height={30}
                    width={30}
                    timeout= {0} //3 secs
                />
                </div>
                }
            </div>
                </div>
                
            </div>
        </form>          
        </div>
     </div>
    );
}

export default CreatePriest;