import React, {useState, useEffect } from 'react';
import {  useSelector, useDispatch} from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Refresh } from '../redux/action/index';



const Header = (props) => {
  const user = useSelector(state => state.root.user_login);
  const onboardinguser = useSelector(state => state.root.user_onboard_login);
  const dispatch = useDispatch();
  const logout = async () => {
    await dispatch(Refresh());
    window.location.href ='/';
  }
  useEffect(() =>{
    //alert(window.innerWidth);
    //alert(JSON.stringify(user));
  },[])
    return (
       <div>
        <header style={{backgroundColor:'red'}}>
         <div className="" style={{backgroundColor:'red'}}>
		
         <Navbar bg="light" expand="lg">
          <Navbar.Brand href="#home">
            <h5 style={{color:'#4e5559'}} className ="desktop">CHURCH OF NIGERIA</h5>
            <h5 style={{color:'#4e5559'}} className ="mobile">CHURCH OF NIGERIA</h5>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
           
            <Nav className="mr-auto" className="justify-content-end">
            
              <Nav.Link href="#home">
              <Link  style={{display:'flex',flexDirection:'row', textDecoration:'none',color:'#4e5559',fontSize:19}}>
              { user != null &&  <span className='' style={{marginTop:12}}>Hi { Object.keys(user).length > 0 ? user.name : ''} </span> }
              { onboardinguser != null &&  <span className='' style={{marginTop:12}}>Hi { Object.keys(onboardinguser).length > 0 ? onboardinguser.name : ''} </span> }
              </Link>
             
              </Nav.Link>
              <Nav.Link href="#link">
                <div className ="">
                   <img className="" src={require(`../asset/images/profile.jpg`)} style={{width:50,height:50,borderRadius:25}} alt="user" class="user"/>
                  
                </div>
              </Nav.Link>
              <Nav.Link>
              <Link className="mdc-drawer-link mobile" to="/home">
                Dashboard
              </Link>
              </Nav.Link>
              <Nav.Link>
              <Link className="mdc-expansion-panel-link mobile" to = "/provinces" data-toggle="expansionPanel" data-target="product">
                Provinces
               
              </Link>
              </Nav.Link>
              <Nav.Link>
              <Link className="mdc-expansion-panel-link mobile" to = "/move" data-toggle="expansionPanel" data-target="product">
                Move Data
               
              </Link>
              </Nav.Link>
              <Nav.Link>
              <Link className="mdc-expansion-panel-link mobile" to = "/report" data-toggle="expansionPanel" data-target="product">
                Report
               
              </Link>
              </Nav.Link>
              <Nav.Link>
              <Link className="mdc-expansion-panel-link mobile" to = "/users" data-toggle="expansionPanel" data-target="product">
                Users
               
              </Link>
              </Nav.Link>
              <Nav.Link>
              <Link className="mdc-expansion-panel-link mobile" to = "/onboard_settings" data-toggle="expansionPanel" data-target="product">
                Onboarding
               
              </Link>
              </Nav.Link>
              <Nav.Link>
              <Link onClick = {logout} style={{display:'flex',flexDirection:'row', textDecoration:'none'}}>
                        <span style={{marginTop:12}}>Log Out</span>
                        <span className = 'mobile'> <i style={{marginTop:16,marginRight:10}} class="mdc-drawer-arrow material-icons">chevron_right</i></span>

                        <span className = 'desktop'> <i style={{marginTop:10,marginRight:10}} class="mdc-drawer-arrow material-icons">chevron_right</i></span>
              </Link>
              </Nav.Link>
              
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        </div>
      </header>
      <header class="main_menu2">
         <div className="main_menu">
         <div class="mdc-top-app-bar__row">
          <div class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
            {/* <button class="material-icons mdc-top-app-bar__navigation-icon mdc-icon-button sidebar-toggler">menu</button> */}
             <span class="mdc-top-app-bar__title">Anthos Autos</span>
            <div class="mdc-text-field mdc-text-field--outlined mdc-text-field--with-leading-icon search-text-field d-none d-md-flex">
              <i class="material-icons mdc-text-field__icon">search</i>
              <input class="mdc-text-field__input" id="text-field-hero-input"/>
              <div class="mdc-notched-outline">
                <div class="mdc-notched-outline__leading"></div>
                <div class="mdc-notched-outline__notch">
                  <label for="text-field-hero-input" class="mdc-floating-label">Search..</label>
                </div>
                <div class="mdc-notched-outline__trailing"></div>
              </div>
            </div>
          </div>
          <div class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end mdc-top-app-bar__section-right">
            <div class="menu-button-container menu-profile d-none d-md-block">
              <button class="mdc-button mdc-menu-button">
                <span class="d-flex align-items-center">
                  <span class="figure">
                    <img src={require('../asset/images/profile.jpg')} alt="user" class="user"/>
                  </span>
                  <span class="user-name">{user.firstname} {user.lastname}</span>
                </span>
              </button>
              <div class="mdc-menu mdc-menu-surface" tabindex="-1">
                
              </div>
            </div>
            <div class="divider d-none d-md-block"></div>
            <div class="menu-button-container d-none d-md-block">
             
            </div>
            <div class="menu-button-container">
             
            </div>
            <div class="menu-button-container">
              
            </div>
            <div class="menu-button-container d-none d-md-block">
             
                <Link to ="/" style={{textDecoration  :'none', color:'#000'}}>Log Out</Link>
             
              
            </div>
          </div>
         </div>
        </div>

      </header>
      </div>
    );
}

export default Header;