import React, {useEffect, useState} from 'react';
import Login from './Component/login';
import Register from './Component/Register';
import Home from './Component/Home';
import {useSelector, useDispatch} from 'react-redux';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
  Redirect,
  useParams,
  Link
} from "react-router-dom";
import { store } from './redux/store/index';
import { persistor } from './redux/store/index';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Provinces from './Component/provinces';
import ProvinceDetails from './Component/provincedetails';
import DioceseDetails from './Component/diocesedetails';
import Users from './Component/users';
import Priest from './Component/priest';
//import Move from './Component/priest';
import OnboardLogin from './Component/onboarding_login';
import OnboardRegister from './Component/onboarding_register';
import OnboardPersonalInformation from './Component/onboard_information';
import OnboardEducation from './Component/onboard_education';
import OnboardPerferment from './Component/onboard_perferment';
import OnboardParish from './Component/onboard_parish';
import Move from './Component/move';
import OnboardSettings from './Component/onboard_settings';
import Scroll from './Component/scroll';

function PrivateRoute({ children, ...rest }) {
  const IsLoggedIn = useSelector(state => state.root.IsLoggedIn);
  //alert(IsLoggedIn);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        IsLoggedIn == true ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}



function App() {
  return (
    <Provider store = {store}>
      <PersistGate loading={null} persistor = {persistor}>  
      <Router>
          <Scroll />
          <Switch>
            <Route path="/" exact>
              <Login />
            </Route>
            <Route path="/onboard" exact>
              <OnboardPersonalInformation />
            </Route>
            <Route path="/move" exact>
              <Move />
            </Route>
            <Route path="/onboard_priest_education" exact>
              <OnboardEducation />
            </Route>
            <Route path="/onboard_priest_parish" exact>
              <OnboardParish />
            </Route>
            <Route path="/onboard_priest_perferment" exact>
              <OnboardPerferment />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/onboard_login" exact>
              <OnboardLogin />
            </Route>
            <Route path="/onboard_register">
              <OnboardRegister />
            </Route>
            <PrivateRoute path="/users">
              <Users />
            </PrivateRoute>
            <PrivateRoute path="/onboard_settings">
              <OnboardSettings />
            </PrivateRoute>
            <PrivateRoute path="/home">
              <Home />
            </PrivateRoute>
            <PrivateRoute path="/move">
              <Move />
            </PrivateRoute>
            <PrivateRoute path="/provinces">
              <Provinces />
            </PrivateRoute>
            <PrivateRoute path="/provincedetails/:id">
              <ProvinceDetails />
            </PrivateRoute>    
            <PrivateRoute path="/diocesedetails/:id">
              <DioceseDetails />
            </PrivateRoute> 
            <PrivateRoute path="/priest/:id">
              <Priest />
            </PrivateRoute>          
          </Switch>
      </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
