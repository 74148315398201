import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Aside from './aside';
import { Modal } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { Move_archdeaconary_to_another_diocese, Move_diocese_to_another_province, Move_parish_to_archdeaconry } from '../redux/action';
import { GetProvince, GetDiocese, GetArchdeaconry, GetParish } from '../Message/getdata';


const Moves = (props) => {
	const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
  const [IsSubmitting, setIsSubmitting ] = useState(false);
  const [ show, setshow ] = useState(false);
  const [ movediocese, setmovediocese ] = useState(false);
  const [ movearch, setmovearch ] = useState(false);
  const [ moveparish, setmoveparish ] = useState(false);
  const [ diocese, setdiocese ] = useState('');
  const [ province, setprovince ] = useState('');
  const [ thisparish, setthisparish ] = useState('');
  const [ thisarchdeaconry, setthisarchdeaconry ] = useState('');

  const onSubmit = async (data,e) => {
    setIsSubmitting(true);
    //await dispatch(CreateDocumentType(data,e));
    setIsSubmitting(false);
  }

  const dioceseSubmit = async (data,e) => {
    setIsSubmitting(true);
    //await dispatch(CreateDocumentType(data,e));
    setIsSubmitting(false);
  }
  const DioceseOptions = async (inputValue, callback) => {
    let r = await GetDiocese(inputValue);
    return r;
  }

  const ProvinceOptions = async (inputValue, callback) => {
    let r = await GetProvince(inputValue);
    return r;
  }

  const ArchdeaconryOptions = async (inputValue, callback) => {
    let r = await GetArchdeaconry(inputValue);
    return r;
  }

  const ParishOptions = async (inputValue, callback) => {
    let r = await GetParish(inputValue);
    return r;
  }

  const MoveDiocese  = async () => {
      if(diocese == '')
      {
          alert('Select Diocese');
          return false;
      }
      if(province == '')
      {
          alert("Select Province")
          return false;
      }
      setIsSubmitting(true);
      let data = {};
      data['dioceseId'] = diocese;
      data['toId'] = province;
      //alert(JSON.stringify(data));
      await dispatch(Move_diocese_to_another_province(data));
      setIsSubmitting(false);
  }

  const MoveArch  = async () => {
    if(thisarchdeaconry == '')
    {
        alert('Select Archdeaconry');
        return false;
    }
    if(diocese == '')
    {
        alert("Select Diocese")
        return false;
    }
    setIsSubmitting(true);
    let data = {};
    data['archId'] = thisarchdeaconry;
    data['toId'] = diocese;
    //alert(JSON.stringify(data));
    await dispatch(Move_archdeaconary_to_another_diocese(data));
    setIsSubmitting(false);
}

const MoveParish  = async () => {
    if(thisparish == '')
    {
        alert('Select Parish');
        return false;
    }
    if(thisarchdeaconry == '')
    {
        alert("Select Archdeaconry")
        return false;
    }
    setIsSubmitting(true);
    let data = {};
    data['parishId'] = thisparish;
    data['toId'] = thisarchdeaconry;
    //alert(JSON.stringify(data));
    await dispatch(Move_parish_to_archdeaconry(data));
    setIsSubmitting(false);
}
	
    return (
        <div class="body-wrapper">
          <Modal show = {movediocese} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setmovediocese(false)}>
                <Modal.Title style={{color:'#fff'}}>Move Diocese</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <div>
                    <div className="row" style={{marginBottom:30}}>
                        <div className="col-lg-12 col-sm-12">
                        <AsyncSelect   
                            cacheOptions
                            defaultOptions
                            placeholder="Choose Diocese"
                            loadOptions = {DioceseOptions}
                            isClearable
                            onChange = {(opt) => setdiocese(opt==null ? '' : opt.value) }
                        />    
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:30}}>
                        <div className="col-lg-12 col-sm-12">
                        <AsyncSelect   
                            cacheOptions
                            defaultOptions
                            placeholder="Choose Province"
                            loadOptions = {ProvinceOptions}
                            isClearable
                            onChange = {(opt) => setprovince(opt==null ? '' : opt.value) }
                        />    
                            <small className="text-danger">{errors.toId?.type == "required" && "Province Name is required"}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button onClick = { MoveDiocese } type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </div>
                </div>
                </Modal.Body>
          </Modal>
          <Modal show = {movearch} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setmovearch(false)}>
                <Modal.Title style={{color:'#fff'}}>Move Archdeaconry to Another Diocese</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <div>
                    <div className="row" style={{marginBottom:30}}>
                        <div className="col-lg-12 col-sm-12">
                        <AsyncSelect   
                            cacheOptions
                            defaultOptions
                            placeholder="Choose Archdeaconry"
                            loadOptions = {ArchdeaconryOptions}
                            isClearable
                            onChange = {(opt) => setthisarchdeaconry(opt==null ? '' : opt.value) }
                        />       
                            <small className="text-danger">{errors.dioceseId?.type == "required" && "Archdeaconry Name is required"}</small>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:30}}>
                        <div className="col-lg-12 col-sm-12">
                        <AsyncSelect   
                            cacheOptions
                            defaultOptions
                            placeholder="Choose Diocese"
                            loadOptions = {DioceseOptions}
                            isClearable
                            onChange = {(opt) => setdiocese(opt==null ? '' : opt.value) }
                        />  
                            <small className="text-danger">{errors.toId?.type == "required" && "Province Name is required"}</small>
                        </div>
                    </div>

                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button onClick = { MoveArch } type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </div>
                </div>
                </Modal.Body>
          </Modal>
          <Modal show = {moveparish} Close = {props.close}>
                <Modal.Header style={{backgroundColor:'#7a00ff'}} closeButton onClick={() => setmoveparish(false)}>
                <Modal.Title style={{color:'#fff'}}>Move Parish to Another Archdeaconary</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{marginTop:30}}>
                <div>
                    <div className="row" style={{marginBottom:30}}>
                        <div className="col-lg-12 col-sm-12">
                        <AsyncSelect   
                            cacheOptions
                            defaultOptions
                            placeholder="Choose Parish"
                            loadOptions = {ParishOptions}
                            isClearable
                            onChange = {(opt) => setthisparish(opt==null ? '' : opt.value) }
                        />       
                        <small className="text-danger">{errors.dioceseId?.type == "required" && "Parish Name is required"}</small>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:30}}>
                        <div className="col-lg-12 col-sm-12">
                        <AsyncSelect   
                            cacheOptions
                            defaultOptions
                            placeholder="Choose Archdeaconry"
                            loadOptions = {ArchdeaconryOptions}
                            isClearable
                            onChange = {(opt) => setthisarchdeaconry(opt==null ? '' : opt.value) }
                        />  
                            <small className="text-danger">{errors.toId?.type == "required" && "Province Name is required"}</small>
                        </div>
                    </div>

                    <div className="row" style={{marginTop:10}}>
                        <div className="col-lg-4 col-sm-4">
                        <div style={{marginTop:0}} class="">
                        {
                        IsSubmitting == false &&
                        <button onClick = { MoveParish } type ="submit" class="mdc-button mdc-button--raised">
                            Submit
                        </button>
                        }
                        {
                        IsSubmitting == true &&
                        <div style={{textAlign:'center'}}>
                        <Loader
                            visible={true}
                            type="Puff"
                            color="#7a00ff"
                            height={30}
                            width={30}
                            timeout= {0} //3 secs
                        />
                        </div>
                        }
                    </div>
                        </div>
                        
                    </div>
                </div>
                </div>
                </Modal.Body>
          </Modal>
    
        
        <Aside />
        <div class="main-wrapper mdc-drawer-app-content">
          <Header />
          <div class="page-wrapper mdc-toolbar-fixed-adjust">
            <main class="content-wrapper" style={{marginTop:-20}}>
              <div class="">
                <div class="">
                <div className ="header1">
                  <Link class="mdc-button" style ={{textDecorationLine:'none',fontSize:20}}>
                  Church of Nigeria 
                </Link>
                </div>  
               
               
               
                 <div className="">
                    <div class="card2 mt-3 tab-card">
                        <div class="card-header tab-card-header" style={{backgroundColor:'#fff',color:'#7A00FF',padding:4}}>
                                <div>
                                <a class="nav-link title">Move Diocese to Another Province </a>
                               
                                </div>
                        </div>
                        <div class="" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                            <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                                <button onClick = {()  => setmovediocese(true)} type ="submit" class="mdc-button mdc-button--raised w-100">
                                <i class="mdc-drawer-arrow material-icons">add</i> Move Diocese
                                </button>
                            </div>
                            </div>
                            
                            
                            </div>     
                        </div>
                        </div>
                    </div>
                    <div class="card2 mt-3 tab-card">
                        <div class="card-header tab-card-header" style={{backgroundColor:'#fff',color:'#7A00FF',padding:4}}>
                                <div>
                                <a class="nav-link title">Move Archdeaconry to Another Diocese </a>
                               
                                </div>
                        </div>
                        <div class="" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                            <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                                <button onClick = {()  => setmovearch(true)} type ="submit" class="mdc-button mdc-button--raised w-100">
                                <i class="mdc-drawer-arrow material-icons">add</i> Move Archdeaconry
                                </button>
                            </div>
                            </div>
                            
                            
                            </div>     
                        </div>
                        </div>
                    </div>
                    <div class="card2 mt-3 tab-card">
                        <div class="card-header tab-card-header" style={{backgroundColor:'#fff',color:'#7A00FF',padding:4}}>
                                <div>
                                <a class="nav-link title">Move Parish to Another Archdeaconry </a>
                               
                                </div>
                        </div>
                        <div class="" id="">
                        <div class="" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div style={{padding:30}}>
                            <div className="row">
                            <div class="" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto'}}>
                                <button onClick = {()  => setmoveparish(true)} type ="submit" class="mdc-button mdc-button--raised w-100">
                                <i class="mdc-drawer-arrow material-icons">add</i> Move Parish
                                </button>
                            </div>
                            </div>
                            
                            
                            </div>     
                        </div>
                        </div>
                    </div>
                 </div>
               
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    );
}


export default withRouter(Moves);