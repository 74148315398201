import React, {useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Aside from './aside';
import Header from './header';
import Footer from './footer';
//import { useSelector, useDispatch } from 'react-redux';
import { Dashboard } from '../redux/action/index';
import { Chart } from "react-google-charts";




const Home = () => {
    const dashboard = useSelector(state => state.root.dashboard);
    const dispatch =  useDispatch();
    const [pie, setpie] = useState([]);



    useEffect(() => {
        dispatch(Dashboard());
        //alert(JSON.stringify(dashboard));
        
    },[]);

    const piedata = () => {
      let wrapper = [];
        let p = [];
        // let header = [ 
        //       ['Revenue', 'Over Time'],
        //       ['Bids',dashboard.totalbids], 
        //       ['Request', dashboard.totalbuyrequest],
        //       ['Order', dashboard.totalbids]
        //     ];
        //return header;    
        // p.push(header);
        // let param = ['Bids', 'Request', 'Order'];
        // for(let r = 0; r < param.length; r++)
        // {
        //   header.push([param[r], 11]);
        // }
        // return header;

        
    }

    const histodata = () => {
      // let header = [ 
      //   ['Revenue', 'Over Time'],
      //   ['Bids',dashboard.totalbids], 
      //   ['Request', dashboard.totalbuyrequest],
      //   ['Order', dashboard.totalbids]
      // ];
        // ['Bids', 'Request', 'Order'],
        // [2 / 3, -1, 0, 0],
        // [2 / 3, -1, 0, null],
        // [2 / 3, -1, 0, null],
        // [-1 / 3, 0, 1, null],
        // [-1 / 3, 0, -1, null],
        // [-1 / 3, 0, null, null],
        // [-1 / 3, 0, null, null],
    }
    return (
        <div class="body-wrapper">
    <Aside />
    <div class="main-wrapper mdc-drawer-app-content">
      <Header />
      <div class="page-wrapper mdc-toolbar-fixed-adjust">
        <main class="content-wrapper">
          <div class="mdc-layout-grid">
            <div class="mdc-layout-grid__inner">
              <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet">
                <div class="mdc-card info-card info-card--success">
                  <div class="card-inner">
                    <h5 class="card-title">Provinces</h5>
                    <h5 class="font-weight-light pb-2 mb-1 border-bottom">{dashboard != null && dashboard.provinces }</h5>
                    <p class="tx-12 text-muted">Number of Provinces</p>
                    <div class="card-icon-wrapper">
                      <i class="material-icons">local_shipping</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet">
                <div class="mdc-card info-card info-card--danger">
                  <div class="card-inner">
                    <h5 class="card-title">Diocese</h5>
                    <h5 class="font-weight-light pb-2 mb-1 border-bottom">{dashboard != null && dashboard.dioceses }</h5>
                    <p class="tx-12 text-muted">Number of Dioceses</p>
                    <div class="card-icon-wrapper">
                      <i class="material-icons">attach_money</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet">
                <div class="mdc-card info-card info-card--primary">
                  <div class="card-inner">
                    <h5 class="card-title">Archdeaconry</h5>
                <h5 class="font-weight-light pb-2 mb-1 border-bottom">{dashboard != null && dashboard.arch}</h5>
                    <p class="tx-12 text-muted">Number of Archdeaconry</p>
                    <div class="card-icon-wrapper">
                      <i class="material-icons">time_to_leave</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet">
                <div class="mdc-card info-card info-card--info">
                  <div class="card-inner">
                    <h5 class="card-title">Parish</h5>
                     <h5 class="font-weight-light pb-2 mb-1 border-bottom">{dashboard != null && dashboard.parishes }</h5>
                    <p class="tx-12 text-muted">Number of Parishes</p>
                    <div class="card-icon-wrapper">
                      <i class="material-icons">time_to_leave</i>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet">
                <div class="mdc-card bg-success text-white">
                  <div class="d-flex justify-content-between">
                    <h3 class="font-weight-normal">Priests</h3>
                    
                  </div>
                  <div class="mdc-layout-grid__inner align-items-center">
                    <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-2-phone">
                      <div>
                       
                      <h2 class="font-weight-normal mt-3 mb-0">{dashboard != null && dashboard.priests}</h2>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-4-tablet">
                <div class="mdc-card bg-primary text-white">
                  <div class="d-flex justify-content-between">
                    <h3 class="font-weight-normal">Super Admin</h3>
                    
                  </div>
                  <div class="mdc-layout-grid__inner align-items-center">
                    <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-2-phone">
                      <div>
                       
                        <h2 class="font-weight-normal mt-3 mb-0">{dashboard != null && dashboard.superadmin}</h2>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet">
                <div class="mdc-card bg-info text-white">
                    <div class="d-flex justify-content-between">
                      <h3 class="font-weight-normal">Admin</h3>
                      
                    </div>
                    <div class="mdc-layout-grid__inner align-items-center">
                      <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-2-phone">
                        <div>
                         
                          <h2 class="font-weight-normal mt-3 mb-0">{dashboard != null && dashboard.admin}</h2>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-8">
                <div class="mdc-card">
                  <div class="d-flex justify-content-between align-items-center">
                    <h4 class="card-title mb-2 mb-sm-0">Payment</h4>
                   
                  </div>
                  <div class="d-block d-sm-flex justify-content-between align-items-center">
                    <h6 class="card-sub-title mb-0">Revenue Line Chart</h6>
                    <div class="mdc-tab-wrapper revenue-tab mdc-tab--secondary"> 
                      <div class="mdc-tab-bar" role="tablist">
                        <div class="mdc-tab-scroller">
                        </div>
                      </div>
                      <div class="content content--active">    
                      </div>
                      <div class="content">
                      </div>
                      <div class="content">    
                      </div>
                      <div class="content">
                      </div>
                      <div class="content">
                      </div>
                    </div>
                  </div>
                  <div class="chart-container mt-4">
                  <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="Histogram"
                        loader={<div>Loading Chart</div>}
                        data={[
                          ['Quarks', 'Leptons', 'Gauge Bosons', 'Scalar Bosons'],
                          [2 / 3, -1, 0, 0],
                          [2 / 3, -1, 0, null],
                          [2 / 3, -1, 0, null],
                          [-1 / 3, 0, 1, null],
                          [-1 / 3, 0, -1, null],
                          [-1 / 3, 0, null, null],
                          [-1 / 3, 0, null, null],
                        ]}
                        options={{
                          title: 'Sales Over Time',
                          legend: { position: 'top', maxLines: 2 },
                          colors: ['#5C3292', '#1A8763', '#871B47', '#999999'],
                          interpolateNulls: false,
                        }}
                        rootProps={{ 'data-testid': '5' }}
                      />
                  </div>
                </div>
              </div>
              <div class="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-8-tablet">
                <div class="mdc-card">
                  <div class="d-flex d-lg-block d-xl-flex justify-content-between">
                    <div>
                      <h4 class="card-title">Order Statistics</h4>
                      <h6 class="card-sub-title">Customers 58.39k</h6>
                    </div>
                    <div id="sales-legend" class="d-flex flex-wrap"></div>
                  </div>
                  <div class="chart-container mt-4">
                  <Chart
                      width={'auto'}
                      height={'400px'}
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={[
                        ['x', 'dogs', 'cats'],
                        [0, 0, 0],
                        [1, 10, 5],
                        [2, 23, 15],
                        [3, 17, 9],
                        [4, 18, 10],
                        [5, 9, 5],
                        [6, 11, 3],
                        [7, 27, 19],
                      ]}
                      options={{
                        hAxis: {
                          title: 'Time',
                        },
                        vAxis: {
                          title: 'Popularity',
                        },
                        series: {
                          1: { curveType: 'function' },
                        },
                      }}
                      rootProps={{ 'data-testid': '2' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  </div>
    );
}

export default Home;