import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { Link, useHistory, withRouter, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { LOADERCOLOR } from '../redux/action/constant';
import { UserLogin } from '../redux/action/index';
import Aside from './aside';
import { Tabs,Tab } from 'react-bootstrap';
import PHeader from './province_header';
import Card1 from './card1';
import Card2 from './card2';
import Card9 from './card9';
import Card4 from './card4';
import Card5 from './card5';
import Card6 from './card6';
import Card7 from './card7';
import SearchData from './search';


const DioceseDetails = (props) => {
	const { handleSubmit, errors, register, watch } = useForm();
	const dispatch = useDispatch();
  const [IsSubmitting, setIsSubmitting ] = useState(false);
  const [ show, setshow ] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [ which, setwhich ] = useState('bishop');
  const user = useSelector(state => state.root.user_login);
  const dioceses = useSelector(state => state.root.dioceses);
  
  const GetName = () => {
    let dioceseId = window.atob(id);
    let index = dioceses.findIndex(x => x.id == dioceseId);
    return dioceses[index].name;
  }
    return (
      
        <div class="body-wrapper">
         
        <Aside />
        <div class="main-wrapper mdc-drawer-app-content">
          <Header />
          <div className = "mobile">
          <div className = "bottomwrapper">
                <div style = {{display:'flex', flexDirection:'row',justifyContent:'space-around'}}>
                    <div className = "bottomicon" onClick = { () =>  { setwhich('bishop'); window.scrollTo(0,0) } }>
                        <div><i style = {{color: which == 'bishop' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">person</i></div>
                        <div style = {{color: which == 'bishop' ? '#7a00ff' : ''}} className = "bottomtext">Bishop</div>
                    </div>
                    <div className = "bottomicon" onClick = { () => { setwhich('official'); window.scrollTo(0,0) } }>
                        <div ><i style = {{color: which == 'official' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">person</i></div>
                        <div style = {{color: which == 'official' ? '#7a00ff' : ''}} className = "bottomtext">Officials</div>
                    </div>
                    <div className = "bottomicon" onClick = { () => { setwhich('arch'); window.scrollTo(0,0) } }>
                        <div ><i style = {{color: which == 'arch' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">home</i></div>
                        <div style = {{color: which == 'arch' ? '#7a00ff' : ''}} className = "bottomtext">Arcdeaconries</div>
                    </div>
                    <div className = "bottomicon" onClick = { () => { setwhich('parish'); window.scrollTo(0,0) } }>
                        <div ><i style = {{color: which == 'parish' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">home</i></div>
                        <div style = {{color: which == 'parish' ? '#7a00ff' : ''}} className = "bottomtext">Parishes</div>
                    </div>
                    <div className = "bottomicon" onClick = { () => { setwhich('priest'); window.scrollTo(0,0) } }>
                        <div ><i style = {{color: which == 'priest' ? '#7a00ff' : ''}} class="mdc-drawer-arrow material-icons">person</i></div>
                        <div style = {{color: which == 'priest' ? '#7a00ff' : ''}} className = "bottomtext">Priests</div>
                    </div>
                </div>            
          </div>
          </div>
          <div class="page-wrapper mdc-toolbar-fixed-adjust">
            <main class="content-wrapper" style={{marginTop:-20}}>
              <div class="">
                <div class="">
                <div className="header1">
                  <Link onClick = { () => history.goBack() } class="mdc-button" style ={{textDecorationLine:'none',fontSize:20}}>
                  <i style={{fontSize:40}} class="mdc-drawer-arrow material-icons">chevron_left</i>
                  Diocese Of { GetName()} 
                </Link>
                </div>  
               
                 <div className="desktop">
                 <div className="container" style={{marginTop:20}}>
                 <Tabs defaultActiveKey="bishop" id="uncontrolled-tab-example">
                  <Tab eventKey="bishop" title="Bishop Information" style={{fontSize:16,fontSize:30}}>
                    <Card9 />
                
                  </Tab>
                  <Tab eventKey="Diocean Official" title="Diocesan Official">
                      
                    <Card2  />
                  </Tab>
                  <Tab eventKey="Archdeaconry" title="Archdeaconry">
                      
                    <Card5 />
                  </Tab>
                  <Tab eventKey="Parishes" title="Parishes">
                     
                    <Card6 />
                  </Tab>
                  <Tab eventKey="Priest" title="Priest">
                     
                    <Card7 />
                  </Tab>
                 
                </Tabs>
                 </div>
                 </div>
                 <div className="mobile">
                 <div className="" style={{marginTop:20}}>
                  {
                        which == 'bishop' && 
                        <div>
                                <div class="header_btn desktop">
                                    <div>
                                    {
                                        user.userType == 'superadmin' &&    
                                        <button onClick = { () => setshow(true) }  type ="submit" class="mdc-button mdc-button--raised w-30">
                                        <i class="mdc-drawer-arrow material-icons">add</i> Create Province Official
                                        </button>
                                    }
                                    </div>
                                    <SearchData page = 'Province Official' id = {id} />
                                </div>
                                <div class="header_btn mobile">
                                    <SearchData page = 'Province Official' id = {id} />
                                </div>
                            
                                <Card9 />
                        </div>
                  }      
                  {
                        which == 'official' && 
                        <div>
                                <Card2  />
                        </div>
                       
                  }
                  {
                        which == 'arch' &&
                        <div>
                                <Card5  />
                        </div> 
                  }
                  {
                        which == 'parish' &&  
                        <div>
                                <Card6  />
                        </div>
                  }
                  {
                        which == 'priest' &&  
                        <div>
                        <Card7 />
                        </div>
                  }
                 </div>
                 </div>
               
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    );
}


export default withRouter(DioceseDetails);